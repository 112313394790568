<template>
  <v-carousel
    cycle
    height="max-height"
    hide-delimiter-background
    class="text-white "
    :show-arrows="false"
    :hide-delimiters="true"
  >    
    <template v-slot:prev="{ props }">
        <v-btn
            variant="outlined"
            class="text-transparent"
            icon
            @click="props.onClick"
        ><v-icon class="text-whit text-booton-tam">mdi-chevron-left</v-icon></v-btn>
        </template>
        <template v-slot:next="{ props }">
        <v-btn
            variant="outlined"
            class="text-transparent"
            icon
            @click="props.onClick"
        ><v-icon class="text-whit text-booton-tam">mdi-chevron-right</v-icon></v-btn>
        </template> 
        

    
    <v-carousel-item v-if="sliders.length===0"
      src="../../../assets/carusel.webp"
    >
    </v-carousel-item>
    <template 
        v-for="(slid, index) in sliders" v-bind:key="index" >
        <v-carousel-item
            :src="baseUrl+''+baseImagen+''+slid.imagen"
        >
        
            <div class="padding-text" v-if="slid.estado_descripcion===1">
                <h3 class="text-Montserrat-BlackItalic text-titulo-tam">{{slid.nombre}}</h3>
                <p class="text-Montserrat-BlackItalic text-descrip-tam">{{slid.descripcion}}</p>     
            </div>
        </v-carousel-item>
    </template>
  </v-carousel>
</template>

<script>

import axios from 'axios'
var url ='backend-ccatarija/crud-slider.php';
var url_pagina ='backend-ccatarija/crud-pagina.php';


export default {
  name: 'CcaCarousel',
  props: {
  },
  components : {
  },
 methods: {
   
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1})
      .then(Response =>{
        this.sliders=Response.data
      })
    },
    mostrar_pagina(){
      axios.post(this.baseUrl+''+url_pagina,{opcion:6})
      .then(Response =>{
        this.paginas=Response.data
      })
    },
 },
  data(){
    return {
        sliders : [],
        paginas : [],
        //baseUrl : "http://localhost/",
        baseUrl : "https://cca.edu.bo/",
        baseImagen : "img-ccatarija/img-carousel/",
        hide_delimiters:'hide-delimiters'
    };
  },
  computed : {
    
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "x-small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

<style scoped>

.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.ffcf00-background{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
}
.text-whit{
  color: white;
}
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}

.text-booton-tam{
    font-size: 4.5vw
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  
  .text-booton-tam{
    font-size: 6vw
  }
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 3%;
    } 
    .padding-bn{    
        padding-left: 15%;
        padding-right: 1%;
        padding-bottom: 0%;
        padding-top: 2%;
    }
    .text-titulo-tam{
        font-size : 2vw;
    }
    .text-descrip-tam{
        font-size : 1.5vw;
    }
    .text-bn-tam{
        font-size : 2vw;
    }
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 5%;
    } 
    .padding-bn{    
        padding-left: 15%;
        padding-right: 1%;
        padding-bottom: 0%;
        padding-top: 2%;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    } 

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
    
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 5%;
    } 
    .padding-bn{    
        padding-left: 15%;
        padding-right: 1%;
        padding-bottom: 0%;
        padding-top: 2%;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    }
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
    
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 5%;
    } 
    .padding-bn{    
        padding-left: 15%;
        padding-right: 1%;
        padding-bottom: 0%;
        padding-top: 2%;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    }
 }
@media (min-width: 1905px)  { 
    
    .padding-text{
        padding-left: 20%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 7%;
    } 
    .padding-bn{    
        padding-left: 25%;
        padding-right: 1%;
        padding-bottom: 0%;
        padding-top: 2%;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    }

}


</style>
