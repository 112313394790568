import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import './common/font.css'
import '@/assets/css/sucre/style.css'
import '@/assets/css/sucre/styleResponsivo.css'
import { VeeValidatePlugin } from 'vee-validate';

loadFonts()
// const routerInstance = router({ mode: 'hash' });
createApp(App)
  // .use(routerInstance)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VeeValidatePlugin)
  .mount('#app')
