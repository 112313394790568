<template>
<div :v-model="gecarrera(carrera_id)">

</div>
<div v-for="(carrera, index) in carreras" v-bind:key="index" >
    <h1 class="text-Montserrat-BlackItalic text-black margin-carrera text-tam padding-text-title ">{{carrera.nombre}}</h1>
    <v-row justify="center"> 
        <v-col cols="8">
            <v-row justify="center">
                <v-col cols="12">
                    <v-card-title class="text-Montserrat-Regular">
                        PERFIL DEL PROFESIONAL
                    </v-card-title>
                    <v-card-tsubitle class="text-justify text-Montserrat-Regular">
                        {{carrera.perfil_profesional}}
                    </v-card-tsubitle>
                    <v-card-title class="text-Montserrat-Regular">
                        CAMPO LABORAL
                    </v-card-title>
                    <v-card-tsubitle class="text-justify text-Montserrat-Regular">
                        {{carrera.campo_laboral}}
                    </v-card-tsubitle>
                </v-col>
                <v-col cols="6">
                    <v-row>
                        <v-col cols="2">
                            <v-sheet
                                class="d-flex align-center"
                                height="100"
                            >
                                
                                <v-img
                                    src="../../../assets/icon/duracion.webp"
                                    width="100%"
                                >
                                </v-img>
                            </v-sheet>
                        </v-col>
                        <v-col cols="10">
                            <v-card-title class="text-Montserrat-Bold">  
                                DURACIÓN
                            </v-card-title>
                            
                            <editor
                            class="text-Montserrat-Regular"
                            v-model="carrera.duracion"
                            cloud-channel="5"
                            :disabled=true
                            id="uuid4"
                            width="100%"
                            :init= "{  }"
                            initial-value=""
                            :inline=true
                            model-events= ""
                            plugins=""
                            tag-name="div"
                            toolbar=""
                            value=""
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6">
                    <v-row>
                        <v-col cols="2">
                            <v-sheet
                                class="d-flex align-center"
                                height="100"
                            >
                                
                                <v-img
                                    src="../../../assets/icon/modalidad.webp"
                                    width="100%"
                                >
                                </v-img>
                            </v-sheet>
                        </v-col>
                        <v-col cols="10">
                            <v-card-title class="text-Montserrat-Bold">
                                MODALIDAD
                            </v-card-title>
                            
                            <editor
                            class="text-Montserrat-Regular"
                            v-model="carrera.modalidad"
                            cloud-channel="5"
                            :disabled=true
                            id="DD11"
                            width="100%"
                            :init= "{  }"
                            initial-value=""
                            :inline=true
                            model-events= ""
                            plugins=""
                            tag-name="div"
                            toolbar=""
                            value=""
                            />
                        </v-col>
                    </v-row>

                </v-col>
                <v-col cols="6">
                    <v-row>
                        <v-col cols="2">
                            <v-sheet
                                class="d-flex align-center"
                                height="100"
                            >
                                
                                <v-img
                                    src="../../../assets/icon/horario.webp"
                                    width="100%"
                                >
                                </v-img>
                            </v-sheet>
                        </v-col>
                        <v-col cols="10">
                            <v-card-title class="text-Montserrat-Bold">
                                HORARIOS
                            </v-card-title>
                            
                            <editor
                            class="text-Montserrat-Regular"
                            v-model="carrera.horario "
                            cloud-channel="5"
                            :disabled=true
                            id="uuid41"
                            width="100%"
                            :init= "{  }"
                            initial-value=""
                            :inline=true
                            model-events= ""
                            plugins=""
                            tag-name="div"
                            toolbar=""
                            value=""
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6">
                    <v-row>
                        <v-col cols="2">
                            <v-sheet
                                class="d-flex align-center"
                                height="100"
                            >
                                
                                <v-img
                                    src="../../../assets/icon/requisito.webp"
                                    width="100%"
                                >
                                </v-img>
                            </v-sheet>
                        </v-col>
                        <v-col cols="10">
                            <v-card-title class="text-Montserrat-Bold">
                                REQUISITO
                            </v-card-title>
                            
                            <editor
                            class="text-Montserrat-Regular"
                            v-model="carrera.requisito"
                            cloud-channel="5"
                            :disabled=true
                            id="dd15"
                            width="100%"
                            :init= "{  }"
                            initial-value=""
                            :inline=true
                            model-events= ""
                            plugins=""
                            tag-name="div"
                            toolbar=""
                            value=""
                            />
                        </v-col>
                    </v-row>

                </v-col>
            </v-row>
        </v-col>
    </v-row>
</div>
<div class="background-navbar margin-plan">
    <v-row justify="center">
        <v-col cols="8">
            <v-row justify="center">
                <v-col cols="12">
                    <h1 class="text-Poppins-ExtraBold  text-white  ">PLAN DE ESTUDIO</h1>
                </v-col>
                <template v-for="(plans, index) in plan" v-bind:key="index">
                    <v-col 
                    cols="12"
                    sm="12"
                    md="4"
                    lg="3"
                    xl="2">
                    <div class="d-flex width-row1">
                      <div class=" align-self-center  rotar90 width-row   " >   
                                <div class=" background-0a3e6e rounded-xl padding-text-title1  " >
                                    <p class="text-white  text-center text-Poppins-ExtraBold">
                                        {{plans.nombre}}
                                    </p>
                                    <p class="text-ffd000 text-center text-Poppins-ExtraBold">
                                        {{plans.gestion}}
                                    </p> 
                                </div>  
                        </div>  
                    </div>
                    </v-col>
                    <v-col 
                    cols="12"
                    sm="12"
                    md="8"
                    lg="9"
                    xl="10">
                        <div class="background-plan padding-text-title rounded-xl">
                            <editor
                            class="text-white text-Poppins-Regular"
                            
                            v-model="plans.descripcion "
                            cloud-channel="5"
                            :disabled=true
                            :id="plans.id"
                            width="100%"
                            :init= "{  }"
                            initial-value=""
                            :inline=true
                            model-events= ""
                            plugins=""
                            tag-name="div"
                            toolbar=""
                            value=""
                            />
                        </div>
                    </v-col>
                </template>
            </v-row>
        </v-col>
    </v-row>
</div>
</template>

<script>

import axios from 'axios'
import Editor from '@tinymce/tinymce-vue'
var url_plan ='backend-ccatarija/crud-plan-estudio.php';
var url_carrera ='backend-ccatarija/crud-carrera.php';


export default {
  name: 'CcaCarreraView',
    props: {
    carrera_id: Number,
  },
  components : {
      'editor': Editor
  },
 methods: {
   gecarrera(carrera_id){
    this.mostrar_plan(carrera_id);
    this.mostrar_carrera(carrera_id);
   },
    mostrar_plan(carrera_id){
      axios.post(this.baseUrl+''+url_plan,{opcion:1,carreras_id:carrera_id})
      .then(Response =>{
        this.plan=Response.data
      })
    },
    mostrar_carrera(carrera_id){
      axios.post(this.baseUrl+''+url_carrera,{opcion:7,id:carrera_id})
      .then(Response =>{
        this.carreras=Response.data
      })
    },
 },
  data(){
    return {
        plan : [],
        carreras : [],
        //baseUrl : "http://localhost/",
        baseUrl : "https://cca.edu.bo/",
        baseImagen : "img-ccatarija/img-carrera/",
        hide_delimiters:'hide-delimiters'
    };
  },
  computed : {
    
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "x-small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  mounted(){
    }

      

}
</script>

<style scoped>

.text-Poppins-Regular{
  font-family: "Poppins-Regular";
}
.text-Poppins-ExtraBold{
  font-family: "Poppins-ExtraBold";
}
.text-LiberationSans-Bold{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.text-Montserrat-Bold{
  font-family: "Montserrat-Bold";
}

.ffcf00-background{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
} 
.background-004b8f{
   background: #004b8f;
   opacity: 0.60;
}

.opacity-100{
   opacity: 1;
}

.text-whit{
  color: white;
}
.text-ffd000{
    color: #ffd000;
}
.background-navbar{
    background: linear-gradient(90deg, #054e91, #0590ff);
}
.background-000000{
    background: #000000
}
.background-0a3e6e{
    background: #0a3e6e
}
.background-plan{
    background: linear-gradient(90deg, #0a3e6e, #0a61a8);
}
.background-plan1{
    background: linear-gradient(180deg, #0a3e6e, #0a61a8);
}
.background-0a3b68{
    background: #0a3b68
}

.text-booton-tam{
    font-size: 4.5vw
  }
  .div-ta1{
  height: 20px;
  margin: 0px;
}
.margin-plan{
    margin: 50px 0px 0px 0px;
}
.margin-1{
    margin: 11px 0px 0px 0px;
}
.padding-div{
    padding: 6px 0px 6px 0px;
}
.padding-text-title{
        padding: 5%;
    } 
.padding-text-title1{
        padding: 10% 0% 10% 0%;
    }
    .width-row{
        width: 100%;
    } 
    .width-row1{
        height: 100%;
    } 
.rotar90{
    transform: rotate(270deg)
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  
.rotar90{
    transform: rotate(0deg)
}
  .text-size{
    font-size: 6.5vw
  }
  .text-size-subtitle{
    font-size: 3.5vw
  }
    .padding-text{
        padding-top: 15%;
    } 
    .padding-row{    
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 1%;
    }
.padding-div{
    padding: 6px 0px 6px 0px;
}
  .text-size-title{
    font-size: 4.5vw
  }
  .text-size-subtitle1{
    font-size: 2.5vw
  }
  
.padding-subtitle{
    padding: 10px 0px 10px 0px;
}
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
  
.rotar90{
    transform: rotate(0deg)
} 
  .text-size{
    font-size: 6.5vw
  }
  .text-size-subtitle{
    font-size: 3.5vw
  }
    .padding-text{
        padding-top: 15%;
    } 
    .padding-row{    
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 1%;
    }
.padding-div{
    padding: 27px 0px 27px 0px;
}
  .text-size-title{
    font-size: 4.5vw
  }
  .text-size-subtitle1{
    font-size: 2.5vw
  }
.padding-subtitle{
    padding: 20px 0px 20px 0px;
}

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
  .text-size{
    font-size: 6.5vw
  }
  .text-size-subtitle{
    font-size: 2.5vw
  }
    .padding-text{
        padding-top: 15%;
    } 
    .padding-row{    
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 10%;
    }
.padding-div{
    padding: 35px 27px 35px 27px;
}
  .text-size-title{
    font-size: 4.5vw
  }
  .text-size-subtitle1{
    font-size: 2vw
  }
  
.padding-subtitle{
    padding: 30px 0px 30px 0px;
}
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  .text-size{
    font-size: 6.5vw
  }
  .text-size-subtitle{
    font-size: 2.5vw
  }
    .padding-text{
        padding-top: 15%;
    } 
    .padding-row{    
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 12%;
    }
.padding-div{
    padding: 35px 27px 35px 27px;
}
  .text-size-title{
    font-size: 4.5vw
  }
  .text-size-subtitle1{
    font-size: 2vw
  }
.padding-subtitle{
    padding: 40px 0px 40px 0px;
}
 }
@media (min-width: 1905px)  { 
  .text-size{
    font-size: 6.5vw
  }
  .text-size-subtitle{
    font-size: 2.5vw
  }
    .padding-text{
        padding-top: 15%;
    } 
    .padding-row{    
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 12%;
    }
.padding-div{
    padding: 35px 27px 35px 27px;
}
  .text-size-title{
    font-size: 4.5vw
  }
  .text-size-subtitle1{
    font-size: 2vw
  }
  
.padding-subtitle{
    padding: 50px 0px 50px 0px;
}
}


</style>
