<template>
  <div >
    <h2 class="mb-5 text-center">Te puede interesar</h2>
    <v-card v-for="(curso, index) in data" :key="index" class="mb-4">
      <div class="d-flex flex-no-wrap justify-space-between">
        <div>
          <v-card-title>
            {{ curso?.nombre }}
          </v-card-title>
          <v-card-actions>
            <v-btn class="ms-2" variant="outlined" size="small" @click="cambiarRuta(curso.nombre, curso.id)">
              VER MÁS
            </v-btn>
          </v-card-actions>
        </div>

        <v-avatar class="ma-3" size="70" rounded="0">
          <v-img v-if="curso.img1" :src="curso.img1.original_url"></v-img>
        </v-avatar>
      </div>
    </v-card>
    <div v-if="loading" class="text-center">Loading...</div>
  </div>
</template>

<script>
import usePeticion from '@/assets/helpers/Peticion';

export default {
  name: 'MoreCursos',
  data() {
    return {
      data: null,
      nameCurso: null,
      loading: true, 
    };
  },
  props: ['servicio', 'cursoId'],
  methods: {
    async cargarServicios(url) {
      const { fetchData } = usePeticion(url);

      try {
        this.data = await fetchData();
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.loading = false;
      }
    },
    cambiarRuta(name, id) {
      window.scrollTo(0, 0);
      this.$router.push({ name: 'CcaSucreCursoView', params: { name: name, id: id } });
    },
  },
  async created() {
    this.cargarServicios('cursosIDServicio/' + this.servicio);
    this.nameCurso = this.$route.params.servicio;
  },
};
</script>
