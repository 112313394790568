<template>
    <Sucre />
</template>
  
<script>
import { defineComponent } from 'vue';

// Components
import Sucre from '../components/CcaSucre/Bienvenido/Bienvenido.vue';

export default defineComponent({
    name: 'HomeView',

    components: {
        Sucre,
    },
});
</script>
  