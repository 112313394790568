<template>
<div :v-model="gecurso(servicios_id)"></div>
<div v-if="servicios_id==2">
<h1 class="text-center text-Montserrat-Black text-black margin-carrera text-tam">CURSOS DE CAPACITACIÓN</h1>
  <div>
    <v-row justify="center">
      <v-col 
      cols="10">
        <v-carousel
            cycle
            :show-arrows="show_arrows1"
            height="max-height"
            hide-delimiters
          >
          
          <template v-slot:prev="{ props }">
              <v-btn
                variant="outlined"
                class="text-transparent"
                icon
                @click="props.onClick"
              ><v-icon class="text-whit">mdi-chevron-double-left</v-icon></v-btn>
            </template>
            <template v-slot:next="{ props }">
              <v-btn
                variant="outlined"
                class="text-transparent"
                icon
                @click="props.onClick"
              ><v-icon class="text-whit">mdi-chevron-double-right</v-icon></v-btn>
            </template>  
          <v-row justify="center">
            <v-col
              cols="10"
            >     
            
            <v-carousel-item  
              v-for="(j, i) in 
              (Math.ceil(cursos.length/columns1))"
              v-bind:key="i"
              >
            
                <v-row justify="center">
                  <template
                  v-for="(moti, index) in cursos" v-bind:key="index" >
                  <v-col  
                    :cols="col_tam"
                    v-if="index < ((i+1)*columns1)  && index >=((i+1)*columns1)-columns1"
                  >
                  
                  <div v-if="index < ((i+1)*columns1)  && index >=((i+1)*columns1)-columns1">
                      <div
                        class="mx-auto mb-6"
                      >
                      
                       <v-card-title class="d-flex justify-center">
                        <h6 class="text-black text-LiberationSans-Bold text-center">{{moti.nombre }}</h6>
                      </v-card-title>                      
                      <div class="img-contedor">
                          <v-img
                                :src="baseUrl+''+baseImagen+''+moti.imagen"
                                width="100%"
                                aspect-ratio="1"
                                class="img"
                                @click="ViewCursos(moti)"
                            >
                            </v-img>
                      </div>
                      
                      <v-row justify="center">
                        <v-col
                          cols="10"
                          class="padding-0"
                        >
                          <div class="d-flex justify-center">  
                            <v-btn block class="background-0d8dff text-white text-bn-tam1 " @click="ViewCursos(moti)" rounded="pill" :size="height_bootom">
                                Más Información
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                      </div>
                    </div>
                  </v-col>
                  </template>
                </v-row>
                
            </v-carousel-item>
            </v-col>
          </v-row>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
  
  </div>
  <div v-if="servicios_id==3">
<h1 class="text-center text-Montserrat-Black text-black margin-carrera text-tam">CURSOS DE FORMACIÓN CONTINUA</h1>

    <v-row justify="center">
      <v-col 
      cols="10">
        <v-carousel
            cycle
            :show-arrows="show_arrows"
            height="max-height"
            hide-delimiters
          >
          
          <template v-slot:prev="{ props }">
              <v-btn
                variant="outlined"
                class="text-transparent"
                icon
                @click="props.onClick"
              ><v-icon class="text-whit">mdi-chevron-double-left</v-icon></v-btn>
            </template>
            <template v-slot:next="{ props }">
              <v-btn
                variant="outlined"
                class="text-transparent"
                icon
                @click="props.onClick"
              ><v-icon class="text-whit">mdi-chevron-double-right</v-icon></v-btn>
            </template>  
          <v-row justify="center">
            <v-col
              cols="10"
            >     
            
            <v-carousel-item  
              v-for="(j, i) in 
              (Math.ceil(cursos.length/columns))"
              v-bind:key="i"
              >
            
                <v-row >
                  <template
                  v-for="(moti, index) in cursos" v-bind:key="index" >
                  <v-col  
                    :cols="col_tam1"
                    v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns"
                  >
                  
                  <div v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns">
                      <div
                        class="mx-auto mb-6"
                      >
                      
                      <v-card-title class="d-flex justify-center">
                        <h6 class="text-black text-LiberationSans-Bold text-center">{{moti.nombre }}</h6>
                      </v-card-title>
                      <div class="img-contedor">
                          <v-img
                                :src="baseUrl+''+baseImagen+''+moti.imagen"
                                width="100%"
                                aspect-ratio="1"
                                class="img"
                                @click="ViewCursos(moti)"
                            >
                            </v-img>
                      </div>
                      
                      <v-row justify="center">
                        <v-col
                          cols="10"
                          class="padding-0"
                        >
                          <div class="d-flex justify-center">  
                            <v-btn block class="background-0d8dff text-white text-bn-tam " @click="ViewCursos(moti)" rounded="pill" :size="height_bootom">
                                Más Información
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                      </div>
                    </div>
                  </v-col>
                  </template>
                </v-row>
                
            </v-carousel-item>
            </v-col>
          </v-row>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
   
</template>

<script>
//import Editor from '@tinymce/tinymce-vue'
import axios from 'axios'
var url ='backend-ccatarija/crud-curso.php';


export default {
  name: 'CcaCurso',
  props: {
    servicios_id  : Number,
  },
  components : {
  },
 methods: {
  gecurso(servicios_id){
    axios.post(this.baseUrl+''+url,{opcion:6,servicios_id:servicios_id})
        .then(Response =>{
          this.cursos=Response.data
        })
  },
    ViewCursos(cursos){
     this.$emit("cursos_id",cursos.id),
     this.$emit("curso",false),
     this.$emit("quienesSomos",false),
     this.$emit("carrera",false),
     this.$emit("ViewCurso",true),
     this.$emit("ViewCarrera",false)
    }
   
 },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 3;
      }

      if (screen.width > 960) {
        return 4;
      }

      return 1;
    },
    columns1() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 3;
      }
      if (screen.width > 960 && screen.width <= 1264) {
        return 4;
      }
      if (screen.width > 1264) {
        return 6;
      }

      return 1;
    },
    
    col_tam(){
      
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 4;
      }
      
      if (screen.width > 960 && screen.width <= 1264) {
        return 4;
      }

      if (screen.width > 1264) {
        return 4;
      }

      return 1;
    },
    
    col_tam1(){
      
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 4;
      }

      if (screen.width > 960) {
        return 6;
      }

      return 1;
    },
    show_arrows(){
      
      if (screen.width > 961 ) {
        return false;
      }
      return true;
    },
    show_arrows1(){
      
      if (screen.width > 1264 ) {
        return false;
      }
      return true;
    },
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "large";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  data(){
    return {
      x1 : true,
        //baseUrl : "http://localhost/",
        baseUrl : "https://cca.edu.bo/",
      baseImagen : "img-ccatarija/img-curso/",
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      cursoF : [],
      cursoC : [],
      dialog : false,
      dialog_info : false,
      cursos : [],
      informacion : {
        id: null,
        nombre : "",
        apellido  : "",
        celular  : "",
        correo   : "",
        curso : "",
      },
    };
  },
  mounted(){
    }

      

}
</script>

<style scoped>

.padding-0{
    padding-top: 0px;
}
.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
  
}

.text-ffcf00{
  color : #ffcf00
}
.text-whit{
  color: white;
}
.text-004c8c{
  color: #004c8c;
}
.background-0d8dff{
   background: #0d8dff
}
.background-navbar{
    background: linear-gradient(90deg, #0d8dff, #6912b3 ,#004c8c);
}
.border-rad{
    
    border-radius: 9999px !important;
}

.margin-carrera{
    margin: 40px 0px 20px 0px
  }
    .img{
 cursor: pointer;
  color: inherit;
}

.img-contedor:hover .img {
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
  }
.img-contedor {
  overflow:hidden;
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  .dialog_tan{
   width : 300px
 }
 
  .text-tam{ 
    font-size: 5vw;
  }
  
.size-text-area{ 
    font-size: 5.2vw;
  }  
  
.padding-text-area{
    padding-top: 6%;
} 
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
.dialog_tan{
   width : 601px
 }
 
  .text-tam{ 
    font-size: 3vw;
  }
  
.size-text-area{ 
    font-size: 2vw;
  } 
.padding-text-area{
    padding-top: 5%;
} 
}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
   .dialog_tan{
   width : 930px  
   }
    
  .text-tam{ 
    font-size: 2vw;
  }
  
.size-text-area{ 
    font-size: 2.2vw;
  } 

.padding-text-area{
    padding-top: 5%;
} 
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  .dialog_tan{
   width : 1100px
 }
  
  .text-tam{ 
    font-size: 2vw;
  }
  
.size-text-area{ 
    font-size: 2.2vw;
  } 

.padding-text-area{
    padding-top: 4%;
} 
    
 }
@media (min-width: 1905px)  { 
  .dialog_tan{
   width : 1700px
 }
  
  .text-tam{ 
    font-size: 2vw;
  }
  
.size-text-area{ 
    font-size: 2vw;
  } 

.padding-text-area{
    padding-top: 4%;
} 
}



</style>
