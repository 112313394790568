<template>
<div v-if="lista">
    <v-row >
      <v-col
        cols="10"
        offset="1"
      >
        <v-btn
          color="success"
           @click="formNuevo()"
        >
            CREAR <v-icon>mdi-table-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="10"
        offset="1"
      >
        <v-table
            fixed-header
            height="900px" 
        >
            <thead >
            <tr class="light-blue darken-2" >
                <th class="text-left">
                #
                </th>
                <th class="text-left">
                TITULO
                </th>
                <th class="text-left">
                TILULO CORTO
                </th>
                <th class="text-left">
                DESCRIPCION
                </th>
                <th class="text-left">
                ESTADO
                </th>
                <th class="text-left">
                ACCCIONES
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="(pag, index) in paginas" v-bind:key="index"
            >
                <td>{{index+1}}</td>
                <td>{{pag.titulo}}</td>
                <td>{{pag.titulo_corto}}</td>
                <td>
                  <editor
                    v-model="pag.descripcion"
                    api-key="your-api-key"
                    cloud-channel="5"
                    :disabled=true
                    :id="index"
                    :init= "{  }"
                    initial-value=""
                    :inline=true
                    model-events= ""
                    plugins=""
                    tag-name="div"
                    toolbar=""
                    value=""
                    />
                </td>
                <td class="text-blue" v-if="pag.estado == 'P'">
                  PUBLICADO
                </td>
                <td class="text-red" v-if="pag.estado == 'B'">
                  BORRADOR 
                </td>
                <td class="text-red" v-if="pag.estado == 'E'">
                  ELIMONADO 
                </td>
                <td class="text-white">
                <v-btn
                  icon="mdi-table-edit"
                  color="info"
                  @click="formEditar (pag)"
                ></v-btn>
                <v-btn
                    icon="mdi-table-eye-off"
                    @click="formElimina(pag.id)"
                    class="background-red"
                    v-if="pag.estado == 'P'"
                ></v-btn>
                <v-btn
                    icon="mdi-table-eye"
                    @click="formHabilitar(pag.id)"
                    class="background-success"
                    v-if="pag.estado == 'B'"
                ></v-btn>
                </td>
            </tr>
            </tbody>
        </v-table>
        </v-col>
    </v-row>
</div> 
<div v-if="!lista">
    <v-row 
    justify="space-between">
      <v-col
        cols="10"
        offset="1"
        class="padding-0"
      >
      <v-card>
      <v-card-text>
        <v-row 
        justify="space-between"
        >
            <v-col
                cols="12"
                class="padding-0"
            >
              <v-text-field
              :rules="textRules"
                label="Titulo"
                required
                v-model="pagina.titulo"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                class="padding-0"
            >
              <v-text-field
              :rules="textRules"
                label="Titulo corto"
                required
                v-model="pagina.titulo_corto"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                class="padding-0"
            >
              <v-select
                :rules="textRules"
                v-model="pagina.estado"
                :items="estado"
                label="Estado"
              ></v-select>
            </v-col>
            <v-col
                cols="12"
                class="padding-0"
            >
                <editor
                    v-model="pagina.descripcion"
                    :init="{
                        plugins: [
                        'lists link image paste help wordcount'
                        ],
                        toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
                    }"
                    initial-value="Once upon a time..."
                    />
            </v-col><v-col
                cols="12"
                class="padding-0"
            >
            </v-col>
        </v-row>   
        </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn
                text
                @click="lista=true"
                >Cancelar</v-btn>
                <v-btn
                text
                @click="guardar_s"
                >Guardar</v-btn>
            </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
</div>
</template>

<script>

import axios from 'axios'
import Editor from '@tinymce/tinymce-vue'
var url ='backend-ccatarija/crud-pagina.php';
var urlsubirimagen ='backend-ccatarija/upload.php'

export default {
  name: 'CcaPagina',
  props: {
  },
  components : {
    'editor': Editor
  },
 methods: {
    formNuevo (){
      this.lista = false
      this.operacion='crear';
      this.pagina.titulo = "";
      this.pagina.descripcion = "";
      this.pagina.titulo_corto = "";
      this.pagina.estado=""
    },
    convertir_estado(estado){
      if(estado =="Publicado"){
        this.pagina.estado="P"
      }
      if(estado =="Borrador"){
        this.pagina.estado="B"
      }
      if(estado =="Eliminar"){
        this.pagina.estado="E"
      }
      if(estado =="P"){
        this.pagina.estado="Publicado"
      }
      if(estado =="B"){
        this.pagina.estado="Borrador"
      }
      if(estado =="E"){
        this.pagina.estado="Eliminar"
      }
    },
    formElimina(id){
      this.pagina.id=id;
      this.dialogEliminar = true
    },
    formHabilitar(id){
      this.pagina.id=id;
      this.dialogHabilitar = true
    },
    formEditar (pagina1){
      this.lista = false;
      this.operacion='editar';
      this.pagina.id = pagina1.id;
      this.pagina.titulo = pagina1.titulo;
      this.convertir_estado(pagina1.estado);
      this.pagina.descripcion = pagina1.descripcion;
      this.pagina.titulo_corto = pagina1.titulo_corto;
    },
    guardar_slider (){
      
      if(this.operacion=='crear'){
        this.crear_slider();                 
      }
      if(this.operacion=='img'){ 
        this.subirImagen();                           
      }
      if(this.operacion=='editar'){ 
        this.editar_slider();                           
      }
      this.dialog=false;  
    },
    eliminar_slider (){
      axios.post(this.baseUrl+''+url, {opcion:4, id:this.slider.id, estado: false })
        .then(response =>{
          response;
          this.mostrar();

        });  
      
      this.slider.id = null;
      this.dialogEliminar= false;

    },
    habilitar_slider(){
      axios.post(this.baseUrl+''+url, {opcion:4, id:this.slider.id, estado: true })
        .then(response =>{
          response;
          this.mostrar();
        });  
      
      this.slider.id = null;
      this.dialogHabilitar= false;

    },
    crear_slider (){
      axios.post(this.baseUrl+''+url, {opcion:2, titulo:this.pagina.titulo, titulo_corto:this.pagina.titulo_corto, 
                                        descripcion:this.pagina.descripcion, estado:this.pagina.estado })
        .then(response =>{
          response;
          this.mostrar();
        });  
      
      this.pagina.titulo = "";
      this.pagina.descripcion = "";
      this.pagina.titulo_corto = "";
      this.lista= true;

    },
    editar_slider (){
      this.convertir_estado(this.pagina.estado);
      axios.post(this.baseUrl+''+url, {opcion:3,id:this.pagina.id,titulo:this.pagina.titulo, 
                titulo_corto:this.pagina.titulo_corto, descripcion:this.pagina.descripcion, estado:this.pagina.estado })
        .then(response =>{
          response;
          this.mostrar();
        });  
      this.pagina.titulo = "";
      this.pagina.descripcion = "";
      this.pagina.titulo_corto = "";
      this.lista= true;

    },
   cargarImagen(e){
    this.file = e.target.files[0];
   },
   subirImagen(){
     
    var fd = new FormData();
    fd.append("file", this.file, this.file.name)
    //console.log(fd.getAll("file"));
    
    axios
    .post(this.baseUrl+''+urlsubirimagen, fd)
    .then(response => {
        //console.log(response.data[1])
      this.slider.imagen=response.data[1];
      if(this.operacion=='img'){ 
        this.editar_slider();                          
      }else{
        this.guardar_slider();
      }
    })
    .catch(error => {
        console.log(error.data)
    })
   },
   guardar_s(){
     
    if(this.operacion=='crear'){
      this.crear_slider();              
    }
    if(this.operacion=='editar'){ 
      this.editar_slider();                           
    }
   },
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:5})
      .then(Response =>{
        this.paginas=Response.data
      })
    }   
 },
  data(){
    return {
      carouselheight: 0,
      valid : true,
      d : null,
      if_imagen : true,
      if_crear : true,
      operacion : 'crear',
      estado : ['Publicado',"Borrador", "Eliminar"],
      paginas : [],
      pagina : {
        id: null,
        titulo : "",
        titulo_corto : "",
        descripcion : "",
        estado : ""
      },
       file: null,
       dialog : false,
       dialogEliminar : false,
       dialogHabilitar : false,
        //baseUrl : "http://localhost/",
        baseUrl : "https://cca.edu.bo/",
       lista : true,
       content : "",
       
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      textRules: [
        v => !!v || 'Es requerido el campo',
      ],
    };
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

<style scoped>

.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.background-ffcf00{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
}
.text-whit{
  color: white;
}
.background-red{
  background : red;
}
.background-success{
  background : green;
}
.text-ffcf00{
  color: #ffcf00;
}
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.border-rad{
    
    border-radius: 9999px !important;
}

.margin-est{
    margin: 20px 0px 0px 0px
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
    
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
    
    
 }
@media (min-width: 1905px)  { 

}


</style>
