<template>  
  <v-row justify="center" class="pilares-sucre">
    <v-col cols="12">
    <h2  class="text-center mb-4">Todo Estudiante CCA tiene Formación Basada en 5 Pilares</h2>
    </v-col>
    <v-row justify="center">
      <v-col 
        cols="8" 
        sm="10"
        md="12"
        lg="12"
        xl="10">
        <v-row justify="center">
          <v-col  class="padding-col"
            cols="12" 
            sm="4"
            md="2"
            lg="2"
            xl="2">
            <v-row justify="center" class="mb-6">
              <v-col cols="7">
                <v-img
                  src="../../../assets/sucre/tecnologia.png"
                  width="100%"
                >
                </v-img>
              </v-col>
            </v-row>
            <div class="background-ffcf00  rounded-s-xl height-50 d-flex"  block>
              <v-sheet class="background-ffcf00 align-self-center text-center rounded-s-xl text-2c4d89 text-Arial-Bold" width="100%">TECNOLOGÍA</v-sheet>
            </div>
          </v-col>
          <v-col class="padding-col"
            cols="12" 
            sm="4"
            md="2"
            lg="2"
            xl="2">
            <v-row justify="center" class="mb-6">
              <v-col cols="7">
                <v-img
                  src="../../../assets/sucre/liderazgo.png"
                  width="100%"
                >
                </v-img>
              </v-col>
            </v-row>            
            <div class="background-900dff  rounded-0 height-50 d-flex"  block>
              <v-sheet class="background-900dff align-self-center text-center rounded-e-xl text-white text-Arial-Bold" width="100%">LIDERAZGO</v-sheet>
            </div>
          </v-col>
          <v-col class="padding-col"
            cols="12" 
            sm="4"
            md="2"
            lg="2"
            xl="2">
            <v-row justify="center" class="mb-6">
              <v-col cols="7">
                <v-img
                  src="../../../assets/sucre/resposabilidad.png"
                  width="100%"
                >
                </v-img>
              </v-col>
            </v-row>
            <div class="background-ffcf00  rounded-0 height-50 d-flex"  block>
              <v-sheet class="background-ffcf00 align-self-center text-center rounded-0 text-2c4d89 text-Arial-Bold" width="100%">RESPOSABILIDAD SOCIAL</v-sheet>
            </div>
          </v-col>
          <v-col class="padding-col"
            cols="12" 
            sm="4"
            md="2"
            lg="2"
            xl="2">
            <v-row justify="center" class="mb-6">
              <v-col cols="7">
                <v-img
                  src="../../../assets/sucre/Idiomas.png"
                  width="100%"
                >
                </v-img>
              </v-col>
            </v-row>
            <div class="background-900dff  rounded-0 height-50 d-flex"  block>
              <v-sheet class="background-900dff align-self-center text-center rounded-e-xl text-white text-Arial-Bold" width="100%">IDIOMAS</v-sheet>
            </div>
          </v-col>
          <v-col class="padding-col" 
            cols="12" 
            sm="4"
            md="2"
            lg="2"
            xl="2">
            <v-row justify="center" class="mb-6">
              <v-col cols="7">
                <v-img
                  src="../../../assets/sucre/empredndimiento.png"
                  width="100%"
                >
                </v-img>
              </v-col>
            </v-row>
            <div class="background-ffcf00  rounded-e-xl height-50 d-flex"  block>
              <v-sheet class="background-ffcf00 align-self-center text-center rounded-e-xl text-2c4d89 text-Arial-Bold" width="100%">EMPRENDIMIENTO</v-sheet>
            </div>
          </v-col>   
        </v-row>
      </v-col>
    </v-row>   
  </v-row>
</template>

<script>



export default {
  name: 'CcaSucreValores',
  props: {
  },
  components : {
  },
  computed: {
    ifnabar(){
      
      if (screen.width <= 960 ) {
        return true;
      }
      return false;
    },
    height_toolbar() {
      if (screen.width > 0 && screen.width <= 600) {
        return 75;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 90;
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 100;
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return 100;
      }

      if (screen.width > 1904) {
        return 110;
      }

      return 1;
    },
      
  },
 methods: {
    changeFocus (hash) {
     console.log(hash);
     window.location.hash = hash;
  },
   
 },
  data(){
    return {
    };
  },

    watch: {
    },
  mounted(){
    }

      

}
</script>

<style scoped>
.pilares-sucre{
    background-color: #e5eaff;
    padding-bottom: 50px;
}
.pilares-sucre h2{
  margin-bottom: 60px !important;
}
.text-Arial-Bold{
  font-family: "Arial-Bold";
}
.text-Arial-Narrow{
  font-family: "Arial-Narrow";
}
.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.padding-col{
  padding: 0px;
} 
.text-2c4d89{
  color: #2c4d89;
}
.background-ffcf00{
    background: #ffcf00
} 
.background-ffffff{
    background: #ffffff
} 
.background-c4c4c4{
    background: #c4c4c4
} 
.background-004c8c{
    background: #004c8c
}   
.background-900dff{
    background: #900dff
}   
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.div-ta0{
  height: 10px;
  margin: 0px;
}
.width{
  width: 100%;
}
.padding-col{
  padding: 0px;
}  
.height-50{
  height: 40px;
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  .text-size-title{
    font-size: 4vw;
  }
  .img-padding{   
    padding: 12% 0px 0px 0px;
}
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){ 
 .text-size-title{
    font-size: 3vw;
  }
    .img-padding{   
    padding: 10% 0px 0px 0px;
}

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
   .text-size-title{
    font-size: 2.5vw;
  }
  .text-size-bn{
    font-size: 1vw;
  }
   .img-padding{   
    padding: 10% 0px 0px 0px;
}
  .text-narbar-tam{
    font-size: 1vw
  }
  .margin-img{
    margin: 0px 0px 0px 80px
  }
  .margin-booton{
    margin: 0px 120px 0px 0px;
  }
     
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
     .text-size-title{
    font-size: 2vw;
  }
     .img-padding{   
    padding: 7% 0px 0px 0px;
}
  .text-narbar-tam{
    font-size: 1.1vw
  }
  .margin-img{
    margin: 0px 0px 0px 125px;
  }
  .margin-booton{
    margin: 0px 150px 0px 0px;
  }
  
 }
@media (min-width: 1905px)  { 
     .text-size-title{
    font-size: 1.5vw;
  }
     .img-padding{   
    padding: 4.5% 0px 0px 0px;
}
  .text-narbar-tam{
    font-size: 0.9vw
  }
  .margin-img{
    margin: 0px 0px 0px 250px;
  }
  .margin-booton{
    margin: 0px 250px 0px 0px;
  }
}


</style>
