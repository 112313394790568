<template>

     <v-card>
      <v-layout>
        <v-app-bar
          class="background-0d8dff text-white"
          prominent
        >
        <v-avatar class="margin-img">
          <v-img src="../../../assets/Logos/CCA.webp"></v-img>
        </v-avatar>
          <v-spacer></v-spacer>
          <v-btn variant="text" icon="mdi-home"></v-btn>
          <v-btn variant="text" v-on:click="carouselF()">Carousel</v-btn>
          <v-btn variant="text" v-on:click="servicioF()" >Servicio</v-btn>
          <v-btn variant="text" v-on:click="cursoF()" >Curso</v-btn>
          <v-btn variant="text" v-on:click="carreraF()" >Carrera</v-btn>
          <v-btn variant="text" v-on:click="noticiaF()" >Noticias</v-btn>
        </v-app-bar>


        <v-main>
        </v-main>
      </v-layout>
    </v-card>
    <v-row >
      <v-col
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <div class=" div-ta0 background-ffcf00"></div>
      </v-col>
    </v-row>
  <div class="hello">
    
    <div v-if="pagina" class="margin-config">
      <Pagina/>
    </div>
    <div v-if="curso" class="margin-config">
      <Curso/>
    </div>
    <div v-if="carrera" class="margin-config">
      <Carrera/>
    </div>
    <div v-if="carousel" class="margin-config">
      <Carousel/>
    </div>
    <div v-if="servicio" class="margin-config">
    <Servicios/>
    </div>
    <div v-if="noticias" class="margin-config">
    <Noticias/>
    </div>
  </div>
</template>

<script>


//import CarouselConfig from '@/components/CcaTarija/Carousel/CarouselConfig.vue'
import Pagina from '@/components/CcaTarija/Pagina/Pagina.vue'
import Curso from '@/components/CcaTarija/Curso/CursoConfig.vue'
import Carrera from '@/components/CcaTarija/Carrera/CarreraConfig.vue'
import Carousel from '@/components/CcaTarija/Carousel/CarouselConfig.vue'
import Servicios from '@/components/CcaTarija/Servicios/ServiciosConfig.vue'
import Noticias from '@/components/CcaTarija/Noticias/NoticiaConfig.vue'
export default {
  name: 'CcaConfiguracion',
  props: {
  },
  components : {
    Pagina,
    Curso,
    Carrera,
    Carousel,
    Servicios,
    Noticias
  },
 methods: {
   carouselF(){
    this.carousel = true;
    this.curso = false;
    this.carrera  = false;
    this.pagina = false;
    this.servicio = false;
    this.noticias = false;
  },
   pagionaF(){
    this.carousel = false;
    this.curso = false;
    this.carrera  = false;
    this.pagina = true;
    this.servicio = false;
    this.noticias = false;
  },
   cursoF(){
    this.carousel = false;
    this.curso = true;
    this.carrera  = false;
    this.pagina = false;
    this.servicio = false;
    this.noticias = false;
  },
   carreraF(){
    this.carousel = false;
    this.curso = false;
    this.carrera  = true;
    this.pagina = false;
    this.servicio = false;
    this.noticias = false;
  },
   servicioF(){
    this.carousel = false;
    this.curso = false;
    this.carrera  = false;
    this.pagina = false;
    this.servicio = true;
    this.noticias = false;
  },
   noticiaF(){
    this.carousel = false;
    this.curso = false;
    this.carrera  = false;
    this.pagina = false;
    this.servicio = false;
    this.noticias = true;
  },
 },
  data(){
    return {
      carousel : true,
      curso : false,
      carrera  : false,
      pagina : false,
      servicio : false,
      noticias : false,
    };
  },
  mounted(){
    }

      

}
</script>

<style scoped>

.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.background-ffcf00{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
}
.text-whit{
  color: white;
}
.text-ffcf00{
  color: #ffcf00;
}

.div-ta0{
  height: 10px;
  margin: 0px;
}
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.border-rad{
    
    border-radius: 9999px !important;
}

.margin-config{
  margin : 50px 0px 0px 0px
}
.margin-est{
    margin: 20px 0px 0px 0px
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
    
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
    
    
 }
@media (min-width: 1905px)  { 

}


</style>
