<template>
  <div class="carousel-container">
    <v-carousel hide-delimiters show-arrows="hover" class="content-carousel-father" v-if="data">
      <template v-if="data && data.length > 0">
        <v-carousel-item v-for="(item, i) in data" :key="i" :src="item.img1?item.img1.original_url:'https://img.freepik.com/foto-gratis/profesor-retrato-escribiendo-pizarra_23-2148665921.jpg?t=st=1695678192~exp=1695678792~hmac=e703cf847a09a1bb242206d51c92f00ecac8f62543661101cb980063f757ecef.webp'"
          cover>
          <v-row class="content-carousel">
            <v-col class="content-carousel d-flex flex-column justify-center align-center h-100">
              <h1 class="text-center text-white text-shadow">{{ item.nombre }}</h1>
              <div class="fecha">
                Inicia: {{ formatDate(item?.fechaInicio) }}
              </div>
              <div class="options">

                <a :href="item.pdf1?.original_url" target="_blank">
                  <v-btn class="mr-2 download">
                    Descargar
                  </v-btn>
                </a>
                <a :href="`#/sucre/bienvenido/cursos/${item.nombre}/${item.id}`">
                  <v-btn class="more">Ver más</v-btn>
                </a>
              </div>
            </v-col>
            <v-col class="formulario">
              <v-form @submit.prevent="enviarFormulario(item.id, item.servicio.id)">
                <h2 class="text-center">Más información</h2>
                <v-text-field label="Nombre" v-model="nombre" required clearable outlined color="blue"></v-text-field>
                <v-text-field label="Apellido" v-model="apellido" required clearable outlined color="blue"></v-text-field>
                <v-text-field label="Celular" v-model="celular" required clearable outlined color="blue"></v-text-field>
                <v-text-field label="Correo Electrónico" v-model="correo" required clearable outlined
                  color="blue"></v-text-field>
                <v-btn type="submit" color="blue" block>Enviar</v-btn>
              </v-form>
            </v-col>
          </v-row>

        </v-carousel-item>
      </template>

    </v-carousel>
  </div>
</template>
<script>
import usePeticion from '@/assets/helpers/Peticion';

export default {
  name: 'CcaSucreCarousel',
  data() {
    return {
      data: null,
      nombre: '',
      apellido: '',
      celular: '',
      correo: '',
    }
  },
  async created() {
    const { fetchData } = usePeticion('cursosIDServicio/2');

    try {
      this.data = await fetchData();
    } catch (error) {
      console.error('Error:', error);
    }
  },
  methods: {
    async enviarFormulario(id_c, id_ser) {
      try {
        // Crear un objeto con los datos del formulario
        const formData = {
          nombre: this.nombre,
          apellido: this.apellido,
          celular: this.celular,
          correo: this.correo,
          id: id_c,
          servicio_id: id_ser,
        };

        const { postData } = usePeticion('addContacto');
        const response = await postData(formData);

        // Aquí puedes manejar la respuesta, por ejemplo, mostrar un mensaje de éxito
        console.log('Respuesta del servidor:', response);
        // Reiniciar los campos del formulario
        this.nombre = '';
        this.apellido = '';
        this.celular = '';
        this.correo = '';
        this.id = '';
        this.servicio_id = '';
      } catch (error) {
        // Manejar errores, como mostrar un mensaje de error
        console.error('Error:', error);
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, options);
    }
  }
}
</script>
