<template>
  <div class="formulario-view">
    <v-row></v-row>
    <v-row class="mt-50">
      <v-col class="portada-curso">
        <div class="fondo"><img :src="img" /></div>
        <div class="fecha">
          Inicia: {{ formatDate(data?.fechaInicio) }}
        </div>
      </v-col>
      <v-col class="formulario">
        <v-form @submit.prevent="enviarFormulario(data.id, data.servicio.id)">
          <h2>Más información</h2>
          <v-text-field label="Nombre" v-model="nombre" required clearable outlined color="blue"></v-text-field>
          <v-text-field label="Apellido" v-model="apellido" required clearable outlined color="blue"></v-text-field>
          <v-text-field label="Celular" v-model="celular" required clearable outlined color="blue"></v-text-field>
          <v-text-field label="Correo Electrónico" v-model="correo" required clearable outlined
            color="blue"></v-text-field>
          <v-btn type="submit" color="blue" block>Enviar</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import usePeticion from '@/assets/helpers/Peticion';
export default {
  name: 'form-vue',
  props: ['img', 'data'],
  data() {
    return {
      nombre: '',
      apellido: '',
      celular: '',
      correo: '',
    };
  },
  methods: {
    async enviarFormulario(id_c, id_ser) {
      try {
        // Crear un objeto con los datos del formulario
        const formData = {
          nombre: this.nombre,
          apellido: this.apellido,
          celular: this.celular,
          correo: this.correo,
          id: id_c,
          servicio_id: id_ser,
        };

        const { postData } = usePeticion('addContacto');
        const response = await postData(formData);

        // Aquí puedes manejar la respuesta, por ejemplo, mostrar un mensaje de éxito
        console.log('Respuesta del servidor:', response);
        // Reiniciar los campos del formulario
        this.nombre = '';
        this.apellido = '';
        this.celular = '';
        this.correo = '';
        this.id = '';
        this.servicio_id = '';
      } catch (error) {
        // Manejar errores, como mostrar un mensaje de error
        console.error('Error:', error);
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, options);
    }
  },
};
</script>
