<template>  
<v-card>
  <v-toolbar
    :height="height_toolbar"
    class="background-ffffff text-black ">
      <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
      <v-img 
         src="../../../assets/Logos/CCA2.webp" class="cursor" v-on:click="enviarQuien()"></v-img>
      <v-spacer></v-spacer>
      
      <v-menu v-if="!ifnabar" location="start">
        <template v-slot:activator="{ props }">
          
          <v-app-bar-nav-icon variant="text" 
            class="ma-2"
            v-bind="props"></v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item>
            <v-btn variant="text"  v-on:click="changeFocus('carrera')" >
              CARRERAS TÉCNICAS
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn variant="text"  v-on:click="changeFocus('curso')" >
              CURSOS DE CAPACITACIÓN
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      

      <v-btn v-if="ifnabar" variant="text" class ="text-Montserrat-BlackItalic text-narbar-tam" v-on:click="enviarQuien()">Inicio</v-btn>
      <v-btn v-if="ifnabar" variant="text" class ="text-narbar-tam" @click="ViewCarrera(1)">CARRERAS</v-btn>
      <v-btn v-if="ifnabar" variant="text" class ="text-narbar-tam" @click="ViewCarrera(2)">CAPACITACION</v-btn>
      <v-btn v-if="ifnabar" variant="text" class ="text-narbar-tam" @click="ViewCarrera(3)">FORMACION CONTINUA</v-btn>
      <v-btn v-if="ifnabar" variant="text" class ="text-narbar-tam" @click="ViewCarrera(4)">PRE UNIVERSITARIOS</v-btn>
  </v-toolbar>  
  </v-card>

</template>

<script>



export default {
  name: 'CcaSucreNabar',
  props: {
  },
  components : {
  },
  computed: {
    ifnabar(){
      
      if (screen.width <= 960 ) {
        return false;
      }
      return true;
    },
    height_toolbar() {
      if (screen.width > 0 && screen.width <= 600) {
        return 75;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 90;
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 100;
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return 100;
      }

      if (screen.width > 1904) {
        return 110;
      }

      return 1;
    },
      
  },
 methods: {
    changeFocus (hash) {
     console.log(hash);
     window.location.hash = hash;
  },
   
   enviarQuien(){
     this.$emit("carrera",false),
     this.$emit("curso",false),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
    },
    ViewCarrera(id){
      if(id==1){
        this.enviarCarrera(id);
      }else{
        this.enviarCurso(id);
      }
    },
    enviarCarrera(id){
     this.$emit("servicios_id",id),
     this.$emit("quienesSomos",false)
     this.$emit("carrera",true),
     this.$emit("curso",false),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
    },
  enviarCurso(id){
     this.$emit("servicios_id",id),
     this.$emit("quienesSomos",false),
     this.$emit("carrera",false),
     this.$emit("curso",true),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
    },
 },
  data(){
    return {
    };
  },

    watch: {
    },
  mounted(){
    }

      

}
</script>

<style scoped>

.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.padding-col{
  padding: 0px;
} 
.ffcf00-background{
    background: #ffcf00
} 
.background-ffffff{
    background: #ffffff
} 
.background-c4c4c4{
    background: #c4c4c4
}   
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.div-ta0{
  height: 10px;
  margin: 0px;
}
.cursor{
 cursor: pointer;
  color: inherit;
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  .img-padding{   
    padding: 12% 0% 0% 73%;
}
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){ 
    .img-padding{   
    padding: 10% 0% 0% 90%
}

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
   .img-padding{   
    padding: 7% 0px 0px 0px;
}
  .text-narbar-tam{
    font-size: 1vw
  }
  .margin-img{
    margin: 0px 0px 0px 80px
  }
  .margin-booton{
    margin: 0px 120px 0px 0px;
  }
     
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
     .img-padding{   
    padding: 5% 0px 0px 0px;
}
  .text-narbar-tam{
    font-size: 1.1vw
  }
  .margin-img{
    margin: 0px 0px 0px 125px;
  }
  .margin-booton{
    margin: 0px 150px 0px 0px;
  }
  
 }
@media (min-width: 1905px)  { 
     .img-padding{   
    padding: 4.5% 0px 0px 0px;
}
  .text-narbar-tam{
    font-size: 0.9vw
  }
  .margin-img{
    margin: 0px 0px 0px 250px;
  }
  .margin-booton{
    margin: 0px 250px 0px 0px;
  }
}


</style>
