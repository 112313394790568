<template>
  <BannerVue title="Nuestras Carreras" />

  <div class="carreras">

    <v-row justify="center" class="mt-8">
      <v-col cols="10">
        <v-row justify="center">
          <template v-for="(carrera, index) in data" v-bind:key="index">
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <v-card class="card-carrera" max-width="350">
                <div class="img-contedor">
                  <v-img v-if="carrera.img" :src="carrera.img.original_url" width="100%" aspect-ratio="1"
                    class="pa-0 ma-0 img" @click="cambiarRuta(carrera.nombre, carrera.id)">
                  </v-img>
                  <span class="cinta"> {{ carrera.nombre }}</span>
                </div>
                <v-tabs v-model="tab" class="background-014c8d text-ffd101" centered stacked>
                  <v-tab value="tab-1">
                    <v-icon icon="mdi-account-group" class="icon-size text-gris "></v-icon>
                    <h3 class="text-center text-gris text-size-title">MODALIDAD</h3>
                  </v-tab>

                  <v-tab value="tab-2">
                    <v-icon icon="mdi-book-edit-outline" class="icon-size text-gris text-center "></v-icon>
                    <h3 class="text-center text-gris text-size-title">CERTIFICACIÓN</h3>
                  </v-tab>

                  <v-tab value="tab-3">
                    <v-icon icon="mdi-timer-sand" class="icon-size text-gris text-center "></v-icon>
                    <h3 class="text-center text-gris text-size-title">DURACIÓN</h3>
                  </v-tab>
                </v-tabs>
                <v-window v-model="tab">
                  <v-window-item value="tab-1">
                    <v-card class="background-002a50 text-ffd101 details">
                      <v-card-text class="text-center text-size-subtitle content content-1">
                        <editor class="text-center text-size-subtitle" v-model="carrera.modalidad" cloud-channel="5"
                          :disabled=true :id="'modalidad' + carrera.id" width="100%" :init="{}" initial-value=""
                          :inline=true model-events="" plugins="" tag-name="div" toolbar="" value="" />
                      </v-card-text>
                      <span @click="cambiarRuta(carrera.nombre, carrera.id)" class="more-information">Leer más <v-icon end
                          icon="mdi-chevron-right" class="text-6912b3"></v-icon>
                      </span>
                    </v-card>
                  </v-window-item>
                  <v-window-item value="tab-2">
                    <v-card class="background-002a50 text-ffd101 details">
                      <v-card-text class="text-center text-size-subtitle content content-2">
                        <editor class="text-center text-size-subtitle" v-model="carrera.certificacion" cloud-channel="5"
                          :disabled=true :id="'certificacion' + carrera.id" width="100%" :init="{}" initial-value=""
                          :inline=true model-events="" plugins="" tag-name="div" toolbar="" value="" />
                      </v-card-text>
                      <span class="more-information">Leer más <v-icon end icon="mdi-chevron-right"
                          class="text-6912b3"></v-icon>
                      </span>
                    </v-card>
                  </v-window-item>
                  <v-window-item value="tab-3">
                    <v-card class="background-002a50 text-ffd101 details">
                      <v-card-text class="text-center text-size-subtitle">
                        <editor class="text-center text-size-subtitle content" v-model="carrera.Duracion"
                          cloud-channel="5" :disabled=true :id="'Duracion' + carrera.id" width="100%" :init="{}"
                          initial-value="" :inline=true model-events="" plugins="" tag-name="div" toolbar="" value="" />
                      </v-card-text>
                      <span @click="cambiarRuta(carrera.nombre, carrera.id)" class="more-information">Leer más <v-icon end
                          icon="mdi-chevron-right" class="text-6912b3"></v-icon>
                      </span>
                    </v-card>
                  </v-window-item>
                </v-window>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import BannerVue from '@/components/CcaSucre/Navbar/Banner.vue';
import Editor from '@tinymce/tinymce-vue'
import usePeticion from '@/assets/helpers/Peticion';


export default {
  name: 'CcaSucreCarrera',
  props: {
  },
  components: {
    'editor': Editor,
    BannerVue
  },
  
  methods: {


    cambiarRuta(name, id) {
      window.scrollTo(0, 0);
      this.$router.push({ name: 'CcaSucreCarreraView', params: { name: name, id: id } });

    }

  },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 3;
      }

      if (screen.width > 960) {
        return 4;
      }

      return 1;
    },
    columns1() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 3;
      }

      if (screen.width > 960) {
        return 3;
      }

      return 1;
    },

    col_tam() {

      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 4;
      }

      if (screen.width > 960) {
        return 4;
      }

      return 1;
    },

    col_tam1() {

      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 4;
      }

      if (screen.width > 960) {
        return 3;
      }

      return 1;
    },
    show_arrows() {

      if (screen.width > 961) {
        return false;
      }
      return true;
    }
  },
  data() {
    return {
      data:null,
      x1: true,
      //baseUrl : "http://localhost/",
      baseUrl: "https://cca.edu.bo/",
      baseImagen: "img-ccasucre/img-carrera/",
      carreras: [],
      tab: 'option-1',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    };
  },
  async created() {
    const { fetchData } = usePeticion('carreras');

    try {
      this.data = await fetchData(); // Espera a que se resuelva la promesa y asigna los datos
    } catch (error) {
      console.error('Error:', error);
    }
  },



}
</script>

<style scoped>
.img-contedor {
  height: 200px;
  overflow: hidden;
}

.img {
  cursor: pointer;
  transition: .3;

}

.carreras .card-carrera:hover .img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}




/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) {

  .text-size-title {
    font-size: 2.2vw;
  }

  .text-size-subtitle {
    font-size: 2.3vw;
  }

  /* Fin de nemu*/
}

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) {

  .text-size-title {
    font-size: 1.1vw;
  }

  .text-size-subtitle {
    font-size: 1.5vw;
  }


}

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) {


  .text-size-title {
    font-size: 0.6vw;
  }

  .text-size-subtitle {
    font-size: 1vw;
  }

}

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) {


  .text-size-title {
    font-size: 0.7vw;
  }

  .text-size-subtitle {
    font-size: 1vw;
  }

}

@media (min-width: 1905px) {

  .text-size-title {
    font-size: 0.4vw;
  }

  .text-size-subtitle {
    font-size: 0.5vw;
  }

}
</style>
