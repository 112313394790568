export default function usePeticion(url) {
  const baseUrl = 'https://sucre-backend.cca.edu.bo/api/';

  const fetchData = async () => {
    try {
      const res = await fetch(baseUrl + url, {
        method: 'GET',
        // headers: {
        //   Authorization: `Bearer ${token}`
        // }
      });

      if (res.status === 200) {
        return res.json(); // Devuelve la promesa con los datos
      } else {
        console.log('Error', res.status);
        throw new Error('Error en la solicitud');
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const postData = async (data) => {
    try {
      const res = await fetch(baseUrl + url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Indicamos que estamos enviando datos JSON
        },
        body: JSON.stringify(data), // Convierte los datos a JSON y los envía en el cuerpo de la solicitud
      });
      console.log('url', baseUrl + url);
      console.log('DATA', data);
      if (res.status === 200) {
        const responseData = await res.json();
        console.log('Respuesta del servidor:', responseData); // Agregar esta línea para ver la respuesta
        return responseData;
      } else {
        console.log('Error', res.status);
        // throw new Error('Error en la solicitud: ' + (await res.text()));
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return {
    fetchData, // Devuelve la función fetchData
    postData,
  };
}
