<template>
    <Navber @quienesSomos="quienesSomos = $event" @servicios_id="servicios_id = $event" @id_carrera="id_carrera = $event"
        @carrera="carrera = $event" @id_curso="id_curso = $event" @curso="curso = $event"
        @ViewCarrera="ViewCarrera = $event" @ViewCurso="ViewCurso = $event" />
    <v-row v-if="!View">
        <v-col cols="12" class="padding-col">
            <CarouselB/>
        </v-col>
        <v-col cols="12">
            <Separador :id="1" />
        </v-col>
        <div class="py-10">
            <MisionVision/>
        </div>
    </v-row>
    <div id="quiensomos" v-if="quienesSomos">
        <QuienesSomos />
    </div>
    <Personal v-if="quienesSomos" />
    <Docente v-if="quienesSomos" />
    <div id="carrera" v-if="carrera">
        <Carrera @quienesSomos="quienesSomos = $event" @carrera_id="carrera_id = $event" @carrera="carrera = $event"
            @ViewCarrera="ViewCarrera = $event" @ViewCurso="ViewCurso = $event" @curso="curso = $event" />
    </div>

    <div id="curso" v-if="ViewCurso">
        <CursoView :cursos_id="cursos_id" />
    </div>
    <div id="carrera" v-if="ViewCarrera">
        <CarreraView :carrera_id="carrera_id" />
    </div>
    <Contacto v-if="!View" />
    <div class="mb-9">
        <Logos />
    </div>
    <Informacion @quienesSomos="quienesSomos = $event" @servicios_id="servicios_id = $event"
        @carrera_id="carrera_id = $event" @carrera="carrera = $event" @ViewCarrera="ViewCarrera = $event"
        @ViewCurso="ViewCurso = $event" @curso="curso = $event" />
</template>
    
<script>


import Navber from '@/components/CcaSucre/Navbar/Navbar.vue';
import CarouselB from '@/components/CcaSucre/Carousel/Bienvenidos.vue';
import Carrera from '@/components/CcaSucre/Carrera/Carrera.vue';
import Logos from '@/components/CcaSucre/Logos/Logos.vue';
import Informacion from '@/components/CcaSucre/Informacion/Informacion.vue';
import Separador from '@/components/CcaSucre/Separador/Separador.vue';
import QuienesSomos from '@/components/CcaSucre/QuienesSomos/QuienesSomos.vue';
import Docente from '@/components/CcaSucre/Docente/Docente.vue';
import Personal from '@/components/CcaSucre/Personal/Personal.vue';
import CarreraView from '@/components/CcaSucre/Carrera/CarreraView.vue';
import CursoView from '@/components/CcaSucre/Curso/CursoView.vue';
import MisionVision from "@/components/CcaSucre/QuienesSomos/MisionVision.vue"
export default {
    name: 'CcaSucreMain',
    props: {
    },
    components: {
        Navber,
        CarouselB,
        Carrera,
        Logos,
        Informacion,
        Separador,
        QuienesSomos,
        Docente,
        Personal,
        CursoView,
        CarreraView,
        MisionVision
    },
    methods: {

    },
    data() {
        return {
            quienesSomos: false,
            curso: false,
            carrera: false,
            id_curso: 0,
            id_carrera: 0,
            servicios_id: 0,
            ViewCarrera: false,
            ViewCurso: false,
            cursos_id: 0
        };
    },
    computed: {
        View() {
            if (this.carrera || this.curso || this.quienesSomos || this.ViewCarrera || this.ViewCurso) {
                return true;
            }
            return false
        },
        activarnoticia() {

            if (this.carrera || this.curso) {
                return false;
            }
            if (this.quienesSomos || this.ViewCarrera || this.ViewCurso) {
                return true
            }
            return false;
        },
        activar() {
            if (this.quienesSomos || this.carrera || this.curso) {
                return true;
            }

            return false;
        },
    },
    mounted() {
    }



}
</script>
    
<style scoped>
.padding-col {
    padding: 0px;
}

.margin-p {
    margin: 50px 0px 50px 0px;
}


/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) {

    /* Fin de nemu*/
}

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) {}

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) {}

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) {}

@media (min-width: 1905px) {}
</style>
    