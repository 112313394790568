<template>
<div :v-model="gecurso(servicios_id)"></div>
    <div>
        <v-row justify="center" class="mt-8">
          <v-col 
          cols="10">
            <v-row justify="center">
              <template  v-for="(curso, index) in cursos" v-bind:key="index">
                  <v-col
                  cols="12"
                  sm = "6"
                  md = "4"
                  lg = "4"
                  xl = "4">
                      <v-card
                        class="mx-auto mb-6 "
                        max-width="350"
                      >
                      <div class="img-contedor">
                        <v-img
                            :src="baseUrl+''+baseImagen+''+curso.imagen"
                            width="100%"
                            aspect-ratio="1"
                            class="pa-0 ma-0 img"
                            @click="ViewCursos(curso)"
                        >
                        </v-img>
                      </div>
                        <v-card-title class="d-flex justify-center pa-0 borde-carrera">
                          <h6 class="text-LiberationSans-Bold text-center text-6912b3 pa-3">{{curso.nombre }}</h6>
                        </v-card-title>
                        <div class="d-flex justify-center">  
                          <v-btn block class="background-ffd101 text-6912b3 text-bn-tam " @click="ViewCursos(curso)" rounded="pill" >
                            <v-icon
                              end
                              icon="mdi-cursor-pointer"
                              class="text-6912b3"
                            ></v-icon>
                              Más Información
                          </v-btn>
                        </div>
                      </v-card>
                  </v-col>
                </template>
            </v-row>
          </v-col>
        </v-row>

    </div>
</template>

<script>
import axios from 'axios'
var url ='backend-ccasucre/crud-curso.php';


export default {
  name: 'CcaSucreCurso',
  props: {
    servicios_id  : Number,
  },
  components : {
  },
 methods: {
  gecurso(servicios_id){
    axios.post(this.baseUrl+''+url,{opcion:6,servicios_id:servicios_id})
        .then(Response =>{
          this.cursos=Response.data
        })
  },
   ViewCursos(cursos){
     this.$emit("cursos_id",cursos.id),
     this.$emit("ViewCursoTarija",false),
     this.$emit("ViewCursoSucre",true)
    } 
 },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 3;
      }

      if (screen.width > 960) {
        return 4;
      }

      return 1;
    },
    columns1() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 3;
      }
      if (screen.width > 960 && screen.width <= 1264) {
        return 4;
      }
      if (screen.width > 1264) {
        return 5;
      }

      return 1;
    },
    
    col_tam(){
      
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 4;
      }
      
      if (screen.width > 960 && screen.width <= 1264) {
        return 3;
      }

      if (screen.width > 1264) {
        return 2;
      }

      return 1;
    },
    
    col_tam1(){
      
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 4;
      }

      if (screen.width > 960) {
        return 3;
      }

      return 1;
    },
    show_arrows(){
      
      if (screen.width > 961 ) {
        return false;
      }
      return true;
    },
    show_arrows1(){
      
      if (screen.width > 1264 ) {
        return false;
      }
      return true;
    },
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "large";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  data(){
    return {
      x1 : true,
      //baseUrl : "http://localhost/",
      baseUrl : "https://cca.edu.bo/",
      baseImagen : "img-ccasucre/img-curso/",
      cursos : [],    
      tab: null,
    };
  },
  mounted(){
    }

      

}
</script>

<style scoped>

.padding-0{
    padding-top: 0px;
}
.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.text-Montserrat-Bold{
  font-family: "Montserrat-Bold";
}
.text-whit{
  color: white;
}
.text-004c8c{
  color: #004c8c;
}
.background-0d8dff{
   background: #0d8dff
}
.background-navbar{
    background: linear-gradient(90deg, #0d8dff, #6912b3 ,#004c8c);
}
.border-rad{
    
    border-radius: 9999px !important;
}

.margin-carrera{
    margin: 40px 0px 20px 0px
  }
.img{
 cursor: pointer;
  color: inherit;
}

.img-contedor:hover .img {
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
  }
.img-contedor {
  overflow:hidden;
  }
  .padding-quien{
    left: 0%;
    right: 0%;
    bottom: 0%;
    top: 50%;
    position: absolute;
}
.text-ffd101{
  color: #ffd101;
}
 .text-size{
    font-size: 6.5vw
  } 
  .borde-carrera{
    border: solid 3px #6912b3;
  }
  .background-014c8d{
    background : #014c8d;
  }
  .background-002a50{
    background : #002a50;
  }
  .background-ffd101{
    background : #ffd101;
  }
  .text-6912b3{
    color : #6912b3
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
 .text-size-icon{    
    font-size: 5vw;
  }
  .text-size-title{
    font-size: 2.2vw;
  }
  .text-size-subtitle{
    font-size: 2.3vw;
  }
  .text-size-carrera{
    font-size: 5.2vw;
  }   
  .text-tam{ 
    font-size: 5vw;
  }  
     
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
  .text-size-icon{    
    font-size: 4vw;
  }

  .text-size-title{
    font-size: 1.1vw;
  }
  .text-size-subtitle{
    font-size: 1.5vw;
  }
  .text-size-carrera{
    font-size: 5.5vw;
  }    
  .text-tam{ 
    font-size: 3vw;
  }

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
  .text-size-icon{    
    font-size: 2.2vw;
  }
  .text-size-title{
    font-size: 0.6vw;
  }
  .text-size-subtitle{
    font-size: 1vw;
  }
  .text-size-carrera{
    font-size: 5.5vw;
  }   
  .text-tam{ 
    font-size: 2vw;
  }
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  .text-size-icon{    
    font-size: 2.2vw;
  }
 
  .text-size-title{
    font-size: 0.7vw;
  }
  .text-size-subtitle{
    font-size: 1vw;
  }
  .text-size-carrera{
    font-size: 5.5vw;
  }   
  
  .text-tam{ 
    font-size: 2vw;
  }
 }
@media (min-width: 1905px)  { 
  .text-size-icon{    
    font-size: 1.5vw;
  }
   .text-size-title{
    font-size: 0.4vw;
  }
  .text-size-subtitle{
    font-size: 0.5vw;
  }
  .text-size-carrera{
    font-size: 5.5vw;
  }  
  .text-tam{ 
    font-size: 2vw;
  }
}


</style>
