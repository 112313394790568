<template>
<Navber @quienesSomos="quienesSomos=$event" @servicios_id="servicios_id=$event" @id_carrera="id_carrera=$event"   @carrera="carrera=$event" @id_curso="id_curso=$event"   @curso="curso=$event"  @ViewCarrera="ViewCarrera=$event" @ViewCurso="ViewCurso=$event" />
<div id="quiensomos" v-if="quienesSomos">
  <QuienesSomos  />
</div>
<div v-if="!View">
  <Carousel/>
</div>
<div id="Servicios" v-if="!View">
  <Servicios  @quienesSomos="quienesSomos=$event"  @servicios_id="servicios_id=$event"   @carrera="carrera=$event"   @curso="curso=$event" @ViewCarrera="ViewCarrera=$event" @ViewCurso="ViewCurso=$event" />
</div>
<div id="carrera" v-if="carrera">
  <Carrera @quienesSomos="quienesSomos=$event"  @carrera_id="carrera_id=$event"   @carrera="carrera=$event" @ViewCarrera="ViewCarrera=$event"  @ViewCurso="ViewCurso=$event" @curso="curso=$event"  />
</div>
<div id="carreras" v-if="ViewCarrera">
  <CarreraView :carrera_id="carrera_id"/>
</div>
<div id="curso" v-if="curso">
  <Curso :servicios_id="servicios_id" @quienesSomos="quienesSomos=$event"  @cursos_id="cursos_id=$event"   @carrera="carrera=$event" @ViewCarrera="ViewCarrera=$event" @ViewCurso="ViewCurso=$event"   @curso="curso=$event"/>
</div>
<div id="curso" v-if="ViewCurso">
  <CursoView :cursos_id="cursos_id"/>
</div>
<div id="Pilares" v-if="!View">
  <Pilares/>
</div>
<div id="Estadistica" v-if="!View">
  <Estadistica/>
</div>
<div id="redsocial" v-if="!View">
  <RedSocial/>
</div>
<div id="mapa" v-if="!View">
  <Mapa/>
</div>
<div id="noticia" v-if="!activarnoticia">
  <Noticias />
</div>
<div id="contacto">
  <Contacto/>
</div>
<Logos/>
<Informacion/>
</template>

<script>


import Navber from './Navbar/Navbar.vue';
import Carousel from './Carousel/Carousel.vue';
import Estadistica from './Estadistica/Estadistica.vue';
import Carrera from './Carrera/Carrera.vue';
import Curso from './Curso/Curso.vue';
import Mapa from './Mapa/Mapa.vue';
import RedSocial from './RedesSociales/RedesSociales.vue';
import Contacto from './Contactenos/Contactenos.vue';
import Logos from './Logos/Logos.vue';
import Servicios from './Servicios/Servicios.vue';
import Pilares from './Pilares/Pilares.vue';
import Noticias from './Noticias/Noticias.vue';
import Informacion from './Informacion/Informacion.vue';
import QuienesSomos from './QuienesSomos/QuienesSomos.vue';
import CarreraView from './Carrera/CarreraView.vue';
import CursoView from './Curso/CursoView.vue';

export default {
  name: 'CcaMain',
  props: {
  },
  components : {
    Navber,
    Carousel,
    Estadistica,
    Carrera,
    Curso,
    Mapa,
    RedSocial,
    Contacto,
    Logos,
    Servicios,
    Pilares,
    Noticias,
    Informacion,
    QuienesSomos,
    CarreraView,
    CursoView,
  },
 methods: {
   
 },
  data(){
    return {
      quienesSomos : false,
      curso : false,
      carrera : false,
      id_curso : 0,
      id_carrera : 0,
      servicios_id : 0,
      ViewCarrera : false,
      ViewCurso : false,
      cursos_id : 0
    };
  },
  computed: {
    View(){
    if ( this.carrera || this.curso || this.quienesSomos || this.ViewCarrera || this.ViewCurso  ) {
        return true;
      }
      return false
    },
    activarnoticia(){

      if ( this.carrera || this.curso ) {
        return false;
      }
      if (this.quienesSomos || this.ViewCarrera || this.ViewCurso) 
      {
        return true
      }
      return false;
    },
    activar() {
      if (this.quienesSomos || this.carrera || this.curso) {
        return true;
      }

      return false;
    },
  },
  mounted(){
    }

      

}
</script>

<style scoped>
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){ 
}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
     
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  
 }
@media (min-width: 1905px)  { 
}


</style>
