<template>
  <div >
    <h3 class="text-Arial-Bold text-black  text-center text-size-title">Nuestros CARRERAS</h3>
    <v-row justify="center">
      <v-col cols="11" v-if="tablas">
        <v-btn
          color="success"
           @click="formNuevo()"
        >
            CREAR <v-icon>mdi-table-plus</v-icon>
        </v-btn>        
        <v-table
            fixed-header
            height="900px" 
        >
            <thead >
            <tr class="light-blue darken-2" >
                <th class="text-left">
                #
                </th>
                <th class="text-left">
                NOMBRE
                </th>
                <th class="text-left">
                PLAN DE ESTUDIO
                </th>
                <th class="text-left">
                IMAGEN
                </th>
                <th class="text-left">
                ESTADO
                </th>
                <th class="text-left">
                ACCCIONES
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="(carrera, index) in carreras" v-bind:key="index"
            >
                <td>{{index+1}}</td>
                <td>{{carrera.nombre}} </td>
                <td > 
                  <span @click="formPdf (carrera)">{{carrera.plan_estudio}}</span>
                </td>
                <td> 
                  <div class="img-contedor">
                    <v-img
                        :src="baseUrl+''+baseImagen+''+carrera.imagen"
                        width="100%"
                        aspect-ratio="1"
                        class="img"
                        @click="formImagen (carrera)"
                    >
                    </v-img>
                  </div>
                </td>
                <td class="text-blue" v-if="carrera.estado == 1">
                  HABILITADO
                </td>
                <td class="text-red" v-if="carrera.estado == 0">
                  DESHABILITADO 
                </td>
                <td class="text-white">
                <v-btn
                  icon="mdi-table-edit"
                  color="info"
                  @click="formEditar (carrera)"
                ></v-btn>
                <v-btn
                    icon="mdi-table-eye-off"
                    @click="formElimina(carrera.id)"
                    class="background-red"
                    v-if="carrera.estado == 1"
                ></v-btn>
                <v-btn
                    icon="mdi-table-eye"
                    @click="formHabilitar(carrera.id)"
                    class="background-success"
                    v-if="carrera.estado == 0"
                ></v-btn>
                </td>
            </tr>
            </tbody>
        </v-table>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-10" v-if="!tablas">
      <v-col cols="11">
       <v-row justify="center">
          <v-text-field
          class="pr-2"  
          v-if="!if_imagen"
          :rules="textRules"
          label="Nombre"
          required
          v-model="carrera.nombre"
          ></v-text-field>
          <v-text-field
          class="pr-2"  
          v-if="!if_imagen"
          :rules="textRules"
          label="Duracion"
          required
          v-model="carrera.Duracion"
          ></v-text-field> 
       </v-row>
       <v-row justify="center">    
        <v-col >
          <span  v-if="!if_imagen" class="text-Montserrat-Black mb-4">MODALIDAD</span>
          <editor
              v-if="!if_imagen"
              v-model="carrera.modalidad"
              class="pa-4"
              :init="{
                  plugins: [
                  'lists link image paste help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
              }"
              initial-value="Once upon a time..."
              />   
        </v-col>     
        <v-col >
          <span  v-if="!if_imagen" class="text-Montserrat-Black mb-4">CERTIFICACION</span>
          <editor
              v-if="!if_imagen"
              v-model="carrera.certificacion"
              class="pa-4"
              :init="{
                  plugins: [
                  'lists link image paste help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
              }"
              initial-value="Once upon a time..."
              />    
        </v-col>            
       </v-row>
       <v-row justify="center">    
        <v-col >
          <span  v-if="!if_imagen" class="text-Montserrat-Black mb-4">CAMPO LABORAL</span>
          <editor
              v-if="!if_imagen"
              v-model="carrera.campo_laboral"
              class="pa-4"
              :init="{
                  plugins: [
                  'lists link image paste help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
              }"
              initial-value="Once upon a time..."
              />   
        </v-col>     
        <v-col >
          <span  v-if="!if_imagen" class="text-Montserrat-Black mb-4">REQUISITO DE INGRESO</span>
          <editor
              v-if="!if_imagen"
              v-model="carrera.requisito_ingreso"
              class="pa-4"
              :init="{
                  plugins: [
                  'lists link image paste help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
              }"
              initial-value="Once upon a time..."
              />   
        </v-col>            
       </v-row>
       <v-row justify="center">    
        <v-col >
          <span  v-if="!if_imagen" class="text-Montserrat-Black mb-4">REQUISITO DE GRADUACION</span>
          <editor
              v-if="!if_imagen"
              v-model="carrera.requisito_graduacion"
              class="pa-4"
              :init="{
                  plugins: [
                  'lists link image paste help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
              }"
              initial-value="Once upon a time..."
              />    
        </v-col>     
        <v-col >
          <span  v-if="!if_imagen" class="text-Montserrat-Black mb-4">PERFIL EGRESO</span>
          <editor
              v-if="!if_imagen"
              v-model="carrera.perfil_egreso"
              class="pa-4"
              :init="{
                  plugins: [
                  'lists link image paste help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
              }"
              initial-value="Once upon a time..."
              />  
        </v-col>            
       </v-row>
       <v-row justify="center">    
        <v-col >
          <span  v-if="!if_imagen" class="text-Montserrat-Black mb-4">DESCRIPCION</span>
          <editor
              v-if="!if_imagen"
              v-model="carrera.descripcion"
              class="pa-4"
              :init="{
                  plugins: [
                  'lists link image paste help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
              }"
              initial-value="Once upon a time..."
              />    
        </v-col>            
       </v-row>
       <v-row justify="center"> 
          <v-file-input 
          class="pr-2"
          accept="image/*"
          v-if="if_imagen == if_crear"
          label="imagen"
          show-size
          filled
          prepend-icon="mdi-camera"
          @change="cargarImagen"
          ></v-file-input> 
          <v-file-input 
          class="pl-2"
          v-if="if_imagen == if_crear"
          label="Plan de estudio"    
          show-size
          filled
          prepend-icon="mdi-file-pdf-box"
          @change="cargarpdf"
          ></v-file-input>     
       </v-row>        
      <v-btn
        text
        @click="tablas=true"
      >Cancelar</v-btn>
      <v-btn
        text
        @click="guardar_s"
      >Guardar</v-btn>
      </v-col>

    </v-row>
  </div>
<v-dialog
      v-model="dialog_img"
    >
    <v-toolbar
      class="background-personal text-white"
    >Imagen</v-toolbar>
    <v-card  width="600">
      <v-card-text>
        <span >Cambiar imagen</span>        
        <v-file-input 
        class="pr-2"
        v-if="if_imagen == if_crear"
        label="imagen"
        show-size
        filled
        prepend-icon="mdi-camera"
        @change="cargarImagen"
        ></v-file-input> 
      </v-card-text>
      <v-card-actions class="justify-end text-white">
        <v-btn
          class="background-red"
          @click="dialog_img=false"
        >Cancelar</v-btn>
        <v-btn
          class="background-success "
          @click="guardar_s"
        >Guardar</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>

    

    <v-dialog
      v-model="dialogEliminar"
    >
    <v-toolbar
      class="background-red text-white"
    >DESHABILITAR</v-toolbar>
    <v-card>
      <v-card-text>
        <span >Esta seguro de desHabiliatar ?</span>
      </v-card-text>
      <v-card-actions class="justify-end text-white">
        <v-btn
          class="background-success"
          @click="dialogEliminar=false"
        >Cancelar</v-btn>
        <v-btn
          class="background-red "
          @click="eliminar_slider"
        >Si</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogHabilitar"
    >
    <v-toolbar
      class="background-1C759F text-white"
    >HABILITAR</v-toolbar>
    <v-card>
      <v-card-text>
        <span >Esta seguro de Habiliatar ?</span>
      </v-card-text>
      <v-card-actions class="justify-end text-white">
        <v-btn
          class="background-success"
          @click="dialogHabilitar=false"
        >Cancelar</v-btn>
        <v-btn
          class="background-red "
          @click="habilitar_slider"
        >Si</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_pdf"
    >
    <v-toolbar
      class="background-personal text-white"
    >PDF</v-toolbar>
    <v-card  width="600">
      <v-card-text>
        <span >Cambiar el paln de estudio</span>        
        <v-file-input 
        class="pr-2"
        label="plan estudio"
        show-size
        filled
        prepend-icon="mdi-file-pdf-box"
        @change="cargarpdf"
        ></v-file-input> 
      </v-card-text>
      <v-card-actions class="justify-end text-white">
        <v-btn
          class="background-red"
          @click="dialog_pdf=false"
        >Cancelar</v-btn>
        <v-btn
          class="background-success "
          @click="guardar_s"
        >Guardar</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
</template>

<script>

//import { Carousel, Slide, Navigation } from 'vue3-carousel';
import Editor from '@tinymce/tinymce-vue'
import axios from 'axios'
var url ='backend-ccasucre/crud-carrera.php';
var url_crago ='backend-ccasucre/crud-cargo.php';
var urlsubirimagen ='backend-ccasucre/upload-carrera.php'
var urlsubirpdf ='backend-ccasucre/upload-pdf-carrera.php'

export default {
  name: 'CcaSucreCarreraConfig',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
      'editor': Editor
  },
 methods: {
    formNuevo (){
      this.tablas = false;
      this.operacion='crear';
      this.carrera.id = "";
      this.carrera.nombre = "";
      this.carrera.modalidad  = "";
      this.carrera.certificacion = "";
      this.carrera.Duracion = "";
      this.carrera.campo_laboral = "";
      this.carrera.requisito_ingreso = "";
      this.carrera.requisito_graduacion  = "";
      this.carrera.perfil_egreso = "";
      this.carrera.descripcion = "";
      this.carrera.plan_estudio = "";
      this.carrera.imagen = "";
      this.if_imagen=false;
      this.if_crear = false;
    },
    formEditar (carrera){
      this.tablas = false
      this.operacion='editar';
      this.carrera.id = carrera.id;
      this.carrera.nombre = carrera.nombre;
      this.carrera.modalidad = carrera.modalidad;
      this.carrera.certificacion =carrera.certificacion ,
      this.carrera.Duracion = carrera.Duracion;
      this.carrera.campo_laboral = carrera.campo_laboral;
      this.carrera.requisito_ingreso = carrera.requisito_ingreso;
      this.carrera.requisito_graduacion = carrera.requisito_graduacion;
      this.carrera.perfil_egreso = carrera.perfil_egreso;
      this.carrera.descripcion =carrera.descripcion ,
      this.carrera.plan_estudio = carrera.plan_estudio;
      this.carrera.imagen = carrera.imagen;
      this.if_imagen = false;
      this.if_crear = true;
    },
    formImagen (carrera){
      this.dialog_img = true;
      this.operacion='img';
      this.carrera.id = carrera.id;
      this.carrera.nombre = carrera.nombre;
      this.carrera.modalidad = carrera.modalidad;
      this.carrera.certificacion =carrera.certificacion ,
      this.carrera.Duracion = carrera.Duracion;
      this.carrera.campo_laboral = carrera.campo_laboral;
      this.carrera.requisito_ingreso = carrera.requisito_ingreso;
      this.carrera.requisito_graduacion = carrera.requisito_graduacion;
      this.carrera.perfil_egreso = carrera.perfil_egreso;
      this.carrera.descripcion =carrera.descripcion ,
      this.carrera.plan_estudio = carrera.plan_estudio;
      this.carrera.imagen = carrera.imagen;
      this.if_crear = true;
      this.if_imagen = true;
    },
    formPdf (carrera){
      this.dialog_pdf = true;
      this.operacion='pdf';
      this.carrera.id = carrera.id;
      this.carrera.nombre = carrera.nombre;
      this.carrera.modalidad = carrera.modalidad;
      this.carrera.certificacion =carrera.certificacion ,
      this.carrera.Duracion = carrera.Duracion;
      this.carrera.campo_laboral = carrera.campo_laboral;
      this.carrera.requisito_ingreso = carrera.requisito_ingreso;
      this.carrera.requisito_graduacion = carrera.requisito_graduacion;
      this.carrera.perfil_egreso = carrera.perfil_egreso;
      this.carrera.descripcion =carrera.descripcion ,
      this.carrera.plan_estudio = carrera.plan_estudio;
      this.carrera.imagen = carrera.imagen;
      this.if_crear = true;
      this.if_imagen = true;
    },   
    formElimina(id){
      this.carrera.id=id;
      this.dialogEliminar = true
    },
    formHabilitar(id){
      this.carrera.id=id;
      this.dialogHabilitar = true
    },       
   guardar_s(){
     
    if(this.operacion=='crear' || this.operacion=='img'){
      this.subirImagen();              
    }
    if(this.operacion=='editar'){ 
      this.editar_slider();                           
    }
    if(this.operacion=='pdf'){ 
      this.subirPdf();                           
    }
    this.dialog_img=false;
    this.dialog_pdf=false;
   }, 
    guardar_slider (){
      if(this.operacion=='crear'){
        this.crear_slider();                 
      }
      if(this.operacion=='img'){ 
        this.subirImagen();                           
      }
      if(this.operacion=='editar'){ 
        this.editar_slider();                           
      }
      this.tablas=true;  
    },
   cargarpdf(e){
    this.file_pdf = e.target.files[0];
   },
   subirPdf(){
    var fd = new FormData();
    fd.append("file", this.file_pdf, this.file_pdf.name)
    //console.log(fd.getAll("file"));
    
    axios
    .post(this.baseUrl+''+urlsubirpdf, fd)
    .then(response => {
        //console.log(response.data)
      this.carrera.plan_estudio=response.data[1];
      if(this.operacion=='pdf'){ 
        this.editar_slider();                          
      }else{
        this.crear_slider();
      }
      this.mostrar();
    })
    .catch(error => {
        console.log(error.data)
    })
   },
   cargarImagen(e){
    this.file = e.target.files[0];
   },
   subirImagen(){
    var fd = new FormData();
    fd.append("file", this.file, this.file.name)
    //console.log(fd.getAll("file"));
    
    axios
    .post(this.baseUrl+''+urlsubirimagen, fd)
    .then(response => {
        //console.log(response.data)
      this.carrera.imagen=response.data[1];
      if(this.operacion=='img'){ 
        this.editar_slider();                          
      }else{
        this.subirPdf();
      }
    })
    .catch(error => {
        console.log(error.data)
    })
   },
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:5})
      .then(Response =>{
        this.carreras=Response.data
      })
    },
    mostrar_select(id){
      axios.post(this.baseUrl+''+url_crago,{opcion:7,id:id})
      .then(Response =>{
        this.select=Response.data[0]
      })
    },
    mostrar_cargo(){
      axios.post(this.baseUrl+''+url_crago,{opcion:6})
      .then(Response =>{
        this.cargos=Response.data
      })
    },    
    editar_slider (){
      axios.post(this.baseUrl+''+url, {opcion:3,id:this.carrera.id,nombre: this.carrera.nombre, modalidad:this.carrera.modalidad,certificacion:this.carrera.certificacion,
                Duracion: this.carrera.Duracion, campo_laboral:this.carrera.campo_laboral,requisito_ingreso :this.carrera.requisito_ingreso ,requisito_graduacion:this.carrera.requisito_graduacion,
                perfil_egreso:this.carrera.perfil_egreso,descripcion:this.carrera.descripcion, plan_estudio:this.carrera.plan_estudio, imagen:this.carrera.imagen })
        .then(response =>{
          response;
          this.mostrar();
        });  
      this.tablas= true;

    },
    crear_slider (){        
      axios.post(this.baseUrl+''+url, {opcion:2, id:this.carrera.id,nombre: this.carrera.nombre, modalidad:this.carrera.modalidad,certificacion:this.carrera.certificacion,
                Duracion: this.carrera.Duracion, campo_laboral:this.carrera.campo_laboral,requisito_ingreso :this.carrera.requisito_ingreso ,requisito_graduacion:this.carrera.requisito_graduacion,
                perfil_egreso:this.carrera.perfil_egreso,descripcion:this.carrera.descripcion, plan_estudio:this.carrera.plan_estudio, imagen:this.carrera.imagen  })
        .then(response =>{
          response;
          this.mostrar();
          console.log(response);
          console.log(this.carrera);
        });  
      this.tablas= true;
    },
    eliminar_slider (){
      axios.post(this.baseUrl+''+url, {opcion:4, id:this.carrera.id, estado: false })
        .then(response =>{
          response;
          this.mostrar();

        });        
      this.carrera.id = null;
      this.dialogEliminar= false;
    },
    habilitar_slider(){
      axios.post(this.baseUrl+''+url, {opcion:4, id:this.carrera.id, estado: true })
        .then(response =>{
          response;
          this.mostrar();
        });  
      
      this.carrera.id = null;
      this.dialogHabilitar= false;
    },
 }, 
  data(){
    return {
      //baseUrl : "http://localhost/",
      baseUrl : "https://cca.edu.bo/",
      baseVideo : "https://www.youtube.com/embed/",
      baseImagen : "img-ccasucre/img-carrera/",
      carreras : {},
      cargos : [],
      carrera : {
        id: null,
        nombre : "",
        modalidad : "",
        certificacion  : "",
        Duracion : "",
        campo_laboral : "",
        requisito_ingreso : "",
        requisito_graduacion : "",
        perfil_egreso : "",
        descripcion : "",
        plan_estudio : "",
        imagen : "",
        servicios_id : "",
        estado : ""
      },         
      select: { id: '', nombre: '' },   
      if_imagen : true,
      if_crear : true,
      tablas : true,
      dialog_img : false,
      file: null,
      file_pdf: null,
      dialogEliminar : false,
      dialogHabilitar : false,
      dialog_pdf : false,
    }
  },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 2;
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 3;
      }
      if (screen.width > 1264) {
        return 4;
      }

      return 1;
    },
    col_tam() {
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 6;
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 4;
      }
      if (screen.width > 1264) {
        return 3;
      }

      return 1;
    }
  },
  mounted(){
      this.mostrar();
      this.mostrar_cargo();
    }

      

}
</script>

<style scoped>
.v-card--variant-contained {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%);
}
.text-keep-calm-font{
  font-family: "keep-calm-font";
}
.text-MontserratAlternates-Regular{
  font-family: "MontserratAlternates-Regular";
}
.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}
.text-Arial-Bold{
  font-family: "Arial-Bold";
}

.margin-0{
  margin: 0px;
}

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.border-001C29{
  border: #001C29;
  border-style: solid;
  border-color: green;
  border-width: 10px;
}
.background-success{
  background-color : green;
}
.background-red{
  background-color : red;
}
.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}
.background-0d8dff{
  background-color: #0d8dff;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.background-014c8d {
  background-color: #014c8d;

}
.text-ffd000{
  color : #ffd000;
}
.text-014c8d{
  color : #014c8d;
}
.background-ffd000{
  background-color: #ffd000;
}
.padding-next{
  
    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
.padding-prev{

    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
.height-div{
  height: 50px;
}
.whatsapp{
 cursor: pointer;
}
.whatsapp:hover{
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  color: #25D366;
	transform: translateY(-7px);
}
.email{
 cursor: pointer;
}
.email:hover{
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  color: #8f39ce;
	transform: translateY(-7px);
}
.background-personal{
    background: linear-gradient(90deg, #004c8c, #1496d9);
}
.img{
 cursor: pointer;
  color: inherit;
}

.img-contedor:hover .img {
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
  }
.img-contedor {
  overflow:hidden;
  }
@media (min-width: 0px) and (max-width: 600px) { 
  .text-size-icon{
    font-size: 15vw;
  }
  .text-size-title{
    font-size: 8vw;
  }
 .video-width{
    width: 210px;
  }
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
   /** sive todo */
   .text-size-icon{
    font-size: 10vw;
  }
  .text-size-title{
    font-size: 5vw;
  }
  .video-width{
    width: 180px;
  }
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
   .text-size-icon{
    font-size: 8vw;
  }
  .text-size-title{
    font-size: 4vw;
  }
.video-width{
    width: 200px;
  }
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
     .text-size-icon{
    font-size: 6vw;
  }
  .text-size-title{
    font-size: 3vw;
  }
  .video-width{
    width: 240px;
  }

 }
@media (min-width: 1905px)  { 
 
     .text-size-icon{
    font-size: 4vw;
  }
  .text-size-title{
    font-size: 2.5vw;
  }
  .video-width{
    width: 300px;
    height: 200px;
  }
 }

</style>
