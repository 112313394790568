<template>
  <div class="background-navbar margin-est">
      <v-row justify="center">
          <v-col
          cols="10"
          sm = "10"
          md = "10"
          lg = "10"
          xl = "10"
          >
            <v-row justify="center" >
                <v-col
                cols="12"
                sm = "12"
                md = "3"
                lg = "3"
                xl = "3"
                >    <div class="justify-center padding-text">
                        <h1 class="text-Montserrat-Black text-white text-center text-title1-size"> Nuestros</h1>
                        <h1 class="text-Montserrat-Black text-ffcf00 text-center text-title-size"> PILARES</h1>
                    </div>
                </v-col>
                <v-col
                cols="12"
                sm = "12"
                md = "9"
                lg = "9"
                xl = "9"
                class="background-white margin-pilar"
                >
                  <v-row justify="center">
                      <v-col 
                      cols="12"
                      sm = "12"
                      md = "6"
                      lg = "6"
                      xl = "6">
                        <v-row justify="center">
                          <v-col 
                            cols="12"
                            sm = "12"
                            md = "10"
                            lg = "10"
                            xl = "10">
                            <v-row justify="center"> 
                              <v-col 
                              cols="3">
                                <v-img 
                                  src="../../../assets/pilares/tecnologias.webp"
                                  width="100%"></v-img>
                              </v-col>
                              <v-col 
                              cols="9">
                                
                              <v-card-title class="text-Poppins-Medium text-subtitle-size">
                                Tecnología
                              </v-card-title>
                              </v-col>
                              <v-col 
                              cols="3">
                                <v-img 
                                  src="../../../assets/pilares/Lider.webp"
                                  width="100%"></v-img>
                              </v-col>
                              <v-col 
                              cols="9">
                                
                              <v-card-title class="text-Poppins-Medium text-subtitle-size" >
                                Liderazgo, desarrollo personal y coaching
                              </v-card-title>
                              </v-col>
                              <v-col 
                              cols="3">
                                <v-img 
                                  src="../../../assets/pilares/Empredimiento.webp"
                                  width="100%"></v-img>
                              </v-col>
                              <v-col 
                              cols="9">
                                
                              <v-card-title class="text-Poppins-Medium text-subtitle-size" >
                                Emprendimiento e innovación
                              </v-card-title>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col 
                      cols="12"
                      sm = "12"
                      md = "6"
                      lg = "6"
                      xl = "6">
                        <v-row justify="center">
                          <v-col 
                          cols="12"
                          sm = "12"
                          md = "10"
                          lg = "10"
                          xl = "10">
                            <v-row justify="center">
                              <v-col 
                              cols="3">
                                <v-img 
                                  src="../../../assets/pilares/Responsabilidad.webp"
                                  width="100%"></v-img>
                              </v-col>
                              <v-col 
                              cols="9">
                                
                              <v-card-title class="text-Poppins-Medium text-subtitle-size" >
                                Resposabilidad Social y Ambiental
                              </v-card-title>
                              </v-col>
                              <v-col 
                              cols="3">
                                <v-img 
                                  src="../../../assets/pilares/idiomas.webp"
                                  width="100%"></v-img>
                              </v-col>
                              <v-col 
                              cols="9">
                                
                              <v-card-title class="text-Poppins-Medium text-subtitle-size" >
                                Comunicación e idiomas
                              </v-card-title>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                  </v-row>
                </v-col>
            </v-row>
          </v-col>
      </v-row>
  </div>
</template>

<script>


export default {
  name: 'CcaPilares',
  props: {
  },
  components : {
  },
 methods: {

 },
  data(){
    return {
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      nombre : "",
      apellido  : "",
      celular  : "",
      correo  : "",
        //baseUrl : "http://localhost/",
        baseUrl : "https://cca.edu.bo/",
    };
  },
  mounted(){
    }

      

}
</script>

<style scoped>

.text-Poppins-Medium{
  font-family: "Poppins-Medium";
}
.text-LiberationSans-Bold{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.background-ffcf00{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
}
.text-whit{
  color: white;
}
.text-ffcf00{
  color: #ffcf00;
}
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.background-white{
    background: white;
}
.background-383838{
  background: #383838;
}
.border-rad{
    
    border-radius: 9999px !important;
}
.margin-0{
  margin : 0px
}
.margin-est{
    margin: 20px 0px 20px 0px
  }
  .margin-pilar{
        margin: 40px 0px 40px 0px;
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  .text-subtitle-size{
    font-size: 4vw;
  }
  .text-title-size{
    font-size: 8vw;
  }
  
  .text-title1-size{
    font-size: 6vw;
  }
    
    .padding-text{
        padding-top: 3%;
    } 
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
  
  .text-subtitle-size{
    font-size: 4vw;
  }
  .text-title-size{
    font-size: 8vw;
  }
  
  .text-title1-size{
    font-size: 6vw;
  }
    
    .padding-text{
        padding-top: 3%;
    } 

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
  .text-subtitle-size{
    font-size: 1.3vw;
  }
  .text-title-size{
    font-size: 3.5vw;
  }
  
  .text-title1-size{
    font-size: 2.5vw;
  }
    
    .padding-text{
        padding-top: 45%;
    } 
    
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
    
  .text-subtitle-size{
    font-size: 1.2vw;
  }
  .text-title-size{
    font-size: 3.5vw;
  }
  
  .text-title1-size{
    font-size: 2.5vw;
  }
    
    .padding-text{
        padding-top: 40%;
    } 
    
 }
@media (min-width: 1905px)  { 

  .text-subtitle-size{
    font-size: 1.5vw;
  }
  .text-title-size{
    font-size: 3.5vw;
  }
  
  .text-title1-size{
    font-size: 2.5vw;
  }
    
    .padding-text{
        padding-top: 30%;
    } 
}


</style>
