<template>
<Navber  @servicios_id="servicios_id=$event" @id_carrera="id_carrera=$event"   @carrera="carrera=$event" @id_curso="id_curso=$event"   @curso="curso=$event"  @ViewCarrera="ViewCarrera=$event" @ViewCurso="ViewCurso=$event"/>
<v-row v-if="!View">
  <v-col cols="12" class="padding-col">
    <Carousel/>
  </v-col>
</v-row>
<div class="background-navbar" v-if="!View">
  <Sedes />
  <div class="pb-12">
    <Servicios @servicios_id="servicios_id=$event" @id_carrera="id_carrera=$event"   @carrera="carrera=$event" @id_curso="id_curso=$event"   @curso="curso=$event"  @ViewCarrera="ViewCarrera=$event" @ViewCurso="ViewCurso=$event"/>
  </div>
</div>
<div v-if="curso">
  <ProgramasCurso :servicios_id="servicios_id" @quienesSomos="quienesSomos=$event"  @cursos_id="cursos_id=$event"   @carrera="carrera=$event" @ViewCarrera="ViewCarrera=$event" @ViewCurso="ViewCurso=$event"   @curso="curso=$event"/>
</div>
<div>
  <ProgramasCarrera v-if="carrera"/>
</div>
<div>
  <InstitutoCca v-if="!View"/>
</div>
<Informacion @quienesSomos="quienesSomos=$event" @servicios_id="servicios_id=$event" @carrera_id="carrera_id=$event"   @carrera="carrera=$event" @ViewCarrera="ViewCarrera=$event"  @ViewCurso="ViewCurso=$event" @curso="curso=$event"/>
</template>

<script>

import Navber from '@/components/Principal/Navbar/Navbar.vue';
import Carousel from '@/components/Principal/Carousel/Carousel.vue';
import Sedes from '@/components/Principal/Sedes/Sedes.vue';
import InstitutoCca from '@/components/Principal/InstitutoCca/InstitutoCca.vue';
import Informacion from '@/components/Principal/Informacion/Informacion.vue';
import ProgramasCurso from '@/components/Principal/Programas/ProgramasCurso.vue';
import ProgramasCarrera from '@/components/Principal/Programas/ProgramasCarrera.vue';
import Servicios from '@/components/Principal/Servicios/Servicios.vue';

export default {
  name: 'PrincipalMain',
  props: {
  },
  components : {
    Navber,
    Carousel,
    Informacion,
    InstitutoCca,
    Sedes,
    ProgramasCurso,
    ProgramasCarrera,
    Servicios,
  },
 methods: {
   
 },
  data(){
    return {
      quienesSomos : false,
      curso : false,
      carrera : false,
      id_curso : 0,
      id_carrera : 0,
      servicios_id : 0,
      ViewCarrera : false,
      ViewCurso : false,
      cursos_id : 0
    };
  },
  computed: {
    View(){
    if ( this.carrera || this.curso || this.quienesSomos || this.ViewCarrera || this.ViewCurso  ) {
        return true;
      }
      return false
    },
    activarnoticia(){

      if ( this.carrera || this.curso ) {
        return false;
      }
      if (this.quienesSomos || this.ViewCarrera || this.ViewCurso) 
      {
        return true
      }
      return false;
    },
    activar() {
      if (this.quienesSomos || this.carrera || this.curso) {
        return true;
      }

      return false;
    },
  },
  mounted(){
    }

      

}
</script>

<style scoped>

.padding-col{
  padding: 0px;
}
.margin-p{
  margin:  50px 0px 50px 0px;
}


.background-navbar{
    background: linear-gradient(180deg, #0084ff, #00398c);
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){ 
}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
     
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  
 }
@media (min-width: 1905px)  { 
}


</style>
