<template>
  <div class="personal-sucre">
    <h2 class="text-center title">Conoce a Nuestro Personal</h2>
    <p class="text">Nuestro honorable personal administrativo</p>
    <v-carousel cycle height="max-height" hide-delimiters>
      <template v-slot:prev="{ props }">
        <v-btn icon @click="props.onClick"><v-icon class=" text-size-icon">mdi-chevron-double-left</v-icon></v-btn>
      </template>
      <template v-slot:next="{ props }">
        <v-btn icon @click="props.onClick"><v-icon class="text-size-icon">mdi-chevron-double-right</v-icon></v-btn>
      </template>

      <template v-if="data && data.length > 0">
        <v-carousel-item v-for="(row, i) in rows" :key="i">
          <v-row>
            <template v-for="(docente, index) in row" :key="index">
              <v-col :cols="col_tam">
                <div class="mb-6 ">
                  <v-card max-width="350" class="our-team">
                    <div class="picture">
                      <v-img :src="docente.img.original_url" width="100%"></v-img>
                    </div>
                    <div class="team-content">
                      <h3 class="name">{{ docente.nombres }} {{ docente.apellido }}</h3>
                      <h4 class="title">{{ docente.cargo.nombre }}</h4>
                    </div>
                    <ul class="social pt-2 pb-2">
                      <li><v-icon icon="mdi-whatsapp" class="text-white "
                          @click="href('http://wa.me/591' + docente.celular)"></v-icon></li>
                      <li><v-icon icon="mdi-email" class="text-white ml-4"></v-icon></li>
                    </ul>
                  </v-card>
                </div>
              </v-col>
            </template>
          </v-row>
        </v-carousel-item>
      </template>
    </v-carousel>
  </div>
</template>

<script>
import usePeticion from '@/assets/helpers/Peticion';

export default {
  name: 'CcaSucrePersonal',
  props: ['id'],
  components: {},
  methods: {
    href(href) {
      window.open(href);
    },
  },
  data() {
    return {
      data: null
    }
  },
  async created() {
    const urlPeticion = this.id ? 'personasIDCargo/' + this.id : 'personas'
    const { fetchData } = usePeticion(urlPeticion);
    try {
      this.data = await fetchData();
    } catch (error) {
      console.error('Error:', error);
    }
  },
  computed: {
    columns() {
      if (screen.width <= 600) {
        return 1;
      } else if (screen.width <= 960) {
        return 2;
      } else if (screen.width <= 1264) {
        return 3;
      } else {
        return 4;
      }
    },
    col_tam() {
      return 12 / this.columns;
    },
    rows() {
      if (!this.data) return [];
      const numRows = Math.ceil(this.data.length / this.columns);
      const rows = [];
      for (let i = 0; i < numRows; i++) {
        const row = [];
        for (let j = 0; j < this.columns; j++) {
          const dataIndex = i * this.columns + j;
          if (dataIndex < this.data.length) {
            row.push(this.data[dataIndex]);
          }
        }
        rows.push(row);
      }
      return rows;
    },
  },
}
</script>
