<template>
  <div class="background-navbar1">
    <!--<h1 class="text-LiberationSans-Bold text-center text-tam"> NUESTRA CORPORACIÓN</h1>-->
      <v-row justify="center" class="py-10">
        <v-col 
          cols="10"
          sm="12"
          md="10"
          lg="10"
          xl="10">
          <v-row justify="center">
            <v-col
              cols="12"
              sm = "6"
              md = "4"
              lg = "4"
              xl = "4">
                <p class="text-ffcf00 text-Montserrat-Black text-size mb-3 text-center">  
                  OFERTA ACADÉMICA
                </p>
                <div class="d-flex mb-2 px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                  <span class="text-Montserrat-Black cursor  pl-2 text-white " v-on:click="ViewServicio(1)">Carreras</span>
                </div>       
                <div class="d-flex mb-2 px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                  <span class="text-Montserrat-Black text-white pl-2 cursor" v-on:click="ViewServicio(2)">Capacitación</span>
                </div>
                <div class="d-flex mb-2 px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                  <span class="text-Montserrat-Black text-white pl-2 cursor" v-on:click="ViewServicio(3)">Formación Continua</span>
                </div>
                <div class="d-flex mb-2 px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                  <span class="text-Montserrat-Black text-white pl-2 cursor" v-on:click="ViewServicio(4)">Pre Universitarios</span>
                </div>
            </v-col>
            <v-col
              cols="12"
              sm = "6"
              md = "2"
              lg = "2"
              xl = "2" >
                <p class="text-ffcf00 text-Montserrat-Black text-size mb-3 text-center">  
                  SEDES
                </p>                
                <div class="d-flex mb-2 px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                  <span class="text-Montserrat-Black text-white pl-2 cursor" @click="selectSede('sucre')">SUCRE</span>
                </div>
                <div class="d-flex mb-2 px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                  <span class="text-Montserrat-Black text-white pl-2 cursor" @click="selectSede('tarija')">TARIJA</span>
                </div>
            </v-col>
            <v-col
              cols="12"
              sm = "12"
              md = "6"
              lg = "6"
              xl = "6" >
                <p class="text-ffcf00 text-Montserrat-Black text-size mb-3 text-center">  
                  CONTACTANOS
                </p>
                <v-row>
                  <v-col 
                    cols="12"
                    sm = "6"
                    md = "6"
                    lg = "6"
                    xl = "6">
                    <div class="d-flex mb-2  px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                      <v-icon icon="mdi-facebook" class="text-black"></v-icon><span class="text-Montserrat-Black cursor pl-2 text-white" v-on:click="href('')">CCA SUCRE</span>
                    </div>
                    <div class="d-flex mb-2  px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                      <v-icon icon="mdi-instagram" class="text-black"></v-icon><span class="text-Montserrat-Black cursor pl-2 text-white" v-on:click="href('')">cca_sucre7</span>
                    </div>
                    <div class="d-flex mb-2  px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                      <v-icon icon="mdi-whatsapp" class="text-black"></v-icon><span class="text-Montserrat-Black cursor pl-2 text-white" v-on:click="href('')">+59171864008</span>
                    </div>
                    <div class="d-flex mb-2  px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                      <v-icon icon="mdi-email" class="text-black"></v-icon><span class="text-Montserrat-Black cursor pl-2 text-white" v-on:click="href('')">CCA.SUCRE@CCA.EDU.BO</span>
                    </div>
                    <div class="d-flex mb-2  px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                      <v-icon icon="mdi-google-maps" class="text-black"></v-icon><span class="text-Montserrat-Black cursor pl-2 text-white" v-on:click="href('')">Calle Calvo N°18</span>
                    </div>
                  </v-col>
                  <v-col  
                    cols="12"
                    sm = "6"
                    md = "6"
                    lg = "6"
                    xl = "6">
                    <div class="d-flex mb-2  px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                      <v-icon icon="mdi-facebook" class="text-black"></v-icon><span class="text-Montserrat-Black cursor pl-2 text-white" v-on:click="href('')">CCA TARIJA</span>
                    </div>
                    <div class="d-flex mb-2  px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                      <v-icon icon="mdi-instagram" class="text-black"></v-icon><span class="text-Montserrat-Black cursor pl-2 text-white" v-on:click="href('')">CCA TARIJA</span>
                    </div>
                    <div class="d-flex mb-2  px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                      <v-icon icon="mdi-whatsapp" class="text-black"></v-icon><span class="text-Montserrat-Black cursor pl-2 text-white" v-on:click="href('')">+59171864008</span>
                    </div>
                    <div class="d-flex mb-2  px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                      <v-icon icon="mdi-email" class="text-black"></v-icon><span class="text-Montserrat-Black cursor pl-2 text-white" v-on:click="href('')">GESTIÓN@CCA.EDU.BO</span>
                    </div>
                    <div class="d-flex mb-2  px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                      <v-icon icon="mdi-google-maps" class="text-black"></v-icon><span class="text-Montserrat-Black cursor pl-2 text-white" v-on:click="href('')">CALLE MADRID N° 741 ENTRE
RAMÓN ROJAS Y ballivian</span>
                    </div>
                  </v-col>
                </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import axios from 'axios'
var url ='backend-ccasucre/crud-persona.php';
var url_carrera ='backend-ccasucre/crud-carrera.php';
var url_rsocial ='backend-ccasucre/crud-rsociales.php';


export default {
  name: 'CcaSucreInformacion',
  props: {
  },
  components : {
  },
  data(){
    return {
      asesores : [],
      //baseUrl : "http://localhost/",
      baseUrl : "https://cca.edu.bo/",
      carreras : [],
      rsocials : [],
    };
  },
 methods: {
   href(href){
    window.open(href);
   }, 
    
    selectSede(sede){
      window.location.href=sede;
    },
    mostrarRsocial(){
      axios.post(this.baseUrl+''+url_rsocial,{opcion:5})
      .then(Response =>{
        this.rsocials=Response.data
      })
    },
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:6,cargo_id:1})
      .then(Response =>{
        this.asesores=Response.data
      })
    },
    mostrar_carrera(){
      axios.post(this.baseUrl+''+url_carrera,{opcion:1})
      .then(Response =>{
        this.carreras=Response.data;
      })
    },
    ViewCarrera(carrera1){
     this.$emit("carrera_id",carrera1.id),
     this.$emit("curso",false),
     this.$emit("quienesSomos",false),
     this.$emit("carrera",false),
     this.$emit("ViewCarrera",true),
     this.$emit("ViewCurso",false)
    }, 
    
   enviarQuien(){
     this.$emit("quienesSomos",true),
     this.$emit("carrera",false),
     this.$emit("curso",false),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
    },  
    ViewServicio(carrera1){
      if(carrera1==1){
        this.enviarCarrera(carrera1);
      }else{
        this.enviarCurso(carrera1);
      }
    },
    enviarCarrera(id){
     this.$emit("servicios_id",id),
     this.$emit("quienesSomos",false)
     this.$emit("carrera",true),
     this.$emit("curso",false),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
    },
  enviarCurso(id){
     this.$emit("servicios_id",id),
     this.$emit("quienesSomos",false),
     this.$emit("carrera",false),
     this.$emit("curso",true),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
    },  
    changeFocus (hash) {
     window.location.hash = hash;
  },  
 },
  mounted(){
    this.mostrar();
    this.mostrar_carrera();
    this.mostrarRsocial();
  }

      

}
</script>

<style scoped>
.cursor{
 cursor: pointer;
    color: inherit;
}
.cursor:hover{
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  color: #004c8c;
	transform: translateY(-7px);
}
.asesor{
 cursor: pointer;
  color: inherit;
}
.asesor span:hover{
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  color: #004c8c;
	transform: translateY(-7px);
}
.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.background-ffcf00{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
}
.text-whit{
  color: white;
}
.text-ffcf00{
  color: #ffcf00;
}
.text-004c8c{
  color: #004c8c;
}
.background-d2d4da{
   background: #d2d4da
}

.background-navbar1{
    background: linear-gradient(180deg, #6b83a5, #72849f);
}
.background-1e2127{
    background: #1e2127;
}
.background-logos{
    background: linear-gradient(180deg, #004c8c, #035aa3, #097add);
}
.border-rad{
    
    border-radius: 9999px !important;
}
.margin-est{
    margin: 0px 0px 0px 0px
  }
  .margin-icon{
    margin: 0px 5px 0px 0px;
  }
  .margin-row{
    
    margin: 50px 0px 50px 0px;
  }
  .padding-final{
    padding: 1px;;
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
 .text-size{ 
    font-size: 6vw;
  }
  .margin-row{
    
    margin: 20px 0px 20px 0px;
  }
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){  
      .margin-row{
    
    margin: 30px 0px 30px 0px;
  }
 .text-size{ 
    font-size: 2.5vw;
  } 

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
 .text-size{ 
    font-size: 1.5vw;
  }   

  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
 .text-size{ 
    font-size: 1.5vw;
  } 
 }
@media (min-width: 1905px)  { 
 .text-size{ 
    font-size: 1.5vw;
  } 

}


</style>
