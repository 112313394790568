<template>
    <div class="mision-vision">
        <v-row justify="center">
            <v-col 
                cols="12"
                sm = "12"
                md = "10"
                lg = "10"
                xl = "10">
                <v-row justify="center">
                    <v-col 
                        cols="11"
                        sm = "11"
                        md = "6"
                        lg = "6"
                        xl = "6">   
                        <div class="mision">
                            <p class="text-center title">
                                    Misión
                            </p>  
                            <div class="text-justify ">
                                Convertir los procesos de formación y aprendizaje
                                en el estímulo  permanente  para:  “Formar lideres 
                                emprendedores   de   excelencia”,    en   Ciencias
                                Empresariales  como   Sociales   y   Tecnológicas 
                                Informáticas para así lograr  el  desarrollo  de las 
                                personas y organizaciones de nuestro país.
                            </div> 
                        </div>
                    </v-col>
                    <v-col 
                        cols="11"
                        sm = "11"
                        md = "6"
                        lg = "6"
                        xl = "6">
                        <div class="vision">
                            <p class="text-center title">
                                    Visión
                            </p>  
                            <div class="text-justify ">
                              Ser una organización líder en formación profesional
                              de  recursos  humanos,  que sea reconocida a nivel
                              Nacional   e   Internacional   por   la  calidad  en  las 
                              actividades  que desarrolla gracias a una constante
                              actualización y superación.
                            </div>
                        </div>
                    </v-col>
                </v-row> 
            </v-col>
        </v-row>
      </div>
    </template>
    
    <script>      
    
    export default {
      name: 'CcaQuienesSomos1',
      props: {
      },
      components : {
      },
     methods: {
        
     },
      data(){
        return {
        };
      },
      computed : {
        
      },
      mounted(){
        }
    
          
    
    }
    </script>
