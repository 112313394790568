<template>
<div :v-model="gecurso(cursos_id)">
</div>
<div v-for="(curso, index) in cursos" v-bind:key="index" > 
  <v-carousel
      cycle
      height="max-height"
      hide-delimiter-background
      class="text-white "
      :show-arrows="false"
      :hide-delimiters="true"
    >    
      <v-carousel-item
        src="../../../assets/sucre/carrera.webp"
        class="d-flex"
      >        <p class="text-Montserrat-Bold text-size-carrera align-self-center text-center padding-quien text-ffd101 px-7" :v-model="curso_id=curso.id">{{curso.nombre}}</p>  
      </v-carousel-item>
    </v-carousel>
    <v-row justify="center" class="mt-10"> 
        <v-col 
            cols="10"
            sm = "10"
            md = "8"
            lg = "8"
            xl = "8" >
            <v-row justify="center">
              <v-col cols="12" class="borde-mapa">
                <v-row justify="center">
                  <v-col  class="d-flex" v-if="img_carrera"
                    cols="12"
                    sm = "5"
                    md = "5"
                    lg = "5"
                    xl = "5" >
                    <v-img
                      :src="baseUrl+''+baseImagen+''+curso.imagen"
                              width="100%"
                              aspect-ratio="1"
                              class="pa-0 ma-0 align-self-center"
                    >
                    </v-img>
                  </v-col>
                  <v-col  class="d-flex"
                    cols="12"
                    sm = "7"
                    md = "7"
                    lg = "7"
                    xl = "7" >
                    <editor
                      class="text-Montserrat-Regular align-self-center text-justify text-descripcion pa-4"
                      v-model="curso.descripcion"
                      cloud-channel="5"
                      :disabled=true
                      :id="curso.id"
                      width="100%"
                      :init= "{  }"
                      initial-value=""
                      :inline=true
                      model-events= ""
                      plugins=""
                      tag-name="div"
                      toolbar=""
                      value=""
                      />
                  </v-col>                  
                  <v-col  class="d-flex" v-if="!img_carrera"
                    cols="12"
                    sm = "5"
                    md = "5"
                    lg = "5"
                    xl = "5" >
                    <v-img
                      :src="baseUrl+''+baseImagen+''+curso.imagen"
                              width="100%"
                              aspect-ratio="1"
                              class="pa-0 ma-0 align-self-center"
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="background-014c8d mb-10 rounded-b-xl">
                <div >
                  <v-row>
                    <v-col 
                        cols="12"
                        sm = "3"
                        md = "3"
                        lg = "3"
                        xl = "3" >
                      <div class="d-flex justify-center">
                        <v-icon icon="mdi-account-group" class="icon-size text-ffd000"></v-icon>
                      </div>
                      <h3 class="text-center text-white text-size-title">MODALIDAD</h3>
                      <editor
                          class="text-Montserrat-Regular text-center text-ffd000 text-size-subtitle"
                          v-model="curso.modalidad"
                          cloud-channel="5"
                          :disabled=true
                          id="uuid41"
                          width="100%"
                          :init= "{  }"
                          initial-value=""
                          :inline=true
                          model-events= ""
                          plugins=""
                          tag-name="div"
                          toolbar=""
                          value=""
                          /> 
                      <!--<p class="text-center text-ffd000 text-size-subtitle">{{curso.modalidad}}</p>-->
                    </v-col>
                    <v-col 
                        cols="12"
                        sm = "6"
                        md = "6"
                        lg = "6"
                        xl = "6" >     
                      <div class="d-flex justify-center">
                        <v-icon icon="mdi-book-edit-outline" class="icon-size text-center text-ffd000"></v-icon> 
                      </div>
                      <h3 class="text-center text-white text-size-title">CERTIFICACIÓN</h3>
                      <editor
                          class="text-Montserrat-Regular text-center text-ffd000 text-size-subtitle"
                          v-model="curso.Certificacion"
                          cloud-channel="5"
                          :disabled=true
                          id="uuid42"
                          width="100%"
                          :init= "{  }"
                          initial-value=""
                          :inline=true
                          model-events= ""
                          plugins=""
                          tag-name="div"
                          toolbar=""
                          value=""
                          /> 
                      <!--<p class="text-center text-ffd000 text-size-subtitle">{{curso.Certificacion }}</p>  -->
                    </v-col>
                    <v-col 
                      cols="12"
                      sm = "3"
                      md = "3"
                      lg = "3"
                      xl = "3" >  
                      <div class="d-flex justify-center">
                        <v-icon icon="mdi-timer-sand" class="icon-size text-center text-ffd000"></v-icon> 
                      </div>                
                      <h3 class="text-center text-white text-size-title">DURACIÓN</h3>
                      <editor
                          class="text-Montserrat-Regular text-center text-ffd000 text-size-subtitle"
                          v-model="curso.Duracion"
                          cloud-channel="5"
                          :disabled=true
                          id="uuid4"
                          width="100%"
                          :init= "{  }"
                          initial-value=""
                          :inline=true
                          model-events= ""
                          plugins=""
                          tag-name="div"
                          toolbar=""
                          value=""
                          />       
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
        </v-col>
    </v-row>
    
    <v-row justify="center"> 
        <v-col cols="10">
          <v-row justify="center">
            <v-col 
                  cols="12"
                  sm = "6"
                  md = "8"
                  lg = "8"
                  xl = "8">
              <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel>
                  <v-expansion-panel-title expand-icon="mdi-plus" collapse-icon="mdi-minus" class="background-014c8d  text-white text-size-tutulo">
                    DESCRIPCIÓN
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="borde-mapa text-size-subtutulo text-justify">
                    <editor
                        class="text-Montserrat-Regular text-size-subtutulo text-justify"
                        v-model="curso.descripcion"
                        cloud-channel="5"
                        :disabled=true
                        id="uuid44"
                        width="100%"
                        :init= "{  }"
                        initial-value=""
                        :inline=true
                        model-events= ""
                        plugins=""
                        tag-name="div"
                        toolbar=""
                        value=""
                        />   
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-title expand-icon="mdi-plus" collapse-icon="mdi-minus" class="background-014c8d text-white text-size-tutulo">
                    REQUISITO DE INGRESO
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="borde-mapa text-size-subtutulo text-justify">                    
                    <editor
                        class="text-Montserrat-Regular text-size-subtutulo text-justify"
                        v-model="curso.requisito_ingreso"
                        cloud-channel="5"
                        :disabled=true
                        id="uuid45"
                        width="100%"
                        :init= "{  }"
                        initial-value=""
                        :inline=true
                        model-events= ""
                        plugins=""
                        tag-name="div"
                        toolbar=""
                        value=""
                        />  
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-title expand-icon="mdi-plus" collapse-icon="mdi-minus" class="background-014c8d  text-white text-size-tutulo">
                    ALCANCE
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="borde-mapa text-size-subtutulo text-justify">                   
                    <editor
                        class="text-Montserrat-Regular text-size-subtutulo text-justify"
                        v-model="curso.alcance"
                        cloud-channel="5"
                        :disabled=true
                        id="uuid46"
                        width="100%"
                        :init= "{  }"
                        initial-value=""
                        :inline=true
                        model-events= ""
                        plugins=""
                        tag-name="div"
                        toolbar=""
                        value=""
                        /> 
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-title expand-icon="mdi-plus" collapse-icon="mdi-minus" class="background-014c8d  text-white text-size-tutulo">
                    OBJETIVOS GENERALES
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="borde-mapa text-size-subtutulo text-justify">               
                    <editor
                        class="text-Montserrat-Regular text-size-subtutulo text-justify"
                        v-model="curso.objetivo"
                        cloud-channel="5"
                        :disabled=true
                        id="uuid47"
                        width="100%"
                        :init= "{  }"
                        initial-value=""
                        :inline=true
                        model-events= ""
                        plugins=""
                        tag-name="div"
                        toolbar=""
                        value=""
                        /> 
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col 
                  cols="12"
                  sm = "6"
                  md = "4"
                  lg = "4"
                  xl = "4" >
              <div class="borde-registrar rounded-xl pa-4">
                <h3 class="text-center text-014c8d"> REGISTRATE AQUI</h3>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                <v-text-field
                  variant="outlined"
                  v-model="nombre"
                  :rules="nameRules"
                  label="Nombres"
                  class="text-003862 pt-2"
                  required
                ></v-text-field>
                <v-text-field
                  variant="outlined"
                  v-model="apellidos"
                  :rules="nameRules"
                  label="Apellidos"
                  class="text-003862 pa-0 ma-0"
                  required
                ></v-text-field>
                <v-text-field
                  variant="outlined"
                  class="text-003862 pa-0 ma-0"
                  v-model="celular"
                  :rules="nameRules"
                  label="celular"
                  required
                ></v-text-field>
                <v-text-field
                  variant="outlined"
                  class="text-003862"
                  v-model="correo"
                  :rules="emailRules"
                  label="Correo Electronico"
                  required
                ></v-text-field>
                <v-btn class="background-0d8dff text-white  text-Arial-Bold mb-6 "  @click="crear"
                  rounded="lg" block
                  :disabled="!valid"
                  >
                    REGiSTRAR
                    <v-overlay
                      activator="parent"
                      location-strategy="connected"
                      scroll-strategy="block"
                    >
                      <v-card class="pa-2 text-0d8dff">
                        Te registrate correctamente
                      </v-card>
                    </v-overlay>
                </v-btn>
                </v-form>
                </div>
            </v-col>
          </v-row>
        </v-col>
    </v-row>
</div>
</template>

<script>

import axios from 'axios'
import Editor from '@tinymce/tinymce-vue'
var url_curso ='backend-ccasucre/crud-curso.php';
var url_interesado ='backend-ccasucre/crud-interesado-curso.php';


export default {
  name: 'CcaSucreCursoView',
  props: {
    cursos_id : Number
  },
  components : {
      'editor': Editor
  },
 methods: {
   
   gecurso(cursos_id){
    this.mostrar_carrera(cursos_id);
   },
    mostrar_carrera(cursos_id){
      axios.post(this.baseUrl+''+url_curso,{opcion:7,id:cursos_id})
      .then(Response =>{
        this.cursos=Response.data
        console.log(this.cursos);
      })
    },
    crear(){ 
      axios.post(this.baseUrl+''+url_interesado, {opcion:2,nombre: this.nombre, apellidos:this.apellidos,celular:this.celular,
                correo: this.correo, curso_id:this.curso_id })
        .then(response =>{
          response;
        });     
      this.id = "";
      this.nombre = "";
      this.apellidos = "";
      this.celular = "";
      this.correo = "";
    },
 },
  data(){
    return {
      valid: true,
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      contenido : [],
      cursos : [],
      //baseUrl : "http://localhost/",
      baseUrl : "https://cca.edu.bo/",
      baseImagen : "img-ccasucre/img-curso/",
      hide_delimiters:'hide-delimiters',
      id : "",
      nombre : "",
      apellidos : "",
      celular : "",
      correo : "",
      curso_id : "",
    };
  },
  computed : {
    
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "x-small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
    img_carrera(){
      if (screen.width > 0 && screen.width <= 600) {
        return true;
      }
      return false;
    },
  },
  mounted(){
    }

      

}
</script>

<style scoped>

.text-Poppins-Black{
  font-family: "Poppins-Black";
}
.text-Poppins-Regular{
  font-family: "Poppins-Regular";
}
.text-Poppins-ExtraBold{
  font-family: "Poppins-ExtraBold";
}
.text-LiberationSans-Bold{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.text-Montserrat-Bold{
  font-family: "Montserrat-Bold";
}

.ffcf00-background{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
} 
.background-004b8f{
   background: #004b8f;
   opacity: 0.60;
}

.opacity-100{
   opacity: 1;
}

.text-whit{
  color: white;
}
.text-ffd000{
    color: #ffd000;
}
.background-navbar{
    background: linear-gradient(90deg, #054e91, #0590ff);
}
.background-000000{
    background: #000000
}
.background-0a3e6e{
    background: #0a3e6e
}
.background-plan{
    background: linear-gradient(90deg, #0a3e6e, #0a61a8);
}
.background-plan1{
    background: linear-gradient(180deg, #0a3e6e, #0a61a8);
}
.background-0a3b68{
    background: #0a3b68
}

.text-booton-tam{
    font-size: 4.5vw
  }
  .div-ta1{
  height: 20px;
  margin: 0px;
}
.margin-plan{
    margin: 50px 0px 0px 0px;
}
.margin-1{
    margin: 11px 0px 0px 0px;
}
.padding-div{
    padding: 6px 0px 6px 0px;
}
.padding-text-title{
        padding: 5%;
    } 
.padding-text-title1{
        padding: 10% 0% 10% 0%;
    }
    .width-row{
        width: 100%;
    } 
.rotar90{
    transform: rotate(270deg)
}

.borde-mapa{
    border: solid 5px #ffd000;
  }
.borde-registrar{
    border: solid 5px #0d8dff;
  }
  .background-014c8d{
    background : #014c8d;
  }
.text-0d8dff{
  color : #0d8dff;
}
.text-014c8d{
  color : #014c8d;
}

.padding-quien{
    left: 0%;
    right: 0%;
    bottom: 0%;
    top: 40%;
    position: absolute;
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  .icon-size{
  font-size: 10vw;
}
.text-size-carrera{
  font-size: 4.5vw;
}
.text-descripcion{
  font-size: 3.5vw;
}
.text-size-title{
  font-size: 4.5vw;
}
.text-size-subtitle{
  font-size: 3.5vw;
}
.text-size-tutulo{
  font-size: 4.5vw;
}
.text-size-subtutulo{
  font-size: 3.5vw;
}
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){    
  .icon-size{
  font-size: 6vw;
}
.text-size-carrera{
  font-size: 4vw;
}
.text-descripcion{
  font-size: 2vw;
}
.text-size-title{
  font-size: 1.8vw;
}
.text-size-subtitle{
  font-size: 1.5vw;
}
.text-size-tutulo{
  font-size: 2vw;
}
.text-size-subtutulo{
  font-size: 1.5vw;
}
}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 

  .icon-size{
  font-size: 4vw;
}
.text-size-carrera{
  font-size: 3.5vw;
}
.text-descripcion{
  font-size: 1.5vw;
}
.text-size-title{
  font-size: 1.5vw;
}
.text-size-subtitle{
  font-size: 1.2vw;
}
.text-size-tutulo{
  font-size: 1.8vw;
}
.text-size-subtutulo{
  font-size: 1.5vw;
}
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  .icon-size{
  font-size: 4vw;
}
.text-size-carrera{
  font-size: 3.5vw;
}
.text-descripcion{
  font-size: 1.5vw;
}
.text-size-title{
  font-size: 1.5vw;
}
.text-size-subtitle{
  font-size: 1.2vw;
}
.text-size-tutulo{
  font-size: 1.8vw;
}
.text-size-subtutulo{
  font-size: 1.5vw;
}
 }
@media (min-width: 1905px)  { 
  .icon-size{
  font-size: 4vw;
}
.text-size-carrera{
  font-size: 3.5vw;
}
.text-descripcion{
  font-size: 1.5vw;
}
.text-size-title{
  font-size: 1.5vw;
}
.text-size-subtitle{
  font-size: 1.2vw;
}
.text-size-tutulo{
  font-size: 1.8vw;
}
.text-size-subtutulo{
  font-size: 1.5vw;
}
}


</style>
