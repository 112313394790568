<template>
<div class="background-navbar">
    <div>
      <Valores/>
    </div>
    <div>
      <Logos/>
    </div>
</div>
</template>

<script>
import Logos from '@/components/Principal/Logos/Logos.vue';
import Valores from '@/components/Principal/Valores/Valores.vue';
import axios from 'axios'
var url ='backend-ccatarija/crud-slider.php';
var url_pagina ='backend-ccatarija/crud-pagina.php';


export default {
  name: 'CcaSucreInstitutoCca',
  props: {
  },
  components : {
    Valores,
    Logos,
  },
 methods: {
   
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1})
      .then(Response =>{
        this.sliders=Response.data
      })
    },
    mostrar_pagina(){
      axios.post(this.baseUrl+''+url_pagina,{opcion:6})
      .then(Response =>{
        this.paginas=Response.data
      })
    },
 },
  data(){
    return {
        sliders : [],
        paginas : [],
        //baseUrl : "http://localhost/",
        baseUrl : "https://cca.edu.bo/",
        baseImagen : "img-ccatarija/img-carousel/",
      baseVideo : "https://www.youtube.com/embed/",
    };
  },
  computed : {
    
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "x-small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

<style scoped>

.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.ffcf00-background{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
}
.background-d2d4da{
   background: #d2d4da
}
.text-whit{
  color: white;
}
.background-navbar{
    background: linear-gradient(180deg, #0084ff, #00398c);
}
.background-instituto{
    background: linear-gradient(90deg, #004c8c, #1496d9);
}
.margin-i{
  margin: 0px 0px 50px 0px;
}
.margin-row{
  margin: 50px 0px 50px 0px;
}
.text-booton-tam{
    font-size: 4.5vw
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  .text-size-title{
    font-size: 8vw;
  }
  .text-booton-tam{
    font-size: 6vw
  }
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 3%;
    } 
    .padding-bn{    
        padding-left: 15%;
        padding-right: 1%;
        padding-bottom: 0%;
        padding-top: 2%;
    }
    .text-titulo-tam{
        font-size : 2vw;
    }
    .text-descrip-tam{
        font-size : 1.5vw;
    }
    .text-bn-tam{
        font-size : 2vw;
    }
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){ 
    .text-size-title{
    font-size: 4.5vw;
  }  
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 5%;
    } 
    .padding-bn{    
        padding-left: 15%;
        padding-right: 1%;
        padding-bottom: 0%;
        padding-top: 2%;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    } 

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
  .text-size-title{
    font-size: 5vw;
  }
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 5%;
    } 
    .padding-bn{    
        padding-left: 15%;
        padding-right: 1%;
        padding-bottom: 0%;
        padding-top: 2%;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    }
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  
  .text-size-title{
    font-size: 4vw;
  }
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 5%;
    } 
    .padding-bn{    
        padding-left: 15%;
        padding-right: 1%;
        padding-bottom: 0%;
        padding-top: 2%;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    }
 }
@media (min-width: 1905px)  { 
     .text-size-title{
    font-size: 3vw;
  } 
    .padding-text{
        padding-left: 20%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 7%;
    } 
    .padding-bn{    
        padding-left: 25%;
        padding-right: 1%;
        padding-bottom: 0%;
        padding-top: 2%;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    }

}


</style>
