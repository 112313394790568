<template>
<div :v-model="gecurso(cursos_id)"></div>
<div v-for="(curso, index) in cursos" v-bind:key="index" >
    <h1 class="text-Montserrat-BlackItalic text-black margin-curso text-tam padding-text-title ">{{curso.nombre}}</h1>
    <v-row justify="center"> 
        <v-col cols="8">
            <v-row justify="center">
                <v-col cols="12">
                    <editor
                      class="text-justify text-Montserrat-Regular"
                      v-model="curso.descripcion"
                      cloud-channel="5"
                      :disabled=true
                      id="dd1s5sdde"
                      width="100%"
                      :init= "{  }"
                      initial-value=""
                      :inline=true
                      model-events= ""
                      plugins=""
                      tag-name="div"
                      toolbar=""
                      value=""
                      />
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="2">
                            <v-sheet
                                class="d-flex align-center"
                                height="100"
                            >
                                
                                <v-img
                                    src="../../../assets/icon/modalidad.webp"
                                    width="100%"
                                >
                                </v-img>
                            </v-sheet>
                        </v-col>
                        <v-col cols="10">
                            <v-card-title class="text-Montserrat-Bold">
                                MODALIDAD
                            </v-card-title>
                            
                            <editor
                            class="text-Montserrat-Regular"
                            v-model="curso.modalidad"
                            cloud-channel="5"
                            :disabled=true
                            id="DD115"
                            width="100%"
                            :init= "{  }"
                            initial-value=""
                            :inline=true
                            model-events= ""
                            plugins=""
                            tag-name="div"
                            toolbar=""
                            value=""
                            />
                        </v-col>
                    </v-row>

                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="2">
                            <v-sheet
                                class="d-flex align-center"
                                height="100"
                            >
                                
                                <v-img
                                    src="../../../assets/icon/duracion.webp"
                                    width="100%"
                                >
                                </v-img>
                            </v-sheet>
                        </v-col>
                        <v-col cols="10">
                            <v-card-title class="text-Montserrat-Bold">  
                                DURACIÓN
                            </v-card-title>
                            
                            <editor
                            class="text-Montserrat-Regular"
                            v-model="curso.duracion"
                            cloud-channel="5"
                            :disabled=true
                            id="uuid45"
                            width="100%"
                            :init= "{  }"
                            initial-value=""
                            :inline=true
                            model-events= ""
                            plugins=""
                            tag-name="div"
                            toolbar=""
                            value=""
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="2">
                            <v-sheet
                                class="d-flex align-center"
                                height="100"
                            >
                                
                                <v-img
                                    src="../../../assets/icon/requisito.webp"
                                    width="100%"
                                >
                                </v-img>
                            </v-sheet>
                        </v-col>
                        <v-col cols="10">
                            <v-card-title class="text-Montserrat-Bold">
                                REQUISITO
                            </v-card-title>
                            
                            <editor
                            class="text-Montserrat-Regular"
                            v-model="curso.requisito"
                            cloud-channel="5"
                            :disabled=true
                            id="dd1s5"
                            width="100%"
                            :init= "{  }"
                            initial-value=""
                            :inline=true
                            model-events= ""
                            plugins=""
                            tag-name="div"
                            toolbar=""
                            value=""
                            />
                        </v-col>
                    </v-row>

                </v-col>
            </v-row>
        </v-col>
    </v-row>
</div>

<div class="margin-plan background-navbar">
  <v-row justify="center">
    <v-col cols="8">    
      <h1 class="text-Montserrat-BlackItalic text-black  text-tam text-white ">CONTENIDO</h1>
    </v-col>
    <v-col cols="8">
      <v-row>
        <template
        v-for="(cont, index) in contenido" v-bind:key="index" >
          <v-col cols="6">
            <v-row>          
              <v-col cols="12" v-if="cont.estado_descripcion">
                <v-card-title class="text-Poppins-Black text-ffd000">
                  {{cont.titulo}}
                </v-card-title>
              </v-col>
              <v-col cols="12">
                <editor
                  class="text-Poppins-Regular text-white"
                  v-model="cont.descripcion"
                  cloud-channel="5"
                  :disabled=true
                  :id="cont.id"
                  width="100%"
                  :init= "{  }"
                  initial-value=""
                  :inline=true
                  model-events= ""
                  plugins=""
                  tag-name="div"
                  toolbar=""
                  value=""
                  />
              </v-col>
            </v-row>
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
  </div>

</template>

<script>

import axios from 'axios'
import Editor from '@tinymce/tinymce-vue'
var url_contenido ='backend-ccatarija/crud-contenido.php';
var url_curso ='backend-ccatarija/crud-curso.php';


export default {
  name: 'CcaCursoView',
  props: {
    cursos_id : Number
  },
  components : {
      'editor': Editor
  },
 methods: {
   
   gecurso(cursos_id){
    this.mostrar_plan(cursos_id);
    this.mostrar_carrera(cursos_id);
   },
    mostrar_plan(cursos_id){
      axios.post(this.baseUrl+''+url_contenido,{opcion:7,cursos_id:cursos_id})
      .then(Response =>{
        this.contenido=Response.data
      })
    },
    mostrar_carrera(cursos_id){
      axios.post(this.baseUrl+''+url_curso,{opcion:7,id:cursos_id})
      .then(Response =>{
        this.cursos=Response.data
        console.log(this.cursos);
      })
    },
 },
  data(){
    return {
        contenido : [],
        cursos : [],
        //baseUrl : "http://localhost/",
        baseUrl : "https://cca.edu.bo/",
        baseImagen : "img-ccatarija/img-contenido/",
        hide_delimiters:'hide-delimiters'
    };
  },
  computed : {
    
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "x-small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  mounted(){
    this.mostrar_plan();
    this.mostrar_carrera();
    }

      

}
</script>

<style scoped>

.text-Poppins-Black{
  font-family: "Poppins-Black";
}
.text-Poppins-Regular{
  font-family: "Poppins-Regular";
}
.text-Poppins-ExtraBold{
  font-family: "Poppins-ExtraBold";
}
.text-LiberationSans-Bold{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.text-Montserrat-Bold{
  font-family: "Montserrat-Bold";
}

.ffcf00-background{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
} 
.background-004b8f{
   background: #004b8f;
   opacity: 0.60;
}

.opacity-100{
   opacity: 1;
}

.text-whit{
  color: white;
}
.text-ffd000{
    color: #ffd000;
}
.background-navbar{
    background: linear-gradient(90deg, #054e91, #0590ff);
}
.background-000000{
    background: #000000
}
.background-0a3e6e{
    background: #0a3e6e
}
.background-plan{
    background: linear-gradient(90deg, #0a3e6e, #0a61a8);
}
.background-plan1{
    background: linear-gradient(180deg, #0a3e6e, #0a61a8);
}
.background-0a3b68{
    background: #0a3b68
}

.text-booton-tam{
    font-size: 4.5vw
  }
  .div-ta1{
  height: 20px;
  margin: 0px;
}
.margin-plan{
    margin: 50px 0px 0px 0px;
}
.margin-1{
    margin: 11px 0px 0px 0px;
}
.padding-div{
    padding: 6px 0px 6px 0px;
}
.padding-text-title{
        padding: 5%;
    } 
.padding-text-title1{
        padding: 10% 0% 10% 0%;
    }
    .width-row{
        width: 100%;
    } 
.rotar90{
    transform: rotate(270deg)
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  .text-size{
    font-size: 6.5vw
  }
  .text-size-subtitle{
    font-size: 3.5vw
  }
    .padding-text{
        padding-top: 15%;
    } 
    .padding-row{    
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 1%;
    }
.padding-div{
    padding: 6px 0px 6px 0px;
}
  .text-size-title{
    font-size: 4.5vw
  }
  .text-size-subtitle1{
    font-size: 2.5vw
  }
  
.padding-subtitle{
    padding: 10px 0px 10px 0px;
}
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){    
  .text-size{
    font-size: 6.5vw
  }
  .text-size-subtitle{
    font-size: 3.5vw
  }
    .padding-text{
        padding-top: 15%;
    } 
    .padding-row{    
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 1%;
    }
.padding-div{
    padding: 27px 0px 27px 0px;
}
  .text-size-title{
    font-size: 4.5vw
  }
  .text-size-subtitle1{
    font-size: 2.5vw
  }
.padding-subtitle{
    padding: 20px 0px 20px 0px;
}

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
  .text-size{
    font-size: 6.5vw
  }
  .text-size-subtitle{
    font-size: 2.5vw
  }
    .padding-text{
        padding-top: 15%;
    } 
    .padding-row{    
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 10%;
    }
.padding-div{
    padding: 35px 27px 35px 27px;
}
  .text-size-title{
    font-size: 4.5vw
  }
  .text-size-subtitle1{
    font-size: 2vw
  }
  
.padding-subtitle{
    padding: 30px 0px 30px 0px;
}
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  .text-size{
    font-size: 6.5vw
  }
  .text-size-subtitle{
    font-size: 2.5vw
  }
    .padding-text{
        padding-top: 15%;
    } 
    .padding-row{    
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 12%;
    }
.padding-div{
    padding: 35px 27px 35px 27px;
}
  .text-size-title{
    font-size: 4.5vw
  }
  .text-size-subtitle1{
    font-size: 2vw
  }
.padding-subtitle{
    padding: 40px 0px 40px 0px;
}
 }
@media (min-width: 1905px)  { 
  .text-size{
    font-size: 6.5vw
  }
  .text-size-subtitle{
    font-size: 2.5vw
  }
    .padding-text{
        padding-top: 15%;
    } 
    .padding-row{    
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 12%;
    }
.padding-div{
    padding: 35px 27px 35px 27px;
}
  .text-size-title{
    font-size: 4.5vw
  }
  .text-size-subtitle1{
    font-size: 2vw
  }
  
.padding-subtitle{
    padding: 50px 0px 50px 0px;
}
}


</style>
