<template>
  <div :style="'height: ' + height_mapa + '; width: 100%;'">
    <l-map v-model="zoom" v-model:zoom="zoom" :center="center" @move="log('move')">
      <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
      <l-control-layers />

      <l-marker :lat-lng="center">
        <l-tooltip>
          Instituto Técnico C.C.A. Del Sur
        </l-tooltip>
        <l-icon :icon-url="iconUrl" :icon-size="iconSize" />
      </l-marker>

      <l-polyline :lat-lngs="[
        [-21.53242112892609, -64.73744501534219],
        [-21.53242112892609, -64.73744501534219],
        [-21.53242112892609, -64.73744501534219],
        [-21.53242112892609, -64.73744501534219],
      ]" color="#ffcf00"></l-polyline>
      <l-polygon :lat-lngs="[
        [-21.53242112892609, -64.73744501534219],
        [-21.53242112892609, -64.73744501534219],
        [-21.53242112892609, -64.73744501534219],
        [-21.53242112892609, -64.73744501534219],
      ]" color="#ffcf00" :fill="true" :fillOpacity="0.5" fillColor="#ffcf00" />
      <l-rectangle :lat-lngs="[
        [-21.53242112892609, -64.73744501534219],
        [-21.53242112892609, -64.73744501534219],
        [-21.53242112892609, -64.73744501534219],
        [-21.53242112892609, -64.73744501534219],
      ]" :fill="true" color="#35495d" />
      <l-rectangle :bounds="[
        [-21.53242112892609, -64.73744501534219],
        [-21.53242112892609, -64.73744501534219],
      ]">
        <l-popup>
          lol
        </l-popup>
      </l-rectangle>
    </l-map>
  </div>
</template>

<script>
//import { GoogleMap, Marker } from "vue3-google-map";
import axios from 'axios'
var url_rsocial = 'backend-ccasucre/crud-rsociales.php';
import "leaflet/dist/leaflet.css"
import icon from '@/assets/icon/marcador-de-posicion.png'
import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LTooltip,
  LPopup,
  LPolyline,
  LPolygon,
  LRectangle,
} from "@vue-leaflet/vue-leaflet";

export default {
  name: 'CcaSucreMapa',
  props: {
  },
  components: {

    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LTooltip,
    LPopup,
    LPolyline,
    LPolygon,
    LRectangle,
  },
  setup() {
    const center = { lat: -19.047768747386446, lng:  -65.25847424944979 };
    return { center };
  },

  data() {
    return {
      zoom: 18,
      iconWidth: 30,
      iconHeight: 30,
      rsocials: [],
      //baseUrl : "http://localhost/",
      baseUrl: "https://cca.edu.bo/",
      icon:''
    };
  },
  computed: {
    iconUrl() {
      return icon;
    },
    iconSize() {
      return [this.iconWidth, this.iconHeight];
    },

    height_mapa() {
    console.log(screen.width);
      if (screen.width < 600) {
        return "52vw";
      }
      return '17vw'
   
    },
  },
  methods: {
    href(href) {
      window.open(href);
    },
    mostrarRsocial() {
      axios.post(this.baseUrl + '' + url_rsocial, { opcion: 5 })
        .then(Response => {
          this.rsocials = Response.data
        })
    },
    log(a) {
      //console.log(a);
      a;
    },
    changeIcon() {
      this.iconWidth += 2;
      if (this.iconWidth > this.iconHeight) {
        this.iconWidth = Math.floor(this.iconHeight / 2);
      }
    },
  },
  mounted() {
    this.mostrarRsocial();
  }



}
</script>

