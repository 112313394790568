<template>
    <h1 class="text-center text-Montserrat-BlackItalic text-004c8c mb-10 text-tam">NUESTROS SERVICIOS</h1>
<div>
    <v-row justify="center">
      <v-col 
      cols="10">
        <v-carousel
            cycle
            :show-arrows="show_arrows"                                                                                                                                                                     
            height="max-height"
            hide-delimiters
          >
          
          <template v-slot:prev="{ props }">
              <v-btn
                variant="outlined"
                class="text-transparent"
                icon
                @click="props.onClick"
              ><v-icon class="text-whit">mdi-chevron-double-left</v-icon></v-btn>
            </template>
            <template v-slot:next="{ props }">
              <v-btn
                variant="outlined"
                class="text-transparent"
                icon
                @click="props.onClick"
              ><v-icon class="text-whit">mdi-chevron-double-right</v-icon></v-btn>
            </template>  
          <v-row justify="center">
            <v-col
              cols="12"
            >     
            
            <v-carousel-item  
              v-for="(j, i) in 
              (Math.ceil(servicios.length/columns))"
              v-bind:key="i"
              >
            
                <v-row >
                  <template
                  v-for="(moti, index) in servicios" v-bind:key="index" >
                  <v-col  
                    :cols="col_tam"
                    v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns"
                  >
                  
                  <div v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns">
                      <div
                        class="mx-auto mb-6"
                      >
                      
                      <h6 class="text-black text-LiberationSans-Bold text-center text-title-tam text-center">{{moti.titulo }}{{moti.id}}</h6>
                      <div class="img-contedor">
                          <v-img
                              :src="baseUrl+''+baseImagen+''+moti.imagen"
                              width="100%"
                              aspect-ratio="1"
                              class="my-3 img"
                              @click="ViewCarrera(moti)"
                          >
                          </v-img>
                        </div>
                      <v-row justify="center">
                        <v-col
                          cols="10"
                          class="padding-0 "
                        >
                          <div class="d-flex justify-center" >  
                            <v-btn block class="background-ffcf00 text-black text-bn-tam text-Poppins-Regular btn" @click="ViewCarrera(moti)" rounded="pill" :size="height_bootom" height="50">
                                Más Información
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                      </div>
                    </div>
                  </v-col>
                  </template>
                </v-row>
                
            </v-carousel-item>
            </v-col>
          </v-row>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
//import Editor from '@tinymce/tinymce-vue'
import axios from 'axios'
var url ='backend-ccatarija/crud-servicio.php';
var url_info ='backend-ccatarija/crud-informacion.php';


export default {
  name: 'CcaServicios',
  props: {
  },
  components : {
  },
 methods: {
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1})
      .then(Response =>{
        this.servicios=Response.data
      })
    }, 
    changeFocus (hash) {
     window.location.hash = hash;
    },
    formNuevo (carrera){
      this.dialog = false;
      this.dialog_info = true;
      this.operacion='crear';
      this.informacion.nombre  = "";
      this.informacion.apellido = "";
      this.informacion.celular  = "";
      this.informacion.correo  = "";
      this.informacion.curso = carrera.nombre;
    },
    
    crear (){        
      axios.post(this.baseUrl+''+url_info, {opcion:2, nombre:this.informacion.nombre, apellido :this.informacion.apellido , celular  :this.informacion.celular  , 
                        correo :this.informacion.correo , curso:this.informacion.curso })
        .then(response =>{
          response;
        });  
      this.dialog_info= false;

    },
    

    ViewCarrera(carrera1){
      if(carrera1.id==1){
        this.enviarCarrera(carrera1.id);
        this.changeFocus('carrera');
      }else{
        this.enviarCurso(carrera1.id);
        this.changeFocus('curso');
      }
    },
    enviarCarrera(id){
     this.$emit("servicios_id",id),
     this.$emit("quienesSomos",false)
     this.$emit("carrera",true),
     this.$emit("curso",false),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
    },
  enviarCurso(id){
     this.$emit("servicios_id",id),
     this.$emit("quienesSomos",false),
     this.$emit("carrera",false),
     this.$emit("curso",true),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
    },
   
 },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 3;
      }

      if (screen.width > 960) {
        return 3;
      }

      return 1;
    },
        
    col_tam(){
      
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 4;
      }

      if (screen.width > 960) {
        return 4;
      }

      return 1;
    },
    show_arrows(){
      
      if (screen.width > 961 ) {
        return false;
      }
      return true;
    },
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  data(){
    return {
      x1 : true,
        //baseUrl : "http://localhost/",
      baseUrl : "https://cca.edu.bo/",
       baseImagen : "img-ccatarija/img-servicio/",
      servicios : [],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      dialog : false,
      dialog_info : false,
      carrera : {
        id: null,
        nombre : "",
        areas : "",
        descripcion  : "",
        campo_laboral  : "",
        duracion_semestre : "",
        duracion_anio  : "",
        horario  : "",
        modalidad   : "",
        requisito  : "",
        imagen : "",
        estado : ""
      },
      informacion : {
        id: null,
        nombre : "",
        apellido  : "",
        celular  : "",
        correo   : "",
        curso : "",
      },
    };
  },
  mounted(){
      this.mostrar();
    }

      

}
</script>

<style scoped>
.padding-0{
    padding-top: 0px;
}

.text-Poppins-Regular{
  font-family: "Poppins-Regular";
}
.text-LiberationSans-Bold{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.text-whit{
  color: white;
}
.text-ffcf00{
  color : #ffcf00
}
.background-ffcf00{
  background : #ffcf00
}
.text-004c8c{
  color: #004c8c;
}

.background-0d8dff{
   background: #0d8dff
}
.background-ffbe0d{
   background: #ffbe0d
}
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.border-rad{
    
    border-radius: 9999px !important;
}

.margin-carrera{
    margin: 20px 0px 10px 0px
  }
  .img{
 cursor: pointer;
  color: inherit;
}

.img-contedor:hover .img {
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
  }
.img-contedor {
  overflow:hidden;
  }
.btn:hover {
 transform: translateY(-7px)
 }

/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  .dialog_tan{
   width : 300px
 }
 
  .text-tam{ 
    font-size: 6vw;
  }
  
.size-text-area{ 
    font-size: 5.2vw;
  }  
.text-bn-tam{
    font-size: 5vw;
} 
.text-title-tam{
    font-size: 5.2vw;
}  
.padding-text-area{
    padding-top: 6%;
} 
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
.dialog_tan{
   width : 601px
 }
 
  .text-tam{ 
    font-size: 4vw;
  }
  
.text-bn-tam{
    font-size: 1.3vw;
} 
.text-title-tam{
    font-size: 1.2vw;
}  
.size-text-area{ 
    font-size: 2vw;
  } 
.padding-text-area{
    padding-top: 5%;
} 
}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
   .dialog_tan{
   width : 930px  
   }
    
  .text-tam{ 
    font-size: 3vw;
  }
  .text-bn-tam{
    font-size: 1.3vw;
} 
.text-title-tam{
    font-size: 1.4vw;
} 
.size-text-area{ 
    font-size: 2.2vw;
  } 

.padding-text-area{
    padding-top: 5%;
} 
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  .dialog_tan{
   width : 1100px
 }
  
  .text-tam{ 
    font-size: 3vw;
  }
  
  .text-bn-tam{
    font-size: 1.3vw;
} 
.text-title-tam{
    font-size: 1.4vw;
}
.size-text-area{ 
    font-size: 2.2vw;
  } 

.padding-text-area{
    padding-top: 4%;
} 
    
 }
@media (min-width: 1905px)  { 
  .dialog_tan{
   width : 1700px
 }
  
  .text-tam{ 
    font-size: 3vw;
  }
    .text-bn-tam{
    font-size: 1.3vw;
} 
.text-title-tam{
    font-size: 1.4vw;
}
.size-text-area{ 
    font-size: 2vw;
  } 

.padding-text-area{
    padding-top: 4%;
} 
}


</style>
