<template>
  <div class="carrera-view">
    <BannerVue v-if="carrera" :title=carrera.nombre :img="carrera?.img.original_url" />
    <div class="characteristics d-flex justify-center">
      <v-card class="card"><span class="title">Duración</span><span class="content">{{ carrera?.Duracion
      }}</span></v-card>
      <v-card class="card"><span class="title">Certificación</span> <span class="content"
          v-html="carrera?.certificacion"></span></v-card>
      <v-card class="card"><span class="title">Modalidad</span> <span class="content"
          v-html="carrera?.modalidad"></span></v-card>
    </div>

    <FormVue  :img="carrera?.img.original_url" :data="carrera" />
    <v-row class="container-description">
      <v-col cols="12" md="9">
        <v-row class="descripcion" v-if="carrera?.descripcion">
          <h2 class="text-center title">¿Para quién es {{ carrera?.nombre }} ?</h2>
          <p v-html="carrera?.descripcion" class="mt-4 mb-4"></p>
        </v-row>
        <v-row class="descripcion" v-if="carrera?.alcance">
          <h2 class="text-center title">Alcance</h2>
          <span v-html="carrera?.alcance"></span>
        </v-row>
        <v-row class="descripcion" v-if="carrera?.requisito_ingreso">
          <h2 class="text-center title">Requisitos</h2>
          <span v-html="carrera?.requisito_ingreso"></span>
        </v-row>
        <v-row class="descripcion" v-if="carrera?.objetivo">
          <h2 class="text-center title">Objetivo</h2>
          <span v-html="carrera?.objetivo"></span>
        </v-row>
        <v-row class="descripcion" v-if="carrera?.temario">
          <h2 class="text-center title">Temario</h2>
          <span v-html="carrera?.temario"></span>
        </v-row>
        <v-row class="descripcion">
          <h2 v-if="carrera?.servicio.id!=4"  class="text-center title">Certificado</h2>
          <v-row>
            <v-col cols="12" md="7">
            <div class="d-block" v-if="carrera?.servicio.id!=4" >
            
              Al finalizar el programa, los participantes recibirán un certificado oficial, respaldado por el Ministerio de Educación, el grupo corporativo ESAM o el Instituto Técnico Superior CCA, según corresponda. <br> Este documento certifica su participación y los logros alcanzados durante el curso, ofreciendo una validación oficial de las habilidades y conocimientos adquiridos. La colaboración con estas entidades garantiza el reconocimiento y la calidad del programa de formación.            
            </div>
            <a v-if="carrera && carrera.pdf1" :href="carrera.pdf1.original_url" target="_blank" class="text-center">
                <v-btn class="mt-4 download">
                  Descargar Folleto
                </v-btn>
              </a>
          </v-col>
          <v-col cols="12" md="5" v-if="carrera?.servicio.id!=4" >
            <img width="500" :src="certificado" alt="CERTIFICADO">
          </v-col>
          </v-row>

        </v-row>
      </v-col>
      <v-col cols="12" md="3">
        <MoreCursos v-if="carrera?.servicio" :servicio="carrera?.servicio.id" :cursoId="carrera?.id"></MoreCursos>
      </v-col>
    </v-row>
    <Personal />

  </div>
</template>

<script>
import BannerVue from '@/components/CcaSucre/Navbar/Banner.vue';
import FormVue from '@/components/CcaSucre/Carrera/Form.vue';
import Personal from '@/components/CcaSucre/Personal/Personal.vue';
import certificado from '@/assets/certificado.jpg'
import usePeticion from '@/assets/helpers/Peticion';
export default {
  name: 'CcaSucreCarreraView',

  components: {
    BannerVue,
    FormVue,
    Personal
  },


  data() {
    return {
      carrera: null,
      carrera_id: null,
      name: '',
      phoneNumber: '',
      email: '',
      select: null,
      items: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4',
      ],
      checkbox: null,
      certificado

    };
  },


  async created() {
    const id_carrera = this.$route.params.id;
    const { fetchData } = usePeticion('carrera/' + id_carrera);

    try {
      this.carrera = await fetchData();
    } catch (error) {
      console.error('Error:', error);
    }
  },


}
</script>

