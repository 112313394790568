<template>
  <CcaSucreCarousel />
  <InstitutoCca />
  <div id="Servicios">
    <Servicios />
  </div>
  <Valores />
</template>
<script>
import CcaSucreCarousel from '@/components/CcaSucre/Carousel/Carousel.vue';
import Servicios from '@/components/CcaSucre/Servicios/Servicios.vue';

import InstitutoCca from '@/components/CcaSucre/InstitutoCca/InstitutoCca.vue';
import Valores from '@/components/CcaSucre/Valores/Valores.vue';
export default {
  name: 'CcaSucreMain',
  props: {
  },
  components: {
    CcaSucreCarousel,
    Valores,
    Servicios,
    InstitutoCca
  },
  data() {
    return {
      quienesSomos: false,
      curso: false,
      carrera: false,
      id_curso: 0,
      id_carrera: 0,
      servicios_id: 0,
      ViewCarrera: false,
      ViewCurso: false,
      cursos_id: 0
    };
  },
 }
</script>

