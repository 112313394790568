<template>
  <div class="services-sucre">

    <h2 class="mb-6 text-center "> Conoce Nuestros Servicios</h2>
    <p class="text-center">Queremos brindarte la mejor experiencia en educación</p>

    <v-carousel cycle :show-arrows="show_arrows" height="max-height" hide-delimiters class="carousel">

      <template v-slot:prev="{ props }">
        <v-btn variant="outlined" class="text-transparent" icon @click="props.onClick"><v-icon
            class="text-6911b1 text-size-icon">mdi-chevron-double-left</v-icon></v-btn>
      </template>
      <template v-slot:next="{ props }">
        <v-btn variant="outlined" class="text-transparent" icon @click="props.onClick"><v-icon
            class="text-6911b1 text-size-icon">mdi-chevron-double-right</v-icon></v-btn>
      </template>
      <v-row justify="center">
      
        <v-col>

          <v-carousel-item v-for="(j, i) in 
              (Math.ceil(servicios.length / columns))" v-bind:key="i">

            <v-row justify="center">
              <template v-for="(moti, index) in servicios" v-bind:key="index">
                <v-col :cols="col_tam" v-if="index < ((i + 1) * columns) && index >= ((i + 1) * columns) - columns">

                  <div v-if="index < ((i + 1) * columns) && index >= ((i + 1) * columns) - columns">
                    <div class=" mb-6 card-service">
                      <div class="img-contedor" @click="ViewCarrera(moti)">
                        <v-img :src="moti.img.original_url" width="100%" class="img">
                        </v-img>

                      </div>
                      <div class="service-title">
                        <p > <v-icon size="small" color="blue" icon="mdi-domain"></v-icon><span class="ml-3 mt-2">{{ moti.cantida_carrera+ moti.cantidad_curso}}
                          {{ moti.cantida_carrera==0?'Cursos':'Carreras'}}</span></p>
                        <h3 class="mt-2" @click="ViewCarrera(moti)"> {{ moti.titulo }} </h3>
                      </div>
                    </div>
                  </div>
                </v-col>
              </template>
            </v-row>
          </v-carousel-item>
        </v-col>
      </v-row>
    </v-carousel>
  </div>
</template>

<script>
import usePeticion from '@/assets/helpers/Peticion';

export default {
  name: 'CcaSucreServicios',
  props: {
  },
  components: {
  },
methods:{
  ViewCarrera(carrera1) {
      if (carrera1.id == 1) {
        this.changePath('CcaSucreCarrera')
      } else {
        this.changePath('CcaSucreCurso', carrera1.id, carrera1.titulo)
      }
    },
    changePath(namePath, id_servicio, servicio) {
      window.scrollTo(0, 0);
      this.$router.push({ name: namePath, params: { id: id_servicio, servicio: servicio } });
    },
},
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 2;
      }
      if (screen.width > 960 && screen.width <= 1264) {
        return 4;
      }

      if (screen.width > 1264) {
        return 4;
      }

      return 1;
    },

    col_tam() {

      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 6;
      }
      if (screen.width > 960 && screen.width <= 1264) {
        return 3;
      }

      if (screen.width > 1264) {
        return 3;
      }

      return 1;
    },
    show_arrows() {

      if (screen.width > 960) {
        return false;
      }
      return true;
    },
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  data() {
    return {

      servicios: [],
    };
  },
  async created() {
    const { fetchData } = usePeticion('servicios');
    try {
      this.servicios = await fetchData();
    } catch (error) {
      console.error('Error:', error);
    }
  },
 
}
</script>
