<template>
  <v-toolbar class="header-sucre" v-if="!isMobile" :style="scrolled?'margin-top: 0px':'margin-top: 10px'">
    <v-img src="../../../assets/Logos/CCA2.webp" width="45"></v-img>
    <v-spacer></v-spacer>
    <v-btn variant="text" @click="changePath('HomeView')">Inicio</v-btn>
    <v-btn variant="text" @click="changePath('CcaQuienesSomos')">¿Quiénes somos?</v-btn>
    <v-menu location="bottom">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props">
          Programas
        </v-btn>
      </template>
      <v-list>


        <v-list-item>
          <v-list-item-title class="d-block" @click="changePath('CcaSucreCarrera')">CARRERA</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="d-block"
            @click="changePath('CcaSucreCurso', 2, 'Capacitación')">CAPACITACION</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="d-block" @click="changePath('CcaSucreCurso', 3, 'Formación Continua')">FORMACION
            CONTINUA</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="d-block" @click="changePath('CcaSucreCurso', 4, 'Preuniversitarios')">PRE
            UNIVERSITARIOS</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar>
  <!-- Cajón de navegación responsivo -->
  <v-toolbar class="header-sucre" v-if="isMobile">
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="isMobile"></v-app-bar-nav-icon>
    <v-img src="../../../assets/Logos/CCA2.webp" width="50"></v-img>
  </v-toolbar>
  <v-navigation-drawer app v-model="drawer" v-if="isMobile">
    <v-list>
      <v-list-item @click="changePath('HomeView')">Inicio</v-list-item>
      <v-list-item @click="changePath('CcaQuienesSomos')">¿Quiénes somos?</v-list-item>
      <v-list-item>
        <v-list-item-title class="d-block" @click="changePath('CcaSucreCarrera')">CARRERA</v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title class="d-block"
          @click="changePath('CcaSucreCurso', 2, 'Capacitación')">CAPACITACION</v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title class="d-block" @click="changePath('CcaSucreCurso', 3, 'Formación Continua')">FORMACION
          CONTINUA</v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title class="d-block" @click="changePath('CcaSucreCurso', 4, 'Preuniversitarios')">PRE
          UNIVERSITARIOS</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <div class="redes-sociales">
    <template v-for="(rsocial, index) in rsocials" v-bind:key="index">

      <v-btn class="icon-btn" v-on:click="href(rsocial.enlace)"> <v-icon :icon="'mdi-' + rsocial.logo"></v-icon></v-btn>


    </template>
  </div>
</template>
<script>
import usePeticion from '@/assets/helpers/Peticion';

export default {

  name: 'CcaSucreNabar',
  data() {
    return {
      scrolled: false,
      drawer: false,
      isMobile: false,
      rsocials: null
    };
  },
  methods: {
    changePath(namePath, id_servicio, servicio) {
      window.scrollTo(0, 0);
      this.$router.push({ name: namePath, params: { id: id_servicio, servicio: servicio } });
    },

    redirectToUrl(url) {
      window.open(url, '_blank');
    },

    handleScroll() {
      this.scrolled = window.scrollY > 250; // Cambiar 0 por el valor de desplazamiento deseado
    },
    href(href) {
      window.open(href);
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    if (window.innerWidth <= 600) {
      this.isMobile = true;
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async created() {
    const { fetchData } = usePeticion('redessociales');
    try {
      this.rsocials = await fetchData();
    } catch (error) {
      console.error('Error:', error);
    }
  },

}
</script>
