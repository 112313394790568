<template>
  <div class="quienes">
    <BannerVue title="¿Quiénes Somos?"/>
    <MisionVision/>
  <div class="mt-4 mb-4">
    <v-row justify="center">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-row justify="center">
          <v-col cols="11" sm="11" md="9" lg="9" xl="9">
              Somos una organización con un enfoque innovador con más de 27 años de trabajo
              y superación constante, con servicios educativos, así como también en consultaría
              empresarial, líderes enprocesos de formación de recursos en las áreas de ciencias
              empresariales y tecnológicas informáticas, con más de 29,000 personas
              que han participado en nuestras carreras y cursos.
           
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
  <Personal />

  </div>
</template>

<script>
import Personal from '@/components/CcaSucre/Personal/Personal.vue';
import BannerVue from '@/components/CcaSucre/Navbar/Banner.vue';
import MisionVision from "@/components/CcaSucre/QuienesSomos/MisionVision.vue"
import axios from 'axios'
var url = 'backend-ccatarija/crud-slider.php';
var url_pagina = 'backend-ccatarija/crud-pagina.php';


export default {
  name: 'CcaQuienesSomos',
  props: {
  },
  components: {
    MisionVision,
    BannerVue,
    Personal
  },
  methods: {

    mostrar() {
      axios.post(this.baseUrl + '' + url, { opcion: 1 })
        .then(Response => {
          this.sliders = Response.data
        })
    },
    mostrar_pagina() {
      axios.post(this.baseUrl + '' + url_pagina, { opcion: 6 })
        .then(Response => {
          this.paginas = Response.data
        })
    },

  },
  data() {
    return {
      sliders: [],
      paginas: [],
      //baseUrl : "http://localhost/",
      baseUrl: "https://cca.edu.bo/",
      baseImagen: "img-ccatarija/img-carousel/",
      hide_delimiters: 'hide-delimiters'
    };
  },
  computed: {

    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "x-small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  mounted() {
    this.mostrar();
  }



}
</script>

