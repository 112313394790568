<template>

     <v-card>
      <v-layout>
        <v-app-bar
          class="background-0d8dff text-white"
          prominent
        >
        <v-avatar class="margin-img">
          <v-img src="../../../assets/Logos/CCA.webp"></v-img>
        </v-avatar>
          <v-spacer></v-spacer>
          <v-btn variant="text" icon="mdi-home"></v-btn>
        </v-app-bar>


        <v-main>
        </v-main>
      </v-layout>
    </v-card>
    
 <v-card>
    <v-layout>
      <v-navigation-drawer
        permanent
        location="left"
      >
        <template v-slot:prepend>
          <v-list-item
            two-line
            prepend-avatar="https://randomuser.me/api/portraits/women/81.jpg"
            title="Jane Smith"
            subtitle="Logged in"
          ></v-list-item>
        </template>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-group value="Academico">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                prepend-icon="mdi-school"
                title="Academico"
              ></v-list-item>
            </template>
            <v-list-item prepend-icon="mdi-book-cog" title="Servicios" value="Servicios" v-on:click="servicioF()"></v-list-item>
            <v-list-item prepend-icon="mdi-book-cog" title="Carreras" value="Carreras" v-on:click="carreraF()"></v-list-item>
            <v-list-item prepend-icon="mdi-book-cog" title="Cursos" value="Cursos" v-on:click="cursoF()"></v-list-item>
          </v-list-group>
          <v-list-item prepend-icon="mdi-account-cog" title="Personal" value="Personal"  v-on:click="personalF()"></v-list-item>
          <v-list-group value="Video">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                prepend-icon="mdi-youtube"
                title="Video"
              ></v-list-item>
            </template>
            <v-list-item prepend-icon="mdi-movie-cog-outline" title="Testimonios" value="Testimonios" v-on:click="testimonioF()"></v-list-item>
            <v-list-item prepend-icon="mdi-movie-cog-outline" title="Noticias" value="Noticias" v-on:click="noticiaF()"></v-list-item>
          </v-list-group>
          <v-list-group value="General">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                prepend-icon="mdi-cog-outline"
                title="General"
              ></v-list-item>
            </template>
            <v-list-item prepend-icon="mdi-folder-cog-outline" title="Carusel" value="Carusel" v-on:click="carouselF()"></v-list-item>
            <v-list-item prepend-icon="mdi-folder-cog-outline" title="Estadisticas" value="Estadisticas" v-on:click="estadisticaF()"></v-list-item>
            <v-list-item prepend-icon="mdi-folder-cog-outline" title="Redes sociales" value="Redes sociales" v-on:click="rsocialF()"></v-list-item>
          </v-list-group>
          
        </v-list>
      </v-navigation-drawer>
      <v-main style="height:auto">
          <div class="hello" v-if="true">               
            <div v-if="personal" class="margin-config">
              <Personal/>
            </div>
            <div v-if="servicio" class="margin-config">
              <Servicios/>
            </div>
            <div v-if="curso" class="margin-config">
              <Curso/>
            </div>
            <div v-if="carrera" class="margin-config">
              <Carrera/>
            </div>
            <div v-if="testimonio" class="margin-config">
              <Testimonios/>
            </div>
            <div v-if="noticia" class="margin-config">
              <Noticia/>
            </div>
            <div v-if="estadistica" class="margin-config">
              <Estadistica/>
            </div>
            <div v-if="rsocial" class="margin-config">
              <Rsocial/>
            </div>
        </div>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>


//import CarouselConfig from '@/components/CcaTarija/Carousel/CarouselConfig.vue'
import Curso from '@/components/CcaSucre/Curso/CursoConfig.vue'
import Personal from '@/components/CcaSucre/Personal/PersonalConfig.vue'
import Servicios from '@/components/CcaSucre/Servicios/ServiciosConfig.vue' 
import Carrera from '@/components/CcaSucre/Carrera/CarreraConfig.vue' 
import Testimonios from '@/components/CcaSucre/Testimonios/TestimoniosConfig.vue' 
import Noticia from '@/components/CcaSucre/Videos/VideosConfig.vue' 
import Estadistica from '@/components/CcaSucre/Estadistica/EstadisticaConfig.vue' 
import Rsocial from '@/components/CcaSucre/RedesSociales/RedesSocialesConfig.vue' 
export default {
  name: 'CcaSucreConfiguracion',
  props: {
  },
  components : {
    Curso,
    Carrera,
    Personal,
    Servicios,
    Testimonios,
    Noticia,
    Estadistica,
    Rsocial,
  },
 methods: {
   servicioF(){
    this.servicio = true;
    this.carrera = false;
    this.curso  = false;
    this.testimonio = false;
    this.noticia = false;
    this.estadistica  = false;
    this.rsocial = false;
    this.personal = false;
  },
   carreraF(){
    this.servicio = false;
    this.carrera = true;
    this.curso  = false;
    this.testimonio = false;
    this.noticia = false;
    this.estadistica  = false;
    this.rsocial = false;
    this.personal = false;
  },
   cursoF(){
    this.servicio = false;
    this.carrera = false;
    this.curso  = true;
    this.testimonio = false;
    this.noticia = false;
    this.estadistica  = false;
    this.rsocial = false;
    this.personal = false;
  },
   testimonioF(){
    this.servicio = false;
    this.carrera = false;
    this.curso  = false;
    this.testimonio = true;
    this.noticia = false;
    this.estadistica  = false;
    this.rsocial = false;
    this.personal = false;
  },
   noticiaF(){
    this.servicio = false;
    this.carrera = false;
    this.curso  = false;
    this.testimonio = false;
    this.noticia = true;
    this.estadistica  = false;
    this.rsocial = false;
    this.personal = false;
  },
   estadisticaF(){
    this.servicio = false;
    this.carrera = false;
    this.curso  = false;
    this.testimonio = false;
    this.noticia = false;
    this.estadistica  = true;
    this.rsocial = false;
    this.personal = false;
  },
   rsocialF(){
    this.servicio = false;
    this.carrera = false;
    this.curso  = false;
    this.testimonio = false;
    this.noticia = false;
    this.estadistica  = false;
    this.rsocial = true;
    this.personal = false;
  },
   personalF(){
    this.servicio = false;
    this.carrera = false;
    this.curso  = false;
    this.testimonio = false;
    this.noticia = false;
    this.estadistica  = false;
    this.rsocial = false;
    this.personal = true;
  }
 },
  data(){
    return {
      
   servicio : true,
   carrera : true,
   curso  : false,
   testimonio : false,
   noticia : false,
   estadistica  : false,
   rsocial : false,
   personal: false,
    };
  },
  mounted(){
    }

      

}
</script>

<style scoped>

.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.background-ffcf00{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
}
.text-whit{
  color: white;
}
.text-ffcf00{
  color: #ffcf00;
}

.div-ta0{
  height: 10px;
  margin: 0px;
}
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.border-rad{
    
    border-radius: 9999px !important;
}

.margin-config{
  margin : 50px 0px 0px 0px
}
.margin-est{
    margin: 20px 0px 0px 0px
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
    
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
    
    
 }
@media (min-width: 1905px)  { 

}


</style>
