<template>
<div class="margin-1">
  <v-carousel
    cycle
    height="max-height"
    hide-delimiter-background
    class="text-white "
    :show-arrows="false"
    :hide-delimiters="true"
  >    
    <template v-slot:prev="{ props }">
        <v-btn
            variant="outlined"
            class="text-transparent"
            icon
            @click="props.onClick"
        ><v-icon class="text-whit text-booton-tam">mdi-chevron-left</v-icon></v-btn>
        </template>
        <template v-slot:next="{ props }">
        <v-btn
            variant="outlined"
            class="text-transparent"
            icon
            @click="props.onClick"
        ><v-icon class="text-whit text-booton-tam">mdi-chevron-right</v-icon></v-btn>
        </template> 
        

    
    <v-carousel-item
      src="../../../assets/quien.webp"
    >
            <v-row>
                <v-col cols="6">
                        <p class="text-Montserrat-Bold text-size padding-text text-black text-center">¿Quiénes Somos?</p>    
                </v-col>
            </v-row>
    </v-carousel-item>
  </v-carousel>
  </div>
  <div class="background-navbar margin-1">
    <v-row justify="center">
        <v-col
            cols="12"
            sm = "12"
            md = "12"
            lg = "12"
            xl = "12">
            <v-row  justify="center">
                <v-col 
                    cols="11"
                    sm = "11"
                    md = "9"
                    lg = "9"
                    xl = "9">

                    <v-card-subtitle class="text-Montserrat-Regular text-justify text-size-subtitle text-white padding-subtitle">
                        Somos una organización con un enfoque innovador en servicios educativos y consultoría empresarial, líderes en procesos de formación de recursos
                        humanos en las áreas de Ciencias Empresariales y Tecnologías Informáticas.
                    </v-card-subtitle>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
  </div>

  <div class="margin-1">
  <v-carousel
    cycle
    height="max-height"
    hide-delimiter-background
    class="text-white "
    :show-arrows="false"
    :hide-delimiters="true"
  >    
    <template v-slot:prev="{ props }">
        <v-btn
            variant="outlined"
            class="text-transparent"
            icon
            @click="props.onClick"
        ><v-icon class="text-whit text-booton-tam">mdi-chevron-left</v-icon></v-btn>
        </template>
        <template v-slot:next="{ props }">
        <v-btn
            variant="outlined"
            class="text-transparent"
            icon
            @click="props.onClick"
        ><v-icon class="text-whit text-booton-tam">mdi-chevron-right</v-icon></v-btn>
        </template> 
        

    
    <v-carousel-item
      src="../../../assets/vision.webp"
    >
        <div class="padding-row">
            <v-row justify="center">
                <v-col 
                    cols="12"
                    sm = "12"
                    md = "9"
                    lg = "9"
                    xl = "9">
                    <v-row justify="center">
                        <v-col 
                            cols="11"
                            sm = "11"
                            md = "6"
                            lg = "6"
                            xl = "6">   
                            <div class="background-004b8f rounded-xl padding-div">
                                <p class="text-Montserrat-Bold  text-white text-center text-size-title opacity-100">
                                        Misión
                                </p>  
                                <v-card-subtitle class="text-justify text-Montserrat-Regular text-size-subtitle1 text-white opacity-100">
                                    Convertir los procesos de
                                    formación y aprendizaje en el estímulo permanente para “Formar líderes
                                    emprendedores de excelencia”
                                    en Ciencias Empresariales como
                                    Sociales y Tecnológicas Infromáticas para así lograr el desarrollo de las
                                    persona y organiza
                                </v-card-subtitle> 
                            </div>
                        </v-col>
                        <v-col 
                            cols="11"
                            sm = "11"
                            md = "6"
                            lg = "6"
                            xl = "6">
                            <div class="background-004b8f rounded-xl padding-div ">
                                <p class="text-Montserrat-Bold  text-white text-center text-size-title opacity-100">
                                        Visión
                                </p>  
                                <v-card-subtitle class="text-justify text-Montserrat-Regular text-size-subtitle1 text-white opacity-100">
                                    Somos una organización con un
                                    enfoque innovador en servicios
                                    educativos y consultoría empresarial, lideres en procesos de formación de
                                    recursos humanos en las áreas de Ciencias Empresariales y Técnologías Informáticas.
                                </v-card-subtitle>
                            </div>
                        </v-col>
                    </v-row> 
                </v-col>
            </v-row>
        </div>
    </v-carousel-item>
  </v-carousel>
  </div>
<div class=" div-ta1 background-000000" >
</div> 
</template>

<script>

import axios from 'axios'
var url ='backend-ccatarija/crud-slider.php';
var url_pagina ='backend-ccatarija/crud-pagina.php';


export default {
  name: 'CcaQuienesSomos',
  props: {
  },
  components : {
  },
 methods: {
   
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1})
      .then(Response =>{
        this.sliders=Response.data
      })
    },
    mostrar_pagina(){
      axios.post(this.baseUrl+''+url_pagina,{opcion:6})
      .then(Response =>{
        this.paginas=Response.data
      })
    },
    
 },
  data(){
    return {
        sliders : [],
        paginas : [],
        //baseUrl : "http://localhost/",
        baseUrl : "https://cca.edu.bo/",
        baseImagen : "img-ccatarija/img-carousel/",
        hide_delimiters:'hide-delimiters'
    };
  },
  computed : {
    
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "x-small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

<style scoped>

.text-LiberationSans-Bold{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.text-Montserrat-Bold{
  font-family: "Montserrat-Bold";
}

.ffcf00-background{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
} 
.background-004b8f{
   background: #004b8f;
   opacity: 0.60;
}

.opacity-100{
   opacity: 1;
}

.text-whit{
  color: white;
}
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.background-000000{
    background: #000000
}
.text-booton-tam{
    font-size: 4.5vw
  }
  .div-ta1{
  height: 20px;
  margin: 0px;
}
.margin-1{
    margin: 11px 0px 0px 0px;
}
.padding-div{
    padding: 6px 0px 6px 0px;
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  .text-size{
    font-size: 6.5vw
  }
  .text-size-subtitle{
    font-size: 3.5vw
  }
    .padding-text{
        padding-top: 15%;
    } 
    .padding-row{    
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 1%;
    }
.padding-div{
    padding: 6px 0px 6px 0px;
}
  .text-size-title{
    font-size: 4.5vw
  }
  .text-size-subtitle1{
    font-size: 2.5vw
  }
  
.padding-subtitle{
    padding: 10px 0px 10px 0px;
}
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){    
  .text-size{
    font-size: 6.5vw
  }
  .text-size-subtitle{
    font-size: 3.5vw
  }
    .padding-text{
        padding-top: 15%;
    } 
    .padding-row{    
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 1%;
    }
.padding-div{
    padding: 27px 0px 27px 0px;
}
  .text-size-title{
    font-size: 4.5vw
  }
  .text-size-subtitle1{
    font-size: 2.5vw
  }
.padding-subtitle{
    padding: 20px 0px 20px 0px;
}

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
  .text-size{
    font-size: 6.5vw
  }
  .text-size-subtitle{
    font-size: 2.5vw
  }
    .padding-text{
        padding-top: 15%;
    } 
    .padding-row{    
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 10%;
    }
.padding-div{
    padding: 35px 27px 35px 27px;
}
  .text-size-title{
    font-size: 4.5vw
  }
  .text-size-subtitle1{
    font-size: 2vw
  }
  
.padding-subtitle{
    padding: 30px 0px 30px 0px;
}
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  .text-size{
    font-size: 6.5vw
  }
  .text-size-subtitle{
    font-size: 2.5vw
  }
    .padding-text{
        padding-top: 15%;
    } 
    .padding-row{    
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 12%;
    }
.padding-div{
    padding: 35px 27px 35px 27px;
}
  .text-size-title{
    font-size: 4.5vw
  }
  .text-size-subtitle1{
    font-size: 2vw
  }
.padding-subtitle{
    padding: 40px 0px 40px 0px;
}
 }
@media (min-width: 1905px)  { 
  .text-size{
    font-size: 6.5vw
  }
  .text-size-subtitle{
    font-size: 2.5vw
  }
    .padding-text{
        padding-top: 15%;
    } 
    .padding-row{    
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 12%;
    }
.padding-div{
    padding: 35px 27px 35px 27px;
}
  .text-size-title{
    font-size: 4.5vw
  }
  .text-size-subtitle1{
    font-size: 2vw
  }
  
.padding-subtitle{
    padding: 50px 0px 50px 0px;
}
}


</style>
