<template>
<h1 class="text-Montserrat-Black text-black margin-carrera text-tam padding-text-title ">ÁREA DE TECNOLOGÍA</h1>
  <div>
    <v-row justify="center">
      <v-col 
        cols="12"
        sm = "12"
        md = "10"
        lg = "10"
        xl = "10">
        <v-carousel 
            cycle
            :show-arrows="show_arrows"
            height="max-height"
            hide-delimiters
          >
          
          <template v-slot:prev="{ props }">
              <v-btn
                variant="outlined"
                class="text-transparent"
                icon
                @click="props.onClick"
              ><v-icon class="text-black">mdi-chevron-double-left</v-icon></v-btn>
            </template>
            <template v-slot:next="{ props }">
              <v-btn
                variant="outlined"
                class="text-transparent"
                icon
                @click="props.onClick"
              ><v-icon class="text-black">mdi-chevron-double-right</v-icon></v-btn>
            </template>  
          <v-row  justify="center">
            <v-col
              cols="10"
              sm = "10"
              md = "10"
              lg = "10"
              xl = "10"
            >     
            
            <v-carousel-item  
              v-for="(j, i) in 
              (Math.ceil(carreraI.length/columns1))"
              v-bind:key="i"
              >
            
                <v-row >
                  <template
                  v-for="(moti, index) in carreraI" v-bind:key="index" >
                  <v-col  
                    :cols="col_tam"
                    v-if="index < ((i+1)*columns1)  && index >=((i+1)*columns1)-columns1"
                  >
                  
                  <div v-if="index < ((i+1)*columns1)  && index >=((i+1)*columns1)-columns1">
                      <div
                        class="mx-auto mb-6"
                      >
                      
                        <v-card-title class="d-flex justify-center">
                          <h6 class="text-black text-LiberationSans-Bold text-center">{{moti.nombre }}</h6>
                        </v-card-title>
                      <div class="img-contedor">
                          <v-img
                                :src="baseUrl+''+baseImagen+''+moti.imagen"
                                width="100%"
                                aspect-ratio="1"
                                class="img"
                                @click="ViewCarrera(moti)"
                            >
                            </v-img>
                      </div>
                      <v-row justify="center">
                        <v-col
                          cols="10"
                          class="padding-0"
                        >
                          <div class="d-flex justify-center">  
                            <v-btn block class="background-0d8dff text-white text-bn-tam " @click="ViewCarrera(moti)" rounded="pill" >
                                Más Información
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                      </div>
                    </div>
                  </v-col>
                  </template>
                </v-row>
                
            </v-carousel-item>
            </v-col>
          </v-row>
        </v-carousel>
      </v-col>
    </v-row>
    <h1 class="text-Montserrat-Black text-black margin-carrera text-tam padding-text-title">ÁREA DE NEGOCIOS</h1>
    <v-row justify="center">
      <v-col 
        cols="12">
        <v-carousel
            cycle
            :show-arrows="show_arrows"                                                                                                                                                                     
            height="max-height"
            hide-delimiters
          >
          
          <template v-slot:prev="{ props }">
              <v-btn
                variant="outlined"
                class="text-transparent"
                icon
                @click="props.onClick"
              ><v-icon class="text-black">mdi-chevron-double-left</v-icon></v-btn>
            </template>
            <template v-slot:next="{ props }">
              <v-btn
                variant="outlined"
                class="text-transparent"
                icon
                @click="props.onClick"
              ><v-icon class="text-black">mdi-chevron-double-right</v-icon></v-btn>
            </template>  
          <v-row justify="center">
            <v-col
              cols="10">     
            
            <v-carousel-item  
              v-for="(j, i) in 
              (Math.ceil(carreraA.length/columns))"
              v-bind:key="i"
              >
            
                <v-row >
                  <template
                  v-for="(moti, index) in carreraA" v-bind:key="index" >
                  <v-col  
                    :cols="col_tam1"
                    v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns"
                  >
                  
                  <div v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns">
                      <div
                        class="mx-auto mb-6"
                      >
                      
                      <v-card-title class="d-flex justify-center">
                        <h6 class="text-black text-LiberationSans-Bold text-center">{{moti.nombre }}</h6>
                      </v-card-title>                      
                      <div class="img-contedor">
                          <v-img
                                :src="baseUrl+''+baseImagen+''+moti.imagen"
                                width="100%"
                                aspect-ratio="1"
                                class="img"
                                @click="ViewCarrera(moti)"
                            >
                            </v-img>
                      </div>
                      <v-row justify="center">
                        <v-col
                          cols="10"
                          class="padding-0"
                        >
                          <div class="d-flex justify-center">  
                            <v-btn block class="background-0d8dff text-white text-bn-tam " @click="ViewCarrera(moti)" rounded="pill" >
                                Más Información
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                      </div>
                    </div>
                  </v-col>
                  </template>
                </v-row>
                
            </v-carousel-item>
            </v-col>
          </v-row>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
  
   <v-dialog
      v-model="dialog"
    >
     <v-sheet
      elevation="10"
      rounded="xl"
    >
    <v-card class="dialog_tan">
      <v-card-text>
        <span class="text-Montserrat-Regular">{{carrera.descripcion}}</span>
      </v-card-text>
      <v-card-text>
        <h3 class="text-Montserrat-Regular">CAMPO LABORAL</h3>
      </v-card-text>
      <v-card-text>
        <span class="text-Montserrat-Regular">{{carrera.campo_laboral}}</span>
      </v-card-text>
      <v-row justify="center">
        <v-col 
          cols="12"
          sm="6"
          md="5"
          lg="5"
          xl="5">    
          <v-card-text>
            <span class="text-Montserrat-Black">DURACIÓN</span>
            <p class="text-Montserrat-Regular">{{carrera.duracion_semestre}} SEMESTRES</p>
            <p class="text-Montserrat-Regular">{{carrera.duracion_anio }} AÑO</p>
          </v-card-text>
        </v-col>
        <v-col 
          cols="12"
          sm="6"
          md="5"
          lg="5"
          xl="5">    
          <v-card-text>
            <span class="text-Montserrat-Black">MODALIDAD</span>
            <p class="text-Montserrat-Regular">{{carrera.modalidad }}</p>
          </v-card-text>
        </v-col>
        <v-col 
          cols="12"
          sm="6"
          md="5"
          lg="5"
          xl="5">    
          <v-card-text>
            <span  class="text-Montserrat-Black">HORARIO</span>
            
              <editor
              v-model="carrera.horario"
              api-key="your-api-key"
              cloud-channel="5"
              :disabled=true
              id="uuid"
              width="100%"
              :init= "{  }"
              initial-value=""
              :inline=true
              model-events= ""
              plugins=""
              tag-name="div"
              toolbar=""
              value=""
              />
          </v-card-text>
        </v-col>
        <v-col 
          cols="12"
          sm="6"
          md="5"
          lg="5"
          xl="5">    
          <v-card-text>
            <span  class="text-Montserrat-Black">REQUISITO</span>
             <editor
              v-model="carrera.requisito"
              api-key="your-api-key"
              cloud-channel="5"
              :disabled=true
              id="uuid4"
              width="100%"
              :init= "{  }"
              initial-value=""
              :inline=true
              model-events= ""
              plugins=""
              tag-name="div"
              toolbar=""
              value=""
              />
          </v-card-text>
        </v-col>
      </v-row>
      <v-card-actions >
        
        <v-btn
          block
          class="background-navbar text-white text-Montserrat-Black"
          @click="formNuevo(carrera)"
        >
          ¡REGISTRATE AQUI!
        </v-btn>
      </v-card-actions>
      </v-card>
     </v-sheet>
    </v-dialog>
    
    <v-dialog
      v-model="dialog_info"
    >
     <v-sheet
      elevation="10"
      rounded="xl"
    >
    <v-card class="dialog_tan">
      <v-card-text>
        <h3 class="text-center">{{informacion.curso}}</h3>
        <v-text-field
        :rules="textRules"
        label="Nombre"
        required
        v-model="informacion.nombre"
        ></v-text-field>

        <v-text-field
        :rules="textRules"
        label="Apellido "
        required
        v-model="informacion.apellido "
        ></v-text-field>

        <v-text-field
        :rules="textRules"
        label="Celular"
        required
        v-model="informacion.celular"
        ></v-text-field>

        <v-text-field
        :rules="emailRules"
        label="Correo"
        required
        v-model="informacion.correo"
        ></v-text-field>

      </v-card-text>
      <v-card-actions >
        
        <v-btn
          block
          class="background-navbar text-white text-Montserrat-Black"
          @click="crear"
        >
          REGISTRAR
        </v-btn>
      </v-card-actions>
      </v-card>
     </v-sheet>
    </v-dialog>   
</template>

<script>
//import Editor from '@tinymce/tinymce-vue'
import axios from 'axios'
var url ='backend-ccatarija/crud-carrera.php';
var url_info ='backend-ccatarija/crud-informacion.php';


export default {
  name: 'CcaCarrera',
  props: {
  },
  components : {
  },
 methods: {
    mostrarI(){
      axios.post(this.baseUrl+''+url,{opcion:6,areas_id:"1"})
      .then(Response =>{
        this.carreraI=Response.data;
        this.carreraI_length=this.carreraI.length;
      })
    },
    mostrarA(){
      axios.post(this.baseUrl+''+url,{opcion:6,areas_id:"2"})
      .then(Response =>{
        this.carreraA=Response.data
      })
    },
    formNuevo (carrera){
      this.dialog = false;
      this.dialog_info = true;
      this.operacion='crear';
      this.informacion.nombre  = "";
      this.informacion.apellido = "";
      this.informacion.celular  = "";
      this.informacion.correo  = "";
      this.informacion.curso = carrera.nombre;
    },
    
    crear (){
        
      axios.post(this.baseUrl+''+url_info, {opcion:2, nombre:this.informacion.nombre, apellido :this.informacion.apellido , celular  :this.informacion.celular  , 
                        correo :this.informacion.correo , curso:this.informacion.curso })
        .then(response =>{
          response;
        });  
      this.dialog_info= false;

    },
    ViewCarrera(carrera1){
     this.$emit("carrera_id",carrera1.id),
     this.$emit("ViewCarreraTarija",true),
     this.$emit("ViewCarreraSucre",false)
    }
   
 },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 3;
      }

      if (screen.width > 960) {
        return 4;
      }

      return 1;
    },
    columns1() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 3;
      }

      if (screen.width > 960) {
        return 3;
      }

      return 1;
    },
    
    col_tam(){
      
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 4;
      }

      if (screen.width > 960) {
        return 4;
      }

      return 1;
    },
    
    col_tam1(){
      
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 4;
      }

      if (screen.width > 960) {
        return 3;
      }

      return 1;
    },
    show_arrows(){
      
      if (screen.width > 961 ) {
        return false;
      }
      return true;
    }
  },
  data(){
    return {
      x1 : true,
        //baseUrl : "http://localhost/",
        baseUrl : "https://cca.edu.bo/",
       baseImagen : "img-ccatarija/img-carrera/",
      carreraI : [],
      carreraA : [],
      carreraI_length : 0,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      dialog : false,
      dialog_info : false,
      carrera : {
        id: null,
        nombre : "",
        areas : "",
        descripcion  : "",
        campo_laboral  : "",
        duracion_semestre : "",
        duracion_anio  : "",
        horario  : "",
        modalidad   : "",
        requisito  : "",
        imagen : "",
        estado : ""
      },
      informacion : {
        id: null,
        nombre : "",
        apellido  : "",
        celular  : "",
        correo   : "",
        curso : "",
      },
    };
  },
  mounted(){
      this.mostrarI();
      this.mostrarA();
    }

      

}
</script>

<style scoped>
.padding-0{
    padding-top: 0px;
}
.text-LiberationSans-Bold{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.text-whit{
  color: white;
}
.text-ffcf00{
  color : #ffcf00
}
.text-004c8c{
  color: #004c8c;
}

.background-0d8dff{
   background: #0d8dff
}
.background-ffbe0d{
   background: #ffbe0d
}
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.border-rad{
    
    border-radius: 9999px !important;
}

.margin-carrera{
    margin: 20px 0px 10px 0px
  }
.padding-text-title{
    padding-left: 11%;
} 
  .img{
 cursor: pointer;
  color: inherit;
}

.img-contedor:hover .img {
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
  }
.img-contedor {
  overflow:hidden;
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  .dialog_tan{
   width : 300px
 }
 
  .text-tam{ 
    font-size: 5vw;
  }
  
.size-text-area{ 
    font-size: 5.2vw;
  }  
  
.padding-text-area{
    padding-top: 6%;
} 
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
.dialog_tan{
   width : 601px
 }
 
  .text-tam{ 
    font-size: 3vw;
  }
  
.size-text-area{ 
    font-size: 2vw;
  } 
.padding-text-area{
    padding-top: 5%;
} 
}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
   .dialog_tan{
   width : 930px  
   }
    
  .text-tam{ 
    font-size: 2vw;
  }
  
.size-text-area{ 
    font-size: 2.2vw;
  } 

.padding-text-area{
    padding-top: 5%;
} 
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  .dialog_tan{
   width : 1100px
 }
  
  .text-tam{ 
    font-size: 2vw;
  }
  
.size-text-area{ 
    font-size: 2.2vw;
  } 

.padding-text-area{
    padding-top: 4%;
} 
    
 }
@media (min-width: 1905px)  { 
  .dialog_tan{
   width : 1700px
 }
  
  .text-tam{ 
    font-size: 2vw;
  }
  
.size-text-area{ 
    font-size: 2vw;
  } 

.padding-text-area{
    padding-top: 4%;
} 
}


</style>
