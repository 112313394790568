<template>
  <v-carousel cycle height="max-height" hide-delimiter-background class="text-white " hide-delimiters
    :show-arrows="false">
    <template v-slot:prev="{ props }">
      <v-btn variant="outlined" class="text-transparent" icon @click="props.onClick"><v-icon
          class="text-whit text-booton-tam">mdi-chevron-left</v-icon></v-btn>
    </template>
    <template v-slot:next="{ props }">
      <v-btn variant="outlined" class="text-transparent" icon @click="props.onClick"><v-icon
          class="text-whit text-booton-tam">mdi-chevron-right</v-icon></v-btn>
    </template>

    <!--<template v-if="sliders.length == 0">-->
    <v-carousel-item src="../../../assets/bienvenido.webp">
      <div class="padding-text">
        <h3 class="text-Montserrat-BlackItalic text-titulo-tam">BIENVENIDO</h3>
        <p class="text-Montserrat-BlackItalic text-f5c827 text-descrip-tam">ERES PARTE DE LA FAMILIA CCA</p>
      </div>
      <div class=" padding-bn container">
        <p class="text-Montserrat-BlackItalic text-descrip-tam mb-1 mb-sm-4">SIGUENOS EN NUESTRAS REDES SOCIALES</p>
        <v-row >
          <v-col class="d-flex justify-center px-0 mx-0">
            <v-btn icon class="background-ff0000 text-white margin-booton icon-size" href="https://goo.gl/maps/32QKhU3a1UsACuFdA" target="_blank" :size="height_bootom">
              <v-icon>mdi-map-marker</v-icon></v-btn>
          </v-col>
          <v-col class="d-flex justify-center px-1 mx-1">
            <v-btn icon variant="text" class="text-8f39ce margin-booton icon-size1" href="mailto:cca.sucre@cca.edu.bo" target="_blank" :size="height_bootom">
              <v-icon>mdi-email</v-icon></v-btn>
          </v-col>
          <v-col class="d-flex justify-center px-1 mx-1">
            <v-btn icon variant="text" class="background-white text-0037c1 margin-booton icon-size1" href="https://www.facebook.com/SucreCCA/" target="_blank"
              :size="height_bootom"> <v-icon>mdi-facebook</v-icon></v-btn>
          </v-col>
          <v-col class="d-flex justify-center px-1 mx-1">
            <v-btn icon class="background-instragram text-white margin-booton icon-size" href="https://www.instagram.com/cca_sucre/" target="_blank"
              :size="height_bootom"><v-icon>mdi-instagram</v-icon></v-btn>
          </v-col>
          <v-col class="d-flex justify-center px-1 mx-1">
            <v-btn icon class="background-080011 text-white margin-booton icon-size"  href="https://www.tiktok.com/@cca_sucre?_t=8cnzQvBrjpG&_r=1" target="_blank" :size="height_bootom">
              <v-img src="../../../assets/Logos/tiktok.png" class="cursor img-logo">
              </v-img>
            </v-btn>
          </v-col>
          <v-col class="d-flex justify-center px-1 mx-1">
            <v-btn icon class=" text-white margin-booton icon-size" href="https://moodlesucre.cca.edu.bo" target="_blank" :size="height_bootom">
              <v-img src="../../../assets/Logos/logoMoodle.svg" class="cursor img-logo">
              </v-img>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>
  
<script>

import axios from 'axios'
var url = 'backend-ccatarija/crud-slider.php';
var url_pagina = 'backend-ccatarija/crud-pagina.php';


export default {
  name: 'CcaSucreCarouselBienvenido',
  props: {
  },
  components: {
  },
  methods: {

    mostrar() {
      axios.post(this.baseUrl + '' + url, { opcion: 1 })
        .then(Response => {
          this.sliders = Response.data
        })
    },
    mostrar_pagina() {
      axios.post(this.baseUrl + '' + url_pagina, { opcion: 6 })
        .then(Response => {
          this.paginas = Response.data
        })
    },
  },
  data() {
    return {
      sliders: [],
      paginas: [],
      //baseUrl : "http://localhost/",
      baseUrl: "https://cca.edu.bo/",
      baseImagen: "img-ccatarija/img-carousel/",
    };
  },
  computed: {

    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "x-small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  mounted() {
    this.mostrar();
  }



}
</script>
  
<style scoped>
.background-f5c827 {
  background: #f5c827
}

.text-f5c827 {
  color: #f5c827
}

.text-034c96 {
  color: #034c96;
}

.img-logo {
  height: 50px;
  width: 50px;
}

.text-LiberationSans-Boldy {
  font-family: "LiberationSans-Bold";
}

.text-LiberationSans-Regular {
  font-family: "LiberationSans-Regular";
}

.text-Montserrat-Black {
  font-family: "Montserrat-Black";
}

.text-Montserrat-Regular {
  font-family: "Montserrat-Regular";
}

.text-Montserrat-BlackItalic {
  font-family: "Montserrat-BlackItalic";
}

.ffcf00-background {
  background: #ffcf00
}

.background-0d8dff {
  background: #0d8dff
}

.background-0037c1 {
  background: #0037c1
}

.text-0037c1 {
  color: #0037c1;
}

.text-whit {
  color: white;
}

.background-white {
  background: white;
}

.background-navbar {
  background: linear-gradient(90deg, #004c8c, #0d8dff);
}

.background-instragram {
  background: linear-gradient(25deg, #6c48e0, #db3b8d, #fbd26f);
}

.background-8f39ce {
  background: #8f39ce
}

.text-8f39ce {
  color: #8f39ce;
}

.background-ff0000 {
  background: #ff0000
}

.background-57dc70 {
  background: #57dc70
}

.background-080011 {
  background: #080011
}

.text-booton-tam {
  font-size: 4.5vw
}

.margin-booton {
  margin: 0px 10px 0px 10px;
}

/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) {

  .img-logo {
    height: 30px;
    width: 30px;
  }

  .icon-size {
    font-size: 15px;
  }

  .icon-size1 {
    font-size: 28px;
  }

  .margin-booton {
    margin: 0px 0px 0px 0px;
  }

  .text-booton-tam {
    font-size: 6vw
  }

  .padding-text {
    padding-left: 11%;
    padding-top: 6%;
  }

  .padding-bn {
    left: 9%;
    bottom: 3%;
    position: absolute;
  }

  .text-titulo-tam {
    font-size: 5.5vw;
  }

  .text-descrip-tam {
    font-size: 2.5vw;
  }

  .text-bn-tam {
    font-size: 2vw;
  }

  /* Fin de nemu*/
}

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) {
  .img-logo {
    height: 35px;
    width: 35px;
  }

  .icon-size {
    font-size: 20px;
  }

  .icon-size1 {
    font-size: 38px;
  }

  .padding-text {
    padding-left: 12%;
    padding-top: 8%;
  }

  .margin-booton {
    margin: 0px 5px 0px 5px;
  }

  .padding-bn {
    left: 5%;
    bottom: 6%;
    position: absolute;
  }

  .text-titulo-tam {
    font-size: 4.5vw;
  }

  .text-descrip-tam {
    font-size: 2.1vw;
  }

  .text-bn-tam {
    font-size: 1vw;
  }

}

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) {
  .icon-size {
    font-size: 28px;
  }

  .icon-size1 {
    font-size: 48px;
  }

  .padding-text {
    padding-left: 12%;
    padding-top: 8%;
  }

  .margin-booton {
    margin: 0px 0px 0px 0px;
  }

  .padding-bn {
    left: 4%;
    bottom: 4%;
    position: absolute;
  }

  .text-titulo-tam {
    font-size: 5vw;
  }

  .text-descrip-tam {
    font-size: 2vw;
  }

  .text-bn-tam {
    font-size: 1vw;
  }

}

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) {

  .icon-size {
    font-size: 30px;
  }

  .icon-size1 {
    font-size: 50px;
  }

  .padding-text {
    padding-left: 12%;
    padding-top: 7%;
  }

  .padding-bn {
    left: 3%;
    bottom: 5%;
    position: absolute;
  }

  .text-titulo-tam {
    font-size: 5vw;
  }

  .text-descrip-tam {
    font-size: 2vw;
  }

  .text-bn-tam {
    font-size: 1vw;
  }
}

@media (min-width: 1905px) {
  .icon-size {
    font-size: 30px;
  }

  .icon-size1 {
    font-size: 57px;
  }

  .padding-text {
    padding-left: 10%;
    padding-top: 8%;
  }

  .padding-bn {
    left: 5%;
    bottom: 5%;
    position: absolute;
  }

  .text-titulo-tam {
    font-size: 5.5vw;
  }

  .text-descrip-tam {
    font-size: 1.5vw;
  }

  .text-bn-tam {
    font-size: 1vw;
  }

}
</style>
  