<template>
  <Estadistica />
</template>
<script>
import Estadistica from '@/components/CcaSucre/Estadistica/Estadistica.vue';
import axios from 'axios'
var url ='backend-ccatarija/crud-slider.php';
var url_pagina ='backend-ccatarija/crud-pagina.php';


export default {
  name: 'CcaSucreInstitutoCca',
  props: {
  },
  components : {
    Estadistica,
  },
 methods: {
   
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1})
      .then(Response =>{
        this.sliders=Response.data
      })
    },
    mostrar_pagina(){
      axios.post(this.baseUrl+''+url_pagina,{opcion:6})
      .then(Response =>{
        this.paginas=Response.data
      })
    },
 },
  data(){
    return {
        sliders : [],
        paginas : [],
        //baseUrl : "http://localhost/",
        baseUrl : "https://cca.edu.bo/",
        baseImagen : "img-ccatarija/img-carousel/",
      baseVideo : "https://www.youtube.com/embed/",
    };
  },
  computed : {
    
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "x-small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

