<template>
<div>
    
    <v-row >
      <v-col
        cols="10"
        offset="1"
      >
        <v-btn
          color="success"
           @click="formNuevo()"
        >
            CREAR <v-icon>mdi-table-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="10"
        offset="1"
      >
        <v-table
            fixed-header
            height="900px" 
        >
            <thead >
            <tr class="light-blue darken-2" >
                <th class="text-left">
                #
                </th>
                <th class="text-left">
                TITULO
                </th>
                <th class="text-left">
                DESCRIPCION
                </th>
                <th class="text-left">
                ENLACE
                </th>
                <th class="text-left">
                IMAGEN
                </th>
                <th class="text-left">
                ESTADO
                </th>
                <th class="text-left">
                ACCCIONES
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="(moti, index) in cursos" v-bind:key="index"
            >
                <td>{{index+1}}</td>
                <td>{{moti.titulo}}</td>
                <td>{{moti.descripcion}}</td>
                <td>{{moti.enlace}}</td>
                <td> 
                  <v-img
                      :src="baseUrl+''+baseImagen+''+moti.imagen"
                      width="100%"
                      aspect-ratio="1"
                      class="cursor"
                      @click="formImagen (moti)"
                  >
                  </v-img>
                </td>
                <td class="text-blue" v-if="moti.estado == 1">
                  HABILITADO
                </td>
                <td class="text-red" v-if="moti.estado == 0">
                  DESHABILITADO 
                </td>
                <td class="text-white">
                <v-btn
                  icon="mdi-table-edit"
                  color="info"
                  @click="formEditar (moti)"
                ></v-btn>
                <v-btn
                    icon="mdi-table-eye-off"
                    @click="formElimina(moti.id)"
                    class="background-red"
                    v-if="moti.estado == 1"
                ></v-btn>
                <v-btn
                    icon="mdi-table-eye"
                    @click="formHabilitar(moti.id)"
                    class="background-success"
                    v-if="moti.estado == 0"
                ></v-btn>
                </td>
            </tr>
            </tbody>
        </v-table>
        </v-col>
    </v-row>
    
    <v-dialog
      v-model="dialog"
      persistent
    >
    <v-card class="dialog_tan">
      <v-form 
      v-model="valid"
      lazy-validation
      ref="form">
      <v-toolbar
        color="success"
      >Nuevo Slider</v-toolbar>
      <v-card-text>
        <v-text-field
        v-if="!if_imagen"
        :rules="textRules"
        label="Titulo"
        required
        v-model="curso.titulo"
        ></v-text-field>
        
        <v-textarea
        v-if="!if_imagen"
        background-color="amber lighten-4"
        color="orange orange-darken-4"
        label="Descripcion"
        v-model="curso.descripcion "
        ></v-textarea>
        <v-text-field
        v-if="!if_imagen"
        :rules="textRules"
        label="Enlace"
        required
        v-model="curso.enlace"
        ></v-text-field>
        <v-file-input 
        v-if="if_imagen == if_crear"
        label="imagen"
        show-size
        filled
        prepend-icon="mdi-camera"
        @change="cargarImagen"
        ></v-file-input>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="dialog=false"
        >Cancelar</v-btn>
        <v-btn
          text
          @click="guardar_s"
        >Guardar</v-btn>
      </v-card-actions>
      </v-form>
    </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogEliminar"
    >
    <v-toolbar
      class="background-red text-white"
    >DESHABILITAR</v-toolbar>
    <v-card>
      <v-card-text>
        <span >Esta seguro de desHabiliatar ?</span>
      </v-card-text>
      <v-card-actions class="justify-end text-white">
        <v-btn
          class="background-success"
          @click="dialogEliminar=false"
        >Cancelar</v-btn>
        <v-btn
          class="background-red "
          @click="eliminar_slider"
        >Si</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogHabilitar"
    >
    <v-toolbar
      class="background-1C759F text-white"
    >HABILITAR</v-toolbar>
    <v-card>
      <v-card-text>
        <span >Esta seguro de Habiliatar ?</span>
      </v-card-text>
      <v-card-actions class="justify-end text-white">
        <v-btn
          class="background-success"
          @click="dialogHabilitar=false"
        >Cancelar</v-btn>
        <v-btn
          class="background-red "
          @click="habilitar_slider"
        >Si</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
  </div>
</template>

<script>

import axios from 'axios'
var url ='backend-ccatarija/crud-noticia.php';
var urlsubirimagen ='backend-ccatarija/upload-noticia.php'

export default {
  name: 'CcaNoticiaConfig',
  props: {
  },
  components : {
  },
 methods: {
    formNuevo (){
      this.dialog = true
      this.operacion='crear';
      this.curso.titulo = "";
      this.curso.descripcion  = "";
      this.curso.enlace  = "";
      this.curso.imagen = "";
      this.if_imagen=false;
      this.if_crear = false;
      this.select= { id: '', titulo: '' }
    },
    formElimina(id){
      this.curso.id=id;
      this.dialogEliminar = true
    },
    formHabilitar(id){
      this.curso.id=id;
      this.dialogHabilitar = true
    },
    formEditar (curso1){
      this.dialog = true;
      this.operacion='editar';
      this.curso.id = curso1.id;
      this.curso.titulo = curso1.titulo;
      this.curso.descripcion  = curso1.descripcion;
      this.curso.enlace  = curso1.enlace;
      this.curso.imagen = curso1.imagen;
      this.if_imagen = false;
      this.if_crear = true;
    },
    formImagen (curso1){
      this.dialog = true;
      this.operacion='img';
      this.curso.id = curso1.id;
      this.curso.titulo = curso1.titulo;
      this.curso.enlace  = curso1.enlace;
      this.curso.imagen = curso1.imagen;
      this.if_crear = true;
      this.if_imagen = true;
    },
    guardar_slider (){
      if(this.operacion=='crear'){
        this.crear_slider();                 
      }
      if(this.operacion=='img'){ 
        this.subirImagen();                           
      }
      if(this.operacion=='editar'){ 
        
        this.editar_slider();                           
      }
      this.dialog=false;  
    },
    eliminar_slider (){
      axios.post(this.baseUrl+''+url, {opcion:4, id:this.curso.id, estado: false })
        .then(response =>{
          response;
          this.mostrar();

        });  
      
      this.curso.id = null;
      this.dialogEliminar= false;
    },
    habilitar_slider(){
      axios.post(this.baseUrl+''+url, {opcion:4, id:this.curso.id, estado: true })
        .then(response =>{
          response;
          this.mostrar();
        });  
      
      this.curso.id = null;
      this.dialogHabilitar= false;

    },
    crear_slider (){
        
      axios.post(this.baseUrl+''+url, {opcion:2, titulo:this.curso.titulo, descripcion:this.curso.descripcion, enlace :this.curso.enlace , imagen:this.curso.imagen })
        .then(response =>{
          response;
          this.mostrar();
        });  
      this.curso.titulo = "";
      this.curso.imagen = "";
      this.dialog= false;

    },
    editar_slider (){
    
      axios.post(this.baseUrl+''+url, {opcion:3,id:this.curso.id,titulo: this.curso.titulo, descripcion:this.curso.descripcion, enlace :this.curso.enlace,
                        imagen:this.curso.imagen })
        .then(response =>{
          response;
          console.log(response.data);
          this.mostrar();

        });  
      this.curso.titulo = "";
      this.curso.imagen = "";
      this.dialog= false;

    },
   cargarImagen(e){
    this.file = e.target.files[0];
   },
   subirImagen(){
    var fd = new FormData();
    fd.append("file", this.file, this.file.name)
    //console.log(fd.getAll("file"));
    
    axios
    .post(this.baseUrl+''+urlsubirimagen, fd)
    .then(response => {
        //console.log(response.data)
      this.curso.imagen=response.data[1];
      if(this.operacion=='img'){ 
        this.editar_slider();                          
      }else{
        this.guardar_slider();
      }
      this.mostrar();
    })
    .catch(error => {
        console.log(error.data)
    })
   },
   guardar_s(){
     
    if(this.operacion=='crear' || this.operacion=='img'){
      this.subirImagen();              
    }
    if(this.operacion=='editar'){ 
      this.editar_slider();                           
    }
   },
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:5})
      .then(Response =>{
        this.cursos=Response.data
      })
    }
 },
  data(){
    return {
      carouselheight: 0,
      valid : true,
      d : null,
      if_imagen : true,
      if_crear : true,
      cursos : [],
      curso : {
        id: null,
        titulo : "",
        descripcion  : "",
        enlace  : "",
        imagen : "",
        estado : ""
      },
       file: null,
       dialog : false,
       dialogEliminar : false,
       dialogHabilitar : false,
      //baseUrl : "http://localhost/",
      baseUrl : "https://cca.edu.bo/",
       baseImagen : "img-ccatarija/img-noticia/",
       
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      textRules: [
        v => !!v || 'Es requerido el campo',
      ],
    };
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

<style scoped>

.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.background-ffcf00{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
}
.text-whit{
  color: white;
}
.text-ffcf00{
  color: #ffcf00;
}
.background-red{
  background : red;
}
.background-success{
  background : green;
}
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.border-rad{
    
    border-radius: 9999px !important;
}

.margin-est{
    margin: 20px 0px 0px 0px
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  
 .dialog_tan{
   width : 320px
 }
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
.dialog_tan{
   width : 601px
 }

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
  .dialog_tan{
   width : 930px
 }
    
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  .dialog_tan{
   width : 1200px
 }
    
    
 }
@media (min-width: 1905px)  { 
  .dialog_tan{
   width : 1890px
 }

}


</style>
