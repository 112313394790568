<template>
  <v-row class="estadisticas-sucre container">
    <template v-for="(estadistica, index) in estadisticas" v-bind:key="index">
      <template :v-model="value11 = parseInt(estadistica.graduados)"></template>
      <template :v-model="value22 = parseInt(estadistica.carreras)"></template>
      <template :v-model="value33 = parseInt(estadistica.docentes)"></template>
      <v-col cols="12" sm="6" md="4" lg="4">
        <div class="card d-flex flex-column fill-height justify-center align-center ">
          <div>
            <h3>
              {{ value1 }}K
            </h3>
          </div>
          <div>
            <h3>
              GRADUADOS
            </h3>
          </div>
          <div class="text-center">

            {{ estadistica.graduados_text }}

          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4">
        <div class="card d-flex flex-column fill-height justify-center align-center ">
          <div>
            <h3>
              {{ value2 }}
            </h3>
          </div>
          <div>
            <h3>
              CARRERAS
            </h3>
          </div>
          <div class="text-center">


            {{ estadistica.carreras_text }}

          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <div class="card d-flex flex-column fill-height justify-center align-center ">
          <div>
            <h3>
              {{ value3 }}
            </h3>
          </div>
          <div>
            <h3>
              DOCENTES
            </h3>
          </div>
          <div class="text-center">

            {{ estadistica.docentes_text }}

          </div>
        </div>
      </v-col>
    </template>
  </v-row>
</template>

<script>

import axios from 'axios'
var url = 'backend-ccasucre/crud-estadistica.php';


export default {
  name: 'CcaSucreEstadistica',
  props: {
  },
  components: {
  },
  methods: {
    mostrar() {
      axios.post(this.baseUrl + '' + url, { opcion: 5 })
        .then(Response => {
          this.estadisticas = Response.data
        })
    },
  },
  data() {
    return {
      //baseUrl : "http://localhost/",
      baseUrl: "https://cca.edu.bo/",
      estadisticas: {},
      interval1: {},
      interval2: {},
      interval3: {},
      value1: 0,
      value2: 0,
      value3: 0,
      value11: 0,
      value22: 0,
      value33: 0,
    };
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
  mounted() {
    this.interval1 = setInterval(() => {
      if (this.value1 === this.value11) {
        return (this.value1 = this.value11)
      }
      this.value1 += 1
    }, 140)
    this.interval2 = setInterval(() => {
      if (this.value2 === this.value22) {
        return (this.value2 = this.value22)
      }
      this.value2 += 1
    }, 190)
    this.interval3 = setInterval(() => {
      if (this.value3 === this.value33) {
        return (this.value3 = this.value33)
      }
      this.value3 += 10
    }, 140)
    this.mostrar();
  },



}
</script>
