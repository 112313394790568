<template>
  <div class="margin-est">
    <v-carousel
      cycle
      height="max-height"
      :show-arrows="false"
      hide-delimiters
      hide-delimiter-background
      class="text-white "
    >    
      <v-carousel-item
          src="../../../assets/redsocial.webp"
      >
      <div class="margin-title">
        <v-card-title class="text-Montserrat-Black text-size-title padding-title">
          SIGUENOS A TRAVÉS DE NUESTRAS
        </v-card-title>
        <v-card-title class="text-Montserrat-Black text-ffcf00 text-size-title padding-title1">
          REDES SOCIALES
        </v-card-title>
        </div>
        <template v-if="!logos" >
          <v-row >
            <v-col 
              cols="6">
              <v-row justify="end">
                <v-col cols="6">
                    <v-row justify="end">  
                        <v-col 
                          cols="6"
                          sm = "4" >
                          <v-img src="../../../assets/Logos/tiktok.webp" width="100%" class="cursor" v-on:click="redesol('https://vm.tiktok.com/ZMNNYg9c2/')"></v-img>            
                        </v-col>
                        <v-col 
                          cols="6"
                          sm = "4">
                          <v-img src="../../../assets/Logos/yootube.webp" width="100%" class="cursor" v-on:click="redesol('https://www.youtube.com/channel/UC4wqd0BjIZwBV0w6uzq2m8w')"></v-img>          
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6">
                    <v-row justify="end">
                        <v-col 
                          cols="6"
                          sm = "4">
                          <v-img src="../../../assets/Logos/facebook.webp" width="100%" class="cursor" v-on:click="redesol('https://www.facebook.com/ccadelsur')"></v-img>
                        </v-col>
                        <v-col 
                          cols="6"
                          sm = "4">
                          <v-img src="../../../assets/Logos/instagram.webp" width="100%" class="cursor" v-on:click="redesol('https://www.instagram.com/instituto_cca/')"></v-img>
                        </v-col>   
                    </v-row>
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </template>
        <template v-if="logos">
          <v-row >
            <v-col cols="6">
              <v-row justify="end">
                  <v-col cols="8">
                    <v-row justify="center">
                        <v-col cols="10">
                          <v-btn
                            rounded="pill"
                            color="white"
                            block
                            :size="height_bootom"
                          >
                            <v-card-text class="text-LiberationSans-Bold text-size-redes" v-on:click="redesol('https://www.facebook.com/ccadelsur')">
                            <v-icon 
                                class="text-0037c1 " >mdi-facebook</v-icon>
                              INSTITUTO TÉCNICO CCA
                            </v-card-text>
                          </v-btn>
                        </v-col>
                        <v-col cols="10">
                          <v-btn
                            rounded="pill"
                            color="white"
                            block
                            :size="height_bootom"
                          >
                            <v-card-text class="text-LiberationSans-Bold text-size-redes  text-start" v-on:click="redesol('https://www.instagram.com/instituto_cca/')">
                            <v-icon 
                                class="text-b31373" >mdi-instagram</v-icon>
                              <!--<v-avatar class="margin-img">
                                <v-img src="../../../assets/Logos/instagram.webp"></v-img>
                              </v-avatar>-->
                              INSTITUTO_CCA
                            </v-card-text>
                          </v-btn>
                        </v-col>
                        <v-col cols="10">
                          <v-btn
                            rounded="pill"
                            color="white"
                            block
                            :size="height_bootom"
                          >
                            <v-card-text class="text-LiberationSans-Bold text-size-redes d-flex" v-on:click="redesol('https://vm.tiktok.com/ZMNNYg9c2/')">
                                <v-img src="../../../assets/Logos/tiktok.webp" class="logo-tam"></v-img>
                              INSTITUTOCCATARIJA
                            </v-card-text>
                          </v-btn>
                        </v-col>
                        <!--<v-col cols="10">
                            <v-btn
                            rounded="pill"
                            color="white"
                            block
                            :size="height_bootom"
                          >
                            <v-card-text class="text-LiberationSans-Bold text-size-redes " v-on:click="redesol('https://wa.link/azodin')">
                            <v-icon 
                                class="text-red" >mdi-whatsapp</v-icon>
                              +591 71864008
                            </v-card-text>
                          </v-btn>
                        </v-col>-->
                        <v-col cols="10">
                          <v-btn
                            rounded="pill"
                            color="white"
                            block
                            :size="height_bootom"
                          >
                            <v-card-text class="text-LiberationSans-Bold text-size-redes " v-on:click="redesol('https://www.youtube.com/channel/UC4wqd0BjIZwBV0w6uzq2m8w')">
                            <v-icon 
                                class="text-red linkedin" >mdi-youtube</v-icon>
                              <!--<v-avatar class="margin-img">
                                <v-img src="../../../assets/Logos/yootube.webp"></v-img>
                              </v-avatar>-->
                              INSTITUTOCCATARIJA
                            </v-card-text>
                          </v-btn>
                        </v-col>
                    </v-row>
                  </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>



export default {
  name: 'CcaRedSocial',
  props: {
  },
  components : {
  },
 methods: {
   redesol(href){
    //window.location.href=href;
    window.open(href);
   }
 },
  data(){
    return {
    };
  },
  mounted(){
    //console.log(this.logos)
    },
  computed: {
    logos() {
      if (screen.width > 0 && screen.width <= 960) {
        return false;
      }
      if (screen.width > 960) {
        return true;
      }
      return true;
    },
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "x-small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "x-small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "small";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  }

      

}
</script>

<style scoped>

.cursor{
 cursor: pointer;
    color: inherit;
}
.text-LiberationSans-Bold{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.background-ffcf00{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
}
.text-whit{
  color: white;
}
.text-ffcf00{
  color: #ffcf00;
}
.text-0037c1{
  color: #0037c1;
}
.text-b31373{
  color: #b31373;
}
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.border-rad{
    
    border-radius: 9999px !important;
}
.margin-est{
    margin: 20px 0px 40px 0px
  }
.margin-0{
    margin: 0px
  }
  
  .logo-tam{
    width : 25px
  }
  
  .margin-title{
    margin: 0px 0px 20px 0px
    
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) {  
  .text-size-title{
    font-size: 3vw;
  }
  .text-size-redes{
    font-size: 1.7vw;
  }
  .padding-title{
    padding-left: 5%;
    padding-right: 50%;
    padding-bottom: 0%;
    padding-top: 2%
  }
  .padding-title1{
    padding-left: 5%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 0%
  }
  .logo-tam{
    width : 25px
  }
  
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
  .text-size-title{
    font-size: 2.5vw;
  }
  .text-size-redes{
    font-size: 1.7vw;
  }
  .padding-title{
    padding-left: 8%;
    padding-right: 48%;
    padding-bottom: 0%;
    padding-top: 2%
  }
  .padding-title1{
    padding-left: 8%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 0%
  }
  .logo-tam{
    width : 50px
  }
  .margin-logo{
    margin: 20px 0px 40px 0px
  }

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
    .logo-tam{
    width : 20px
  }
  .text-size-title{
    font-size: 1.7vw;
  }
  .text-size-redes{
    font-size: 1.4vw;
  }
  .padding-title{
    padding-left: 20%;
    padding-right: 58%;
    padding-bottom: 0%;
    padding-top: 2%;
  }
  .padding-title1{
    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 0%
  }
  .padding-facebook{
    padding-left: 13%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 1%
  }
  .padding-instagram{
    padding-left: 13%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 1%
  }
  .padding-tiktok{
    padding-left: 13%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 1%
  }
  .padding-whatsapp{
    padding-left: 13%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 1%
  }
  .padding-yootube{
    padding-left: 13%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 1%
  }
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  .text-size-title{
    font-size: 2vw;
  }
  .text-size-redes{
    font-size: 1.4vw;
  }
  .padding-title{
    padding-left: 20%;
    padding-right: 55%;
    padding-bottom: 0%;
    padding-top: 2%
  }
  .padding-title1{
    padding-left: 20%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 0%
  }
  .padding-facebook{
    padding-left: 13%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 1%
  }
  .padding-instagram{
    padding-left: 13%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 1%
  }
  .padding-tiktok{
    padding-left: 13%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 1%
  }
  .padding-whatsapp{
    padding-left: 13%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 1%
  }
  .padding-yootube{
    padding-left: 13%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 1%
  }
    
    
 }
@media (min-width: 1905px)  { 
  .text-size-title{
    font-size: 2vw;
  }
  .text-size-redes{
    font-size: 1.2vw;
  }
  .logo-tam{
    width : 40px
  }
  .padding-title{
    padding-left: 20%;
    padding-right: 55%;
    padding-bottom: 0%;
    padding-top: 3%
  }
  .padding-title1{
    padding-left: 20%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 0%
  }
  .padding-facebook{
    padding-left: 15%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 1%
  }
  .padding-instagram{
    padding-left: 15%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 1%
  }
  .padding-tiktok{
    padding-left: 15%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 1%
  }
  .padding-whatsapp{
    padding-left: 15%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 1%
  }
  .padding-yootube{
    padding-left: 15%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 1%
  }
}


</style>
