<template>
    <div class="banner-sucre d-flex justify-center  flex-column" :style="{ backgroundImage:img?`url('${img}')`: `url(${require('@/assets/banner.png')})` }">
        <div class="container d-flex justify-center align-center ">
            <h2 class="title">{{title}}</h2>
        </div>

    </div>
</template>
<script>
export default{
    name:'banner-vue',
    props:['title','img']
}
</script>