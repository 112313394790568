<template>
<div style="height: 75vh; width: 100%;">
    <l-map
      v-model="zoom"
      v-model:zoom="zoom"
      :center="center"
      @move="log('move')"
    >
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      ></l-tile-layer>
      <l-control-layers />

      <l-marker :lat-lng="center">
        <l-tooltip>
          Instituto Técnico C.C.A. Del Sur
        </l-tooltip>
        <l-icon :icon-url="iconUrl" :icon-size="iconSize" />
      </l-marker>

      <l-polyline
        :lat-lngs="[
          [-21.53242112892609,  -64.73744501534219],
          [-21.53242112892609,  -64.73744501534219],
          [-21.53242112892609,  -64.73744501534219],
          [-21.53242112892609,  -64.73744501534219],
        ]"
        color="#ffcf00"
      ></l-polyline>
      <l-polygon
        :lat-lngs="[
          [-21.53242112892609,  -64.73744501534219],
          [-21.53242112892609,  -64.73744501534219],
          [-21.53242112892609,  -64.73744501534219],
          [-21.53242112892609,  -64.73744501534219],
        ]"
        color="#ffcf00"
        :fill="true"
        :fillOpacity="0.5"
        fillColor="#ffcf00"
      />
      <l-rectangle
        :lat-lngs="[
          [-21.53242112892609,  -64.73744501534219],
          [-21.53242112892609,  -64.73744501534219],
          [-21.53242112892609,  -64.73744501534219],
          [-21.53242112892609,  -64.73744501534219],
        ]"
        :fill="true"
        color="#35495d"
      />
      <l-rectangle
        :bounds="[
          [-21.53242112892609,  -64.73744501534219],
          [-21.53242112892609,  -64.73744501534219],
        ]"
      >
        <l-popup>
          lol
        </l-popup>
      </l-rectangle>
    </l-map>
  </div>
</template>

<script>
//import { GoogleMap, Marker } from "vue3-google-map";
import "leaflet/dist/leaflet.css"

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LTooltip,
  LPopup,
  LPolyline,
  LPolygon,
  LRectangle,
} from "@vue-leaflet/vue-leaflet";

export default {
  name: 'CcaMapa',
  props: {
  },
  components : {
    
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LTooltip,
    LPopup,
    LPolyline,
    LPolygon,
    LRectangle,
  },
  setup() {
    const center = { lat: -21.53242112892609, lng: -64.73744501534219 };
    return { center };
  },
  data(){
    return {
      zoom: 17,
      iconWidth: 20,
      iconHeight: 30,
      };
  },
  computed: {
    iconUrl() {
      return `https://cca.edu.bo/img/gps.webp`;
    },
    iconSize() {
      return [this.iconWidth, this.iconHeight];
    },
  },
  methods: {
    log(a) {
      //console.log(a);
      a;
    },
    changeIcon() {
      this.iconWidth += 2;
      if (this.iconWidth > this.iconHeight) {
        this.iconWidth = Math.floor(this.iconHeight / 2);
      }
    },
  },
  mounted(){
    }

      

}
</script>

<style scoped>

.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.background-ffcf00{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
}
.text-whit{
  color: white;
}
.text-ffcf00{
  color: #ffcf00;
}
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.border-rad{
    
    border-radius: 9999px !important;
}

.margin-est{
    margin: 20px 0px 0px 0px
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
    
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
    
    
 }
@media (min-width: 1905px)  { 

}


</style>
