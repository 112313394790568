<template>
  <Principal />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import Principal from '../components/Principal/Main.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    Principal,
  },
});
</script>
