// import { createRouter, createWebHistory   } from 'vue-router'
// import VueRouter from 'vue-router';
import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Sucre from '../views/Sucre.vue'
import BienvenidoSucre from "../views/BienvenidoSucre"
import Tarija from '../views/Tarija.vue'
import ConfigTarija from '@/components/CcaTarija/Configuracion/Configuracion.vue'
import ConfigSucre from '@/components/CcaSucre/Configuracion/Configuracion.vue'
import Bienvenido from '@/views/Bienvenido'
import LoginT from '@/components/CcaTarija/Login/Login.vue'
import QuienesSomos from '@/components/CcaSucre/QuienesSomos/QuienesSomos.vue';
import Carreras from '@/components/CcaSucre/Carrera/Carrera.vue';
import Carrera from '@/components/CcaSucre/Carrera/CarreraView.vue';
import Cursos from '@/components/CcaSucre/Curso/Curso.vue';
import Curso from '@/components/CcaSucre/Curso/CursoView.vue';


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/configccat',
    name: 'configccat',
    component : ConfigTarija
  },
  {
    path: '/configccas',
    name: 'configccas',
    component : ConfigSucre
  },
  {
    path: '/Tarija',
    name: 'Tarija',
    component : Tarija
  },
  {
    path: '/sucre/bienvenido',
    name: 'HomeView',
    component : Sucre,

    children: [
      {
        path: 'bienvenido',
        name: 'bienvenidoSucre',
        component: BienvenidoSucre,
        
      },
      {
        path: 'quienessomos',
        name: 'CcaQuienesSomos',
        component: QuienesSomos,
        
      },
      {
        path: 'carreras',
        name: 'CcaSucreCarrera',
        component: Carreras,
        
      },
      {
        path: ':name/:id', 
        name: 'CcaSucreCarreraView',
        component: Carrera
      },
      {
        path: 'curso/:servicio/:id',
        name: 'CcaSucreCurso',
        component: Cursos,
      },
      {
        path: 'cursos/:name/:id', 
        name: 'CcaSucreCursoView',
        component: Curso
      },
    ],
  
  },
  {
    path: '/sucre/bienvenido',
    name: 'SucreBienvenido',
    component : Bienvenido,
   
  },
  {
    path: '/LoginT',
    name: 'LoginT',
    component : LoginT
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
