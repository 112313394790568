<template>
<div class="margin-est">
  <v-row justify="center">
    <v-col 
      cols="11"
      sm = "10"
      md = "10"
      lg = "10"
      xl = "10">
        <v-row justify="center">
          <v-col cols="4">
            <div class="d-flex justify-center">
                <v-card-title class="text-Poppins-Black text-323131 text-center text-tilte-size margin-0">
                    +{{ value1 }}
                </v-card-title>
            </div>
            
            <div class="d-flex justify-center">
                <v-card-title class="text-Poppins-ExtraBold  text-5b5b5b text-center text-subtilte-size margin-0">   
                    AÑOS FORMANDO <br>
                    PROFESIONALES
                </v-card-title>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="d-flex justify-center">
                <v-card-title class="text-Poppins-Black text-323131 text-center text-tilte-size margin-0">
                    +{{ value2 }}mil
                </v-card-title>
            </div>
            <div class="d-flex justify-center">
                <v-card-title class="text-Poppins-ExtraBold  text-5b5b5b text-center text-subtilte-size margin-0">   
                    ESTUDIANTES <br>
                    GRADUADOS
                </v-card-title>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="d-flex justify-center">
                <v-card-title class="text-Poppins-Black text-323131 text-center text-tilte-size margin-0">
                    +{{ value3 }}
                </v-card-title>
            </div>
            <div class="d-flex justify-center">
                <v-card-title class="text-Poppins-ExtraBold text-5b5b5b text-center text-subtilte-size margin-0">   
                    DOCENTES ALTAMENTE <br>
                    CALIFICADOS
                </v-card-title>
            </div>
          </v-col>
        </v-row>
    </v-col>
  </v-row>
</div>
</template>

<script>



export default {
  name: 'CcaEstadistica',
  props: {
  },
  components : {
  },
 methods: {
   
 },
  data(){
    return {
      
        interval1: {},
        interval2: {},
        interval3: {},
        value1: 0,
        value2: 0,
        value3: 0,
    };
  },
  beforeUnmount () {
    clearInterval(this.interval)
  },
  mounted () {
    this.interval1 = setInterval(() => {
      if (this.value1 === 20) {
        return (this.value1 = 20)
      }
      this.value1 += 1
    }, 140)
    this.interval2 = setInterval(() => {
      if (this.value2 === 65) {
        return (this.value2 = 65)
      }
      this.value2 += 1
    }, 90)
    this.interval3 = setInterval(() => {
      if (this.value3 === 140) {
        return (this.value3 = 140)
      }
      this.value3 += 5
    }, 140)
  },

      

}
</script>

<style scoped>
.padding-0{
    padding: 0px;
}
.text-Poppins-ExtraBold{
  font-family: "Poppins-ExtraBold";
}
.text-Poppins-Black{
  font-family: "Poppins-Black";
}
.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.background-ffcf00{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
}
.text-whit{
  color: white;
}
.text-5b5b5b{
  color: #5b5b5b;
}
.text-323131{
  color: #323131;
}

.text-ffcf00{
  color: #ffcf00;
}
.text-004c8c{
  color : #004c8c;
}
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.border-rad{
    
    border-radius: 9999px !important;
}
.margin-0{
    margin:   -8px;
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  
.margin-est{
    margin: 35px 0px 35px 0px
  }
  .text-tilte-size{
    font-size: 3.7vh;
  }
.text-subtilte-size{
    font-size: 1.1vh;
  }
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
  
.margin-est{
    margin: 40px 0px 40px 0px
  }
  .text-tilte-size{
    font-size: 4.2vh;
  }
.text-subtilte-size{
    font-size: 1.5vh;
  }

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
  
.margin-est{
    margin: 50px 0px 50px 0px
  }
  .text-tilte-size{
    font-size: 4.7vh;
  }
.text-subtilte-size{
    font-size: 1.8vh;
  }
    
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  
.margin-est{
    margin: 60px 0px 60px 0px
  }
  .text-tilte-size{
    font-size: 5vh;
  }
.text-subtilte-size{
    font-size: 2vh;
  }
    
    
 }
@media (min-width: 1905px)  { 
  
.margin-est{
    margin: 70px 0px 70px 0px
  }
  .text-tilte-size{
    font-size: 7vh;
  }
.text-subtilte-size{
    font-size: 2.2vh;
  }

}


</style>
