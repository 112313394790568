<template>
  <Navber />
  <Sucre v-if="$route.path === '/sucre/bienvenido/' || '/sucre/bienvenido'" />
  <router-view></router-view>
</template>
<script>

import { defineComponent } from 'vue';

// Components
import Sucre from '../components/CcaSucre/Main.vue';
// import Navber from '@/components/CcaSucre/Navbar/Navbar.vue';
// import Mapa from '@/components/CcaSucre/Mapa/Mapa.vue';
// import Logos from '@/components/CcaSucre/Logos/Logos.vue';
// import Informacion from '@/components/CcaSucre/Informacion/Informacion.vue';
export default defineComponent({
  name: 'BienvenidoSucre',

  components: {
    Sucre,
    // Navber,
    // Mapa,
    // Logos,
    // Informacion,
  },
});
</script>
