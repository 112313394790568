<template>
  <div class="margin-est background-1e2127">
    <!--<h1 class="text-LiberationSans-Bold text-center text-tam"> NUESTRA CORPORACIÓN</h1>-->
      <v-row justify="center">
        <v-col
          cols="12"
          sm = "10"
          md = "8"
          lg = "8"
          xl = "8" class="margin-row">
            <v-card-title class="text-white text-LiberationSans-Regula text-size">  
                <v-icon  class="text-white margin-icon" >mdi-map-marker-outline</v-icon>
                    Calle Madrid Nº 741 entre Ballivian y Ramón Rojas Barrio El Molino Calle Colón Nº 888 esq. Colon
            </v-card-title>
            <v-card-title class="text-white text-LiberationSans-Regula text-size">            
                <v-icon  class="text-white margin-icon" >mdi-email-send-outline</v-icon>
                gestion@cca.edu.bo
            </v-card-title>
            <v-card-title class="text-white text-LiberationSans-Regula text-size">            
                <v-icon  class="text-white margin-icon" >mdi-email-send-outline</v-icon>
                eyujra@cca.edu.bo
            </v-card-title>
            <v-card-title class="text-white text-LiberationSans-Regula text-size">                
                <v-icon  class="text-white margin-icon" >mdi-phone-outline</v-icon>
                +59171864008
            </v-card-title>
        </v-col>
      </v-row>
  </div>
</template>

<script>



export default {
  name: 'CcaInformacion',
  props: {
  },
  components : {
  },
  data(){
    return {
    };
  },
 methods: {
   logohref(href){
    window.open(href);
   }
 },
  mounted(){
  }

      

}
</script>

<style scoped>
.cursor{
 cursor: pointer;
    color: inherit;
}
.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.background-ffcf00{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
}
.text-whit{
  color: white;
}
.text-ffcf00{
  color: #ffcf00;
}

.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.background-1e2127{
    background: #1e2127;
}
.background-logos{
    background: linear-gradient(180deg, #004c8c, #035aa3, #097add);
}
.border-rad{
    
    border-radius: 9999px !important;
}
.margin-est{
    margin: 0px 0px 0px 0px
  }
  .margin-icon{
    margin: 0px 5px 0px 0px;
  }
  .margin-row{
    
    margin: 50px 0px 50px 0px;
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
 .text-size{ 
    font-size: 1.8vw;
  }
  .margin-row{
    
    margin: 20px 0px 20px 0px;
  }
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){  
      .margin-row{
    
    margin: 30px 0px 30px 0px;
  }
 .text-size{ 
    font-size: 1.6vw;
  } 

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
 .text-size{ 
    font-size: 1.3vw;
  } 
    

  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
 .text-size{ 
    font-size: 1.3vw;
  } 
 }
@media (min-width: 1905px)  { 
 .text-size{ 
    font-size: 1vw;
  } 

}


</style>
