<template>
  <Tarija />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import Tarija from '../components/CcaTarija/Main.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    Tarija,
  },
});
</script>
