<template>
  <div class="section-footer-sucre">
    <v-row justify="center">
      <v-col cols="10">
        <v-row justify="center">
          <v-col cols="12" sm="6" md="4" class="column">
            <v-img width="150" src="../../../assets/Logos/logo-fondo-azul.png"></v-img>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="column">
            <v-card-title class="title-footer text-white">
              Contactos
            </v-card-title>
            <template v-for="(rsocial, index) in rsocials" v-bind:key="index">
              <div class="d-flex mb-2  px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
                <v-icon class="text-white" :icon="'mdi-' + rsocial.logo"></v-icon><span class="text-white pl-2"
                  v-on:click="href(rsocial.enlace)">{{ rsocial.nombre
                  }}</span>
              </div>
            </template>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="column">
            <v-card-title class="title-footer text-white">
              Oferta Academica
            </v-card-title>
            <div class="d-flex mb-2 px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
              <span class="text-white pr-3">></span>
              <span class="menu text-white" @click="changePath('CcaSucreCarrera')">Carreras</span>
            </div>
            <div class="d-flex mb-2 px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
              <span class="text-white pr-3">></span>
              <span class="menu text-white"  @click="changePath('CcaSucreCurso', 2, 'Capacitación')">Capacitación</span>
            </div>
            <div class="d-flex mb-2 px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
              <span class="text-white pr-3">></span>
              <span class="menu text-white" @click="changePath('CcaSucreCurso', 3, 'Formación Continua')">Formación Continua</span>
            </div>
            <div class="d-flex mb-2 px-10 px-sm-10 px-md-0 px-lg-0 px-xl-0">
              <span class="text-white pr-3">></span>
              <span class="menu text-white"  @click="changePath('CcaSucreCurso', 4, 'Preuniversitarios')    ">Pre Universitarios</span>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="text-white mt-5 mb-5"><small>Copyright &copy; {{ new Date().getFullYear() }}
        cca.edu.bo</small></v-row>
  </div>
</template>

<script>
import usePeticion from '@/assets/helpers/Peticion';
export default {
  name: 'CcaSucreInformacion',
  props: {
  },
  components: {
  },
  data() {
    return {
      rsocials: null
    };
  },
  methods: {

    href(href) {
      window.open(href);
    },
    changePath(namePath, id_servicio, servicio) {
      window.scrollTo(0, 0);
      this.$router.push({ name: namePath, params: { id: id_servicio, servicio: servicio } });
    },

  },
  async created() {
    const { fetchData } = usePeticion('redessociales');
    try {
      this.rsocials = await fetchData();
    } catch (error) {
      console.error('Error:', error);
    }
  },




}
</script>

<style scoped>
.cursor {
  cursor: pointer;
  color: inherit;
}

.cursor:hover {
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  color: #004c8c;
  transform: translateY(-7px);
}

.asesor {
  cursor: pointer;
  color: inherit;
}

.asesor span:hover {
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  color: #004c8c;
  transform: translateY(-7px);
}

.text-LiberationSans-Boldy {
  font-family: "LiberationSans-Bold";
}

.text-LiberationSans-Regular {
  font-family: "LiberationSans-Regular";
}

.text-Montserrat-Black {
  font-family: "Montserrat-Black";
}

.text-Montserrat-Regular {
  font-family: "Montserrat-Regular";
}

.text-Montserrat-BlackItalic {
  font-family: "Montserrat-BlackItalic";
}

.background-ffcf00 {
  background: #ffcf00
}

.background-0d8dff {
  background: #0d8dff
}

.text-whit {
  color: white;
}

.text-ffcf00 {
  color: #ffcf00;
}

.text-004c8c {
  color: #004c8c;
}

.background-d2d4da {
  background: #d2d4da
}

.background-navbar {
  background: linear-gradient(90deg, #004c8c, #0d8dff);
}

.background-1e2127 {
  background: #1e2127;
}

.background-logos {
  background: linear-gradient(180deg, #004c8c, #035aa3, #097add);
}

.border-rad {

  border-radius: 9999px !important;
}

.margin-est {
  margin: 0px 0px 0px 0px
}

.margin-icon {
  margin: 0px 5px 0px 0px;
}

.margin-row {

  margin: 50px 0px 50px 0px;
}

.padding-final {
  padding: 1px;
  ;
}

/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) {
  .text-size {
    font-size: 6vw;
  }

  .margin-row {

    margin: 20px 0px 20px 0px;
  }

  /* Fin de nemu*/
}

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) {
  .margin-row {

    margin: 30px 0px 30px 0px;
  }

  .text-size {
    font-size: 2.5vw;
  }

}

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) {
  .text-size {
    font-size: 1.5vw;
  }


}

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) {
  .text-size {
    font-size: 1.5vw;
  }
}

@media (min-width: 1905px) {
  .text-size {
    font-size: 1.5vw;
  }

}
</style>
