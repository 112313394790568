<template>
<div> 
  <div class="text-center pt-4">
    <span class="mb-6 text-center text-Montserrat-Medium text-size-title text-white"> NUESTRAS</span>
    <span class="mb-6 text-center text-Montserrat-Black text-size-title text-ffcf00"> SEDES</span>
  </div>
    <v-row justify="center" class="pb-3">
      <v-col
        cols="10" 
        sm="10"
        md="8"
        lg="8"
        xl="8"
      > 
      <v-row>
        <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
        xl="6">              
        <div class="img-contedor">
          <v-img
            src="../../../assets/sucre.png"
            width="100%"
            class="img"
            @click="selectSede('HomeView')"
          >
          <div class="d-flex height-100" >
            <p class="align-self-center text-ffcf00 text-Montserrat-Black pl-10 text-size-sede" >
              SUCRE
            </p>
          </div>
          </v-img>
        </div>
        </v-col>
        <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
        xl="6">              
        <div class="img-contedor">
          <v-img
            src="../../../assets/tarija.png"
            width="100%"
            class="img"
            @click="selectSede('Tarija')"
          >
          <div class="d-flex height-100" >
            <p class="align-self-center text-ffcf00 text-Montserrat-Black pl-10 text-size-sede">
              TARIJA
            </p>
          </div>
          </v-img>
        </div>
        </v-col>              
      </v-row>      
      </v-col>
    </v-row>
  </div>
</template>

<script>
//import Editor from '@tinymce/tinymce-vue'
import axios from 'axios'
var url ='backend-ccasucre/crud-servicio.php';
export default {
  name: 'CcaSucreServicios',
  props: {
  },
  components : {
  },
 methods: {
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1})
      .then(Response =>{
        this.servicios=Response.data
      }) 
    },
    
    selectSede(sede){
      window.scrollTo(0, 0);
      this.$router.push({ name: sede });
    },
    
    ViewCarrera(carrera1){
      console.log(carrera1);
      if(carrera1.id==1){
        this.enviarCarrera(carrera1.id);
      }else{
        this.enviarCurso(carrera1.id);
      }
    },
    enviarCarrera(id){
     this.$emit("servicios_id",id),
     this.$emit("quienesSomos",false)
     this.$emit("carrera",true),
     this.$emit("curso",false),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
    },
  enviarCurso(id){
     this.$emit("servicios_id",id),
     this.$emit("quienesSomos",false),
     this.$emit("carrera",false),
     this.$emit("curso",true),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
    },
   
 },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 2;
      }
      if (screen.width > 960 && screen.width <= 1264){
        return 4;
      }

      if (screen.width > 1264) {
        return 4;
      }

      return 1;
    },
        
    col_tam(){
      
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 6;
      }
      if (screen.width > 960 && screen.width <= 1264){
        return 3;
      }

      if (screen.width > 1264) {
        return 3;
      }

      return 1;
    },
    show_arrows(){
      
      if (screen.width > 960 ) {
        return false;
      }
      return true;
    },
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  data(){
    return {
      //baseUrl : "http://localhost/",
      baseUrl : "https://cca.edu.bo/",
       baseImagen : "img-ccasucre/img-servicio/",
      servicios : [],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      dialog : false,
      dialog_info : false,
      carrera : {
        id: null,
        nombre : "",
        areas : "",
        descripcion  : "",
        campo_laboral  : "",
        duracion_semestre : "",
        duracion_anio  : "",
        horario  : "",
        modalidad   : "",
        requisito  : "",
        imagen : "",
        estado : ""
      },
    };
  },
  mounted(){
      this.mostrar();
    }

      

}
</script>

<style scoped>
.padding-0{
    padding-top: 0px;
}
.text-Arial-Bold{
  font-family: "Arial-Bold";
}
.text-Montserrat-SemiBold{
  font-family: "Montserrat-SemiBold";
}
.text-6911b1{
  color: #6911b1;
}
.text-Poppins-Regular{
  font-family: "Poppins-Regular";
}
.text-LiberationSans-Bold{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Bold{
  font-family: "Montserrat-Bold";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-Medium{
  font-family: "Montserrat-Medium";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.text-whit{
  color: white;
}
.text-ffcf00{
  color : #ffcf00
}
.background-ffcf00{
  background : #ffcf00
}
.text-004c8c{
  color: #004c8c;
}

.background-0d8dff{
   background: #0d8dff
}
.background-ffbe0d{
   background: #ffbe0d
}
.background-navbar{
    background: linear-gradient(180deg, #0084ff, #00398c);
}
.border-rad{
    
    border-radius: 9999px !important;
}

.margin-carrera{
    margin: 20px 0px 10px 0px
  }
  .background-d2d4da{
   background: #d2d4da
}
.padding-servicio{
  padding: 2% 0% 2% 5%;
}
  .img{
 cursor: pointer;
  color: inherit;
}

.img-contedor:hover .img {
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
  }
.img-contedor {
  overflow:hidden;
  }
  .height-100{
    height: 100%;
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
.text-size-title{
  font-size: 6vw;
}
.text-size-sede{
  font-size: 4vw;
}
.text-size-icon{
  font-size: 10vw;
}
.text-size-carrera{
  font-size: 5vw;
}
.text-size-bn{
  font-size: 4vw;
}

  .padding-servicio{
  padding: 2% 0% 2% 5%;
}
  .dialog_tan{
   width : 300px
 }
 
  .text-tam{ 
    font-size: 6vw;
  }
  
.size-text-area{ 
    font-size: 5.2vw;
  }  
.text-bn-tam{
    font-size: 5.5vw;
} 
.text-title-tam{
    font-size: 5.2vw;
}  
.padding-text-area{
    padding-top: 6%;
} 
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){ 
  .text-size-title{
  font-size: 6vw;
}
.text-size-sede{
  font-size: 4vw;
}
.text-size-icon{
  font-size: 8vw;
}
.text-size-carrera{
  font-size: 4vw;
}
.text-size-bn{
  font-size: 3vw;
}  
  .padding-servicio{
  padding: 2% 0% 2% 5%;
}
.dialog_tan{
   width : 601px
 }
 
  .text-tam{ 
    font-size: 4vw;
  }
  
.text-bn-tam{
    font-size: 1.8vw;
} 
.text-title-tam{
    font-size: 1.2vw;
}  
.size-text-area{ 
    font-size: 2vw;
  } 
.padding-text-area{
    padding-top: 5%;
} 
}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
  .text-size-title{
  font-size: 5vw;
}
.text-size-sede{
  font-size: 3vw;
}
.text-size-icon{
  font-size: 8vw;
}
.text-size-carrera{
  font-size: 2vw;
}
.text-size-bn{
  font-size: 1.5vw;
}  
   .dialog_tan{
   width : 930px  
   }
    
  .text-tam{ 
    font-size: 3vw;
  }
  .text-bn-tam{
    font-size: 1.8vw;
} 
.text-title-tam{
    font-size: 1.4vw;
} 
.size-text-area{ 
    font-size: 2.2vw;
  } 

.padding-text-area{
    padding-top: 5%;
} 
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  .text-size-title{
  font-size: 4vw;
}
.text-size-sede{
  font-size: 2vw;
}
.text-size-icon{
  font-size: 8vw;
}
.text-size-carrera{
  font-size: 1.5vw;
}
.text-size-bn{
  font-size: 1.5vw;
}  
  .dialog_tan{
   width : 1100px
 }
  
  .text-tam{ 
    font-size: 3vw;
  }
  
  .text-bn-tam{
    font-size: 1.8vw;
} 
.text-title-tam{
    font-size: 1.4vw;
}
.size-text-area{ 
    font-size: 2.2vw;
  } 

.padding-text-area{
    padding-top: 4%;
} 
    
 }
@media (min-width: 1905px)  { 
  .text-size-title{
  font-size: 4vw;
}
.text-size-sede{
  font-size: 2vw;
}
.text-size-icon{
  font-size: 8vw;
}
.text-size-carrera{
  font-size: 1.5vw;
}
.text-size-bn{
  font-size: 1.5vw;
}  
  .dialog_tan{
   width : 1700px
 }
  
  .text-tam{ 
    font-size: 3vw;
  }
    .text-bn-tam{
    font-size: 1.8vw;
} 
.text-title-tam{
    font-size: 1.4vw;
}
.size-text-area{ 
    font-size: 2vw;
  } 

.padding-text-area{
    padding-top: 4%;
} 
}


</style>
