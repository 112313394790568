<template>
  <v-carousel
    height="max-height"
    hide-delimiter-background
    class="text-white "
    hide-delimiters
  >    
    <template v-slot:prev="{ props }">
        <v-btn
            variant="outlined"
            class="text-transparent"
            icon
            @click="props.onClick"
        ><v-icon class="text-whit text-booton-tam">mdi-chevron-left</v-icon></v-btn>
        </template>
        <template v-slot:next="{ props }">
        <v-btn
            variant="outlined"
            class="text-transparent"
            icon
            @click="props.onClick"
        ><v-icon class="text-whit text-booton-tam">mdi-chevron-right</v-icon></v-btn>
        </template> 
        
    <v-carousel-item
      src="../../../assets/baner.png"
    >
    </v-carousel-item>
  </v-carousel> 
</template>

<script>

import axios from 'axios'
var url ='backend-ccatarija/crud-slider.php';
var url_pagina ='backend-ccatarija/crud-pagina.php';


export default {
  name: 'CcaSucreCarousel',
  props: {
  },
  components : {
  },
 methods: {
   
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1})
      .then(Response =>{
        this.sliders=Response.data
      })
    },
    mostrar_pagina(){
      axios.post(this.baseUrl+''+url_pagina,{opcion:6})
      .then(Response =>{
        this.paginas=Response.data
      })
    },
 },
  data(){
    return {
        sliders : [],
        paginas : [],
        //baseUrl : "http://localhost/",
        baseUrl : "https://cca.edu.bo/",
        baseImagen : "img-ccatarija/img-carousel/",
    };
  },
  computed : {
    
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "x-small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

<style scoped>

.background-f5c827{
    background: #f5c827
}  
.text-034c96{
  color: #034c96;
}
.img-logo{
  height: 50px;
  width: 50px;
}
.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.ffcf00-background{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
}
.background-0037c1{
   background: #0037c1
}
.text-0037c1{
  color : #0037c1;
}
.text-whit{
  color: white;
}
.background-white{
    background: white;
} 
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.background-instragram{
    background: linear-gradient(25deg, #6c48e0,#db3b8d, #fbd26f);
}
.background-8f39ce{
  background: #8f39ce
}
.text-8f39ce{
  color: #8f39ce;
}
.background-ff0000{
  background: #ff0000
}
.background-57dc70{
  background: #57dc70
}
.background-080011{
  background: #080011
}

.text-booton-tam{
    font-size: 4.5vw
  }
  .margin-booton{
    margin: 0px 10px 0px 10px ;
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  
.img-logo{
  height: 30px;
  width: 30px;
}
 .icon-size{
    font-size: 15px;
  }
  .icon-size1{
    font-size: 28px;
  }
  .margin-booton{
    margin: 0px 5px 0px 5px ;
  }
  .text-booton-tam{
    font-size: 6vw
  }
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 3%;
    } 
    .padding-bn{        
          left: 2%;
    right: 0%;
    bottom: 0%;
    top: 75%;
    position: absolute;
    }
    .text-titulo-tam{
        font-size : 2vw;
    }
    .text-descrip-tam{
        font-size : 1.5vw;
    }
    .text-bn-tam{
        font-size : 2vw;
    }
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
  .img-logo{
  height: 35px;
  width: 35px;
}
 .icon-size{
    font-size: 20px;
  }
  .icon-size1{
    font-size: 38px;
  }
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 5%;
    } 
    .margin-booton{
    margin: 0px 5px 0px 5px ;
  }
    .padding-bn{      
      left: 2%;
    right: 0%;
    bottom: 0%;
    top: 85%;
    position: absolute;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    } 

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
 .icon-size{
    font-size: 28px;
  }
  .icon-size1{
    font-size: 48px;
  }
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 5%;
    } 
    .margin-booton{
    margin: 0px 5px 0px 5px ;
  }
    .padding-bn{      
      left: 2%;
    right: 0%;
    bottom: 0%;
    top: 83%;
    position: absolute;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    }
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 

  .icon-size{
    font-size: 30px;
  }
  .icon-size1{
    font-size: 50px;
  }
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 5%;
    } 
    .padding-bn{      
      left: 2%;
    right: 0%;
    bottom: 0%;
    top: 86%;
    position: absolute;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    }
 }
@media (min-width: 1905px)  { 
      .icon-size{
    font-size: 30px;
  }
  .icon-size1{
    font-size: 57px;
  }
    .padding-text{
        padding-left: 20%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 7%;
    } 
    .padding-bn{      
      left: 2%;
    right: 0%;
    bottom: 0%;
    top: 90%;
    position: absolute;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    }

}


</style>
