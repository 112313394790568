<template>
  <div>    
    <h3 class="text-Arial-Bold text-014c8d py-7  text-center text-size-title">Conoce a Nuestros Docentes</h3>
    <v-carousel
      cycle
      height="max-height"
      hide-delimiters
      class="text-014c8d"
    >
    <template v-slot:prev="{ props }">
        <v-btn
          variant="outlined"
          class="text-transparent"
          icon
          @click="props.onClick"
        ><v-icon class="text-014c8d text-size-icon">mdi-chevron-double-left</v-icon></v-btn>
      </template>
      <template v-slot:next="{ props }">
        <v-btn
          variant="outlined"
          class="text-transparent"
          icon
          @click="props.onClick"
        ><v-icon class="text-014c8d text-size-icon">mdi-chevron-double-right</v-icon></v-btn>
      </template>   
    <v-row justify="center">
      <v-col
          cols="8" 
          sm="9"
          md="10"
          lg="10"
          xl="10"
      >    
        <template 
            v-if="docentes.length>0">
          <v-carousel-item  
            v-for="(j, i) in 
            (Math.ceil(docentes.length/columns))"
            v-bind:key="i"
          >
          
              <v-row > 
                <template
                v-for="(docente, index) in docentes" v-bind:key="index" >
                <v-col  
                  :cols="col_tam"
                  v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns"
                >
                
                <div v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns">
                  <v-card                    
                    class=" mb-6"
                    color="transparent"
                    max-width="350"
                  >                      
                    <v-img
                        :src="baseUrl+''+baseImagen+''+docente.imagen"
                        width="100%"
                        
                        aspect-ratio="1"
                        class="pa-0 ma-0 rounded-t-xl"
                    >
                    </v-img>
                    <div class="background-014c8d mb-2 pa-3">
                      <h3 class="text-center text-ffd000">{{docente.cargo}}</h3>
                      <div class="d-flex">
                        <h3 class="text-center text-ffd000 mr-auto">{{docente.nombres}} {{docente.apellido}} </h3>
                        <v-icon icon="mdi-whatsapp" class="text-white" v-on:click="href('http://wa.me/591'+docente.celular)"  ></v-icon>
                        <v-icon icon="mdi-email" class="text-white email"></v-icon>
                      </div>
                    </div>
                  </v-card>
                  </div>
                </v-col>
                </template>
              </v-row>
          </v-carousel-item>
        </template>
      </v-col>
    </v-row>           
    </v-carousel>
  </div>

</template>

<script>

//import { Carousel, Slide, Navigation } from 'vue3-carousel';
import axios from 'axios'
var url ='backend-ccasucre/crud-persona.php';

export default {
  name: 'CcaSucreDocente',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
  },
 methods: {
   href(href){
    window.open(href);
   }, 
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:6,cargo_id:2})
      .then(Response =>{
        this.docentes=Response.data
      })
    }
 },
  data(){
    return {
      //baseUrl : "http://localhost/",
      baseUrl : "https://cca.edu.bo/",
      baseVideo : "https://www.youtube.com/embed/",
      baseImagen : "img-ccasucre/img-persona/",
      docentes : {},
    }
  },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 2;
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 3;
      }
      if (screen.width > 1264) {
        return 4;
      }

      return 1;
    },
    col_tam() {
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 6;
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 4;
      }
      if (screen.width > 1264) {
        return 3;
      }

      return 1;
    }
  },
  mounted(){
      this.mostrar();
    }

      

}
</script>

<style scoped>
.v-card--variant-contained {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%);
}
.text-keep-calm-font{
  font-family: "keep-calm-font";
}
.text-MontserratAlternates-Regular{
  font-family: "MontserratAlternates-Regular";
}
.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.text-Arial-Bold{
  font-family: "Arial-Bold";
}
.margin-0{
  margin: 0px;
}

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.border-001C29{
  border: #001C29;
  border-style: solid;
  border-color: green;
  border-width: 10px;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}
.background-0d8dff{
  background-color: #0d8dff;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.background-014c8d {
  background-color: #014c8d;

}
.text-ffd000{
  color : #ffd000;
}
.text-014c8d{
  color : #014c8d;
}
.padding-next{
  
    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
.padding-prev{

    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
.height-div{
  height: 50px;
}
.whatsapp{
 cursor: pointer;
}
.whatsapp:hover{
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  color: #25D366;
	transform: translateY(-7px);
}

.email{
 cursor: pointer;
}
.email:hover{
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  color: #8f39ce;
	transform: translateY(-7px);
}
@media (min-width: 0px) and (max-width: 600px) { 
  .text-size-icon{
    font-size: 15vw;
  }
  .text-size-title{
    font-size: 8vw;
  }
 .video-width{
    width: 210px;
  }
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
   /** sive todo */
   .text-size-icon{
    font-size: 10vw;
  }
  .video-width{
    width: 180px;
  }
  .text-size-title{
    font-size: 5vw;
  }
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
   .text-size-icon{
    font-size: 8vw;
  }
  .text-size-title{
    font-size: 4vw;
  }
.video-width{
    width: 200px;
  }
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
     .text-size-icon{
    font-size: 6vw;
  }
  .video-width{
    width: 240px;
  }
  .text-size-title{
    font-size: 3vw;
  }

 }
@media (min-width: 1905px)  { 
 
     .text-size-icon{
    font-size: 4vw;
  }
  .video-width{
    width: 300px;
    height: 200px;
  }
  .text-size-title{
    font-size: 2.5vw;
  }
 }

</style>
