<template>
<div>
  <v-row v-if="View">
    <v-col cols="12">
      <div class="background-navbar">
        <p class="text-center text-Montserrat-Black text-size-carrera  text-ffcf00 py-10">NUESTRAS  CARRERAS</p>  
      </div>
    </v-col>
    <v-col cols="12" class="pa-0" v-if="View">
      <Separador :id="1" />
    </v-col>
  </v-row>
    <h1 class="text-center text-Montserrat-Black text-black margin-carrera text-tam mt-10" v-if="View">SEDE DE SUCRE</h1>
    <div v-if="View">
      <CarreraSucre :servicios_id="servicios_id" @carrera_id="carrera_id=$event"  @ViewCarreraTarija="ViewCarreraTarija=$event" @ViewCarreraSucre="ViewCarreraSucre=$event"/>
    </div>
    <v-row v-if="View">
      <v-col cols="7">
        <Separador :id="1" />
      </v-col>
    </v-row>   
    <div v-if="ViewCarreraSucre" class="mb-5">
      <CarreraViewSucre :carrera_id="carrera_id"/>
    </div>
    <h1 class="text-center text-Montserrat-Black text-black margin-carrera text-tam mt-10" v-if="View">SEDE DE TARIJA</h1>
    <div v-if="View">
      <CarreraTarija :servicios_id="servicios_id"  @carrera_id="carrera_id=$event"  @ViewCarreraTarija="ViewCarreraTarija=$event" @ViewCarreraSucre="ViewCarreraSucre=$event"/>
    </div>
    <v-row justify="end" v-if="View">
      <v-col cols="7">
        <Separador :id="1" />
      </v-col>
    </v-row>    
    <div v-if="ViewCarreraTarija" class="mb-5">
      <CarreraViewTarija :carrera_id="carrera_id"/>
    </div>
</div>
</template>

<script>

import CarreraSucre from '@/components/Principal/Carrera/CarreraSucre.vue';
import CarreraTarija from '@/components/Principal/Carrera/CarreraTarija.vue';
import CarreraViewSucre from '@/components/Principal/Carrera/CarreraViewSucre.vue';
import CarreraViewTarija from '@/components/Principal/Carrera/CarreraViewTarija.vue';
import Separador from '@/components/CcaSucre/Separador/Separador.vue';
import axios from 'axios'
var url ='backend-ccatarija/crud-slider.php';
var url_pagina ='backend-ccatarija/crud-pagina.php';


export default {
  name: 'CcaSucreInstitutoCca',
  props: {
  },
  components : {
    CarreraSucre,
    CarreraTarija,
    Separador,
    CarreraViewSucre,
    CarreraViewTarija,
  },
 methods: {
   
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1})
      .then(Response =>{
        this.sliders=Response.data
      })
    },
    mostrar_pagina(){
      axios.post(this.baseUrl+''+url_pagina,{opcion:6})
      .then(Response =>{
        this.paginas=Response.data
      })
    },
 },
  data(){
    return {
        sliders : [],
        paginas : [],
        //baseUrl : "http://localhost/",
        baseUrl : "https://cca.edu.bo/",
        baseImagen : "img-ccatarija/img-carousel/",
      baseVideo : "https://www.youtube.com/embed/",
      servicios_id : 1,
      carrera_id : 0,
      ViewCarreraTarija : false,
      ViewCarreraSucre : false,
    };
  },
  computed : {        
    
    View(){
    if ( this.ViewCarreraTarija || this.ViewCarreraSucre  ) {
        return false;
      }
      return true
    },
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

<style scoped>

.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.text-ffcf00{
  color : #ffcf00
}
.ffcf00-background{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
}
.background-d2d4da{
   background: #d2d4da
}
.text-whit{
  color: white;
}
.background-navbar{
    background: linear-gradient(180deg, #0084ff, #00398c);
}
.background-instituto{
    background: linear-gradient(90deg, #004c8c, #1496d9);
}
.margin-i{
  margin: 0px 0px 50px 0px;
}
.margin-row{
  margin: 50px 0px 50px 0px;
}
.text-booton-tam{
    font-size: 4.5vw
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  
  .text-size-carrera{
    font-size: 5.2vw;
  }   
  .text-size-title{
    font-size: 8vw;
  }
  .text-booton-tam{
    font-size: 6vw
  }
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 3%;
    } 
    .padding-bn{    
        padding-left: 15%;
        padding-right: 1%;
        padding-bottom: 0%;
        padding-top: 2%;
    }
    .text-titulo-tam{
        font-size : 2vw;
    }
    .text-descrip-tam{
        font-size : 1.5vw;
    }
    .text-bn-tam{
        font-size : 2vw;
    }
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){ 
  .text-size-carrera{
    font-size: 5.5vw;
  }  
    .text-size-title{
    font-size: 4.5vw;
  }  
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 5%;
    } 
    .padding-bn{    
        padding-left: 15%;
        padding-right: 1%;
        padding-bottom: 0%;
        padding-top: 2%;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    } 

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
  .text-size-carrera{
    font-size: 5.5vw;
  }  
  .text-size-title{
    font-size: 5vw;
  }
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 5%;
    } 
    .padding-bn{    
        padding-left: 15%;
        padding-right: 1%;
        padding-bottom: 0%;
        padding-top: 2%;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    }
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  .text-size-carrera{
    font-size: 5.5vw;
  }  
  
  .text-size-title{
    font-size: 4vw;
  }
    .padding-text{
        padding-left: 10%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 5%;
    } 
    .padding-bn{    
        padding-left: 15%;
        padding-right: 1%;
        padding-bottom: 0%;
        padding-top: 2%;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    }
 }
@media (min-width: 1905px)  { 
  .text-size-carrera{
    font-size: 5.5vw;
  }  
     .text-size-title{
    font-size: 3vw;
  } 
    .padding-text{
        padding-left: 20%;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 7%;
    } 
    .padding-bn{    
        padding-left: 25%;
        padding-right: 1%;
        padding-bottom: 0%;
        padding-top: 2%;
    }
    .text-titulo-tam{
        font-size : 1.7vw;
    }
    .text-descrip-tam{
        font-size : 1vw;
    }
    .text-bn-tam{
        font-size : 1vw;
    }

}


</style>
