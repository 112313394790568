<template>
  <Navber />
  <Sucre v-if="$route.path === '/sucre/bienvenido'" />
  <router-view></router-view>
  <Logos />
  <!-- <h1>{{$route.path}}</h1> -->
  <Mapa />
  <Informacion />
</template>
<script>

import { defineComponent } from 'vue';

// Components
import Sucre from '../components/CcaSucre/Main.vue';
import Navber from '@/components/CcaSucre/Navbar/Navbar.vue';
import Mapa from '@/components/CcaSucre/Mapa/Mapa.vue';
import Logos from '@/components/CcaSucre/Logos/Logos.vue';
import Informacion from '@/components/CcaSucre/Informacion/Informacion.vue';
export default defineComponent({
  name: 'HomeView',

  components: {
    Sucre,
    Navber,
    Mapa,
    Logos,
    Informacion,
  },
});
</script>
