<template>
  <div class="margin-est">
    <h1 class="text-Poppins-Medium text-center margin-est text-title1-size"> INICIA TU PROCESO DE INSCRIPCIÓN</h1>
      <v-row justify="center">
        <v-col 
          cols="12"
          sm = "10"
          md = "8"
          lg = "8"
          xl = "8">
          <v-row justify="center">
            <v-col 
              cols="12"
              sm = "12"
              md = "4"
              lg = "4"
              xl = "4">
              <div class="padding-text">
                <div class="d-flex justify-center">
                  <v-card-subtitle class="text-myriadpro-regular text-center text-subtitle-size">
                    COMUNICATE CON UN
                  </v-card-subtitle>
                </div>
                <div class="d-flex justify-center">
                  <v-card-title class="text-Poppins-Medium text-center text-title-size">
                    ASESOR
                  </v-card-title>
                </div>
                <div class="d-flex justify-center">
                  <v-btn class="background-whatsapp text-white text-Montserrat-Bold mb-6 text-bn-size " 
                    v-on:click="href('https://wa.link/azodin')" 
                    rounded="pill">
                    <v-icon  class="text-white" >mdi-whatsapp</v-icon>
                      Whatsapp
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col 
              cols="6"
              sm = "6"
              md = "4"
              lg = "4"
              xl = "4">
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                  <v-text-field
                  variant="outlined"
                  v-model="nombre_completo"
                  bg-color="white"
                  :rules="nameRules"
                  label="Nombre Completo*"
                  required
                ></v-text-field>
                  <v-text-field
                  variant="outlined"
                  v-model="celular"
                  bg-color="white"
                  :rules="nameRules"
                  label="Celular*"
                  required
                ></v-text-field>
                <v-text-field
                  variant="outlined"
                  v-model="correo"
                  :rules="emailRules"
                  label="Email"
                  required
                ></v-text-field>
                
                <v-btn class="background-004c8c text-white text-Poppins-SemiBold mb-6 text-bn1-size " block  @click="crear" :disabled="!valid">
                    ENVIAR
                </v-btn>
              </v-form>
              <div class="text-center">
                  <v-snackbar
                    v-model="snackbar"
                    :timeout="timeout"
                  >
                    <v-alert 
                    v-model="snackbar"
                    :timeout="timeout" type="success">I'm a success alert.</v-alert>
                  </v-snackbar>
              </div>
            </v-col>
            <v-col 
              cols="6"
              sm = "6"
              md = "4"
              lg = "4"
              xl = "4">
              
                <v-textarea
                  variant="outlined"
                  v-model="descripcion"
                  filled
                  auto-grow
                  label="Agregar tu mensaje*"
                  rows="11"
                  row-height="30"
                  shaped
                ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
  </div>
</template>

<script>

import axios from 'axios'
var url_inscripcion ='backend-ccatarija/crud-inscripcion.php';


export default {
  name: 'CcaContactenos',
  props: {
  },
  components : {
  },
 methods: {
    crear (){
      axios.post(this.baseUrl+''+url_inscripcion, {opcion:2, nombre_completo:this.nombre_completo, descripcion  :this.descripcion  , celular  :this.celular  , 
                        correo :this.correo })
        .then(response =>{
          response;
        });  
        
      this.nombre_completo = "";
      this.descripcion   = "";
      this.celular  = "";
      this.correo  = "";
      
   this.snackbar = true;
    },
    
   href(href){
    window.open(href);
   },
 },
  data(){
    return {
      valid: true,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      nombre_completo : "",
      celular  : "",
      descripcion  : "",
      correo  : "",
      snackbar: false,
      text: 'My timeout is set to 2000.',
      timeout: 2000,
        //baseUrl : "http://localhost/",
        baseUrl : "https://cca.edu.bo/",
    };
  },
  mounted(){
    }

      

}
</script>

<style scoped>

.text-myriadpro-regular{
  font-family: "myriadpro-regular";
}
.text-Poppins-SemiBold{
  font-family: "Poppins-SemiBold";
}
.text-Poppins-Medium{
  font-family: "Poppins-Medium";
}
.text-LiberationSans-Bold{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.text-Montserrat-Bold{
  font-family: "Montserrat-Bold";
}

.background-ffcf00{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
}
.text-whit{
  color: white;
}
.text-e5e5e5{
  color: #e5e5e5;
}
.text-ffcf00{
  color: #ffcf00;
}

.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.background-whatsapp{
    background: linear-gradient(90deg, #0b9c0e, #40d000);
}
.background-004c8c{
    background: #004c8c;
}
.background-white{
    background: white;
}
.background-383838{
  background: #383838;
}
.border-rad{
    
    border-radius: 9999px !important;
}
.margin-0{
  margin : 0px
}
.margin-est{
    margin: 20px 0px 20px 0px
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  
    .padding-text{
        padding-top: 0%;
    } 
    .text-subtitle-size{
    font-size: 5vw;
  }
  .text-title1-size{
    font-size: 6vw;
  }
  .text-title-size{
    font-size: 10vw;
  }
  .text-bn-size{
    font-size: 6vw;
  }
  .text-bn1-size{
    font-size: 6vw;
  }
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
  
    .padding-text{
        padding-top: 0%;
    } 
    .text-subtitle-size{
    font-size: 3vw;
  }
  .text-title1-size{
    font-size: 4vw;
  }
  .text-title-size{
    font-size: 7vw;
  }
  .text-bn-size{
    font-size: 3vw;
  }
  .text-bn1-size{
    font-size: 3.5vw;
  }

}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
  
    .padding-text{
        padding-top: 45%;
    } 
    .text-subtitle-size{
    font-size: 1.5vw;
  }
  .text-title1-size{
    font-size: 2vw;
  }
  .text-title-size{
    font-size: 3vw;
  }
  .text-bn-size{
    font-size: 1vw;
  }
  .text-bn1-size{
    font-size: 1.5vw;
  }
    
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  
    .padding-text{
        padding-top: 25%;
    } 
    .text-subtitle-size{
    font-size: 1.2vw;
  }
  .text-title1-size{
    font-size: 2vw;
  }
  .text-title-size{
    font-size: 2.5vw;
  }
  .text-bn-size{
    font-size: 1vw;
  }
  .text-bn1-size{
    font-size: 1.5vw;
  }
    
    
 }
@media (min-width: 1905px)  { 
  
    .padding-text{
        padding-top: 10%;
    } 
    .text-subtitle-size{
    font-size: 1.2vw;
  }
  .text-title1-size{
    font-size: 2vw;
  }
  .text-title-size{
    font-size: 2.5vw;
  }
  .text-bn-size{
    font-size: 0.8vw;
  }
  .text-bn1-size{
    font-size: 1vw;
  }

}


</style>
