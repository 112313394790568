<template>
    <h1 class="text-Poppins-Medium  margin-carrera text-size-title padding-title">Últimas Noticias</h1>
<div>
    <v-row justify="center">
      <v-col 
        cols="12"
        sm = "10"
        md = "10"
        lg = "10"
        xl = "10">
        <v-carousel
            cycle
            :show-arrows="show_arrows"                                                                                                                                                                     
            height="max-height"
            hide-delimiters
          >
          
          <template v-slot:prev="{ props }">
              <v-btn
                variant="outlined"
                class="text-transparent"
                icon
                @click="props.onClick"
              ><v-icon class="text-whit">mdi-chevron-double-left</v-icon></v-btn>
            </template>
            <template v-slot:next="{ props }">
              <v-btn
                variant="outlined"
                class="text-transparent"
                icon
                @click="props.onClick"
              ><v-icon class="text-whit">mdi-chevron-double-right</v-icon></v-btn>
            </template>  
          <v-row justify="center">
            <v-col
            cols="11"
            sm = "10"
            md = "10"
            lg = "10"
            xl = "10"
            >     
            <v-carousel-item  
              v-for="(j, i) in 
              (Math.ceil(noticias.length/columns))"
              v-bind:key="i"
              >
            
                <v-row >
                  <template
                  v-for="(moti, index) in noticias" v-bind:key="index" >
                  <v-col  
                    :cols="col_tam"
                    v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns"
                  >
                  
                  <div v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns">
                      <div
                        class="mx-auto mb-6"
                      >
                      <v-row justify="center">
                        <v-col
                          cols="11"
                        >
                          <v-img
                                :src="baseUrl+''+baseImagen+''+moti.imagen"
                                width="100%"
                                aspect-ratio="1"
                            >
                            </v-img>
                        </v-col>
                      </v-row>
                      <v-card-title class="text-black text-Poppins-Medium  text-size-title1">
                        {{moti.titulo }}
                      </v-card-title>
                      <v-card-subtitle class="text-black text-myriadpro-regular text-justify text-size-subtitle">
                        {{moti.descripcion }}
                      </v-card-subtitle>
                      <v-card-title class=" text-afafaf text-Poppins-Medium text-size-booton cursor" 
                          v-on:click="href(moti.enlace)">
                        Más detalles 
                        <v-icon 
                                class="text-afafaf text-size-booton" >mdi-arrow-right </v-icon>
                      </v-card-title>
                      </div>
                    </div>
                  </v-col>
                  </template>
                </v-row>
                
            </v-carousel-item>
            </v-col>
          </v-row>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
<div class=" div-ta1 background-000000">
</div> 
</template>

<script>
//import Editor from '@tinymce/tinymce-vue'
import axios from 'axios'
var url ='backend-ccatarija/crud-noticia.php';
var url_info ='backend-ccatarija/crud-informacion.php';


export default {
  name: 'CcaNoticias',
  props: {
  },
  components : {
  },
 methods: {
   href(href){
    window.open(href);
   },
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1})
      .then(Response =>{
        this.noticias=Response.data
      })
    },
    formNuevo (carrera){
      this.dialog = false;
      this.dialog_info = true;
      this.operacion='crear';
      this.informacion.nombre  = "";
      this.informacion.apellido = "";
      this.informacion.celular  = "";
      this.informacion.correo  = "";
      this.informacion.curso = carrera.nombre;
    },
    
    crear (){
        
      axios.post(this.baseUrl+''+url_info, {opcion:2, nombre:this.informacion.nombre, apellido :this.informacion.apellido , celular  :this.informacion.celular  , 
                        correo :this.informacion.correo , curso:this.informacion.curso })
        .then(response =>{
          response;
        });  
      this.dialog_info= false;

    },
    ViewCarrera(carrera1){
      this.dialog = true;
      this.carrera.id = carrera1.id;
      this.carrera.nombre = carrera1.nombre;
      this.carrera.descripcion  = carrera1.descripcion;
      this.carrera.campo_laboral  = carrera1.campo_laboral;
      this.carrera.duracion_semestre  = carrera1.duracion_semestre;
      this.carrera.duracion_anio  = carrera1.duracion_anio;
      this.carrera.horario  = carrera1.horario;
      this.carrera.modalidad  = carrera1.modalidad;
      this.carrera.requisito  = carrera1.requisito;

    }
   
 },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 2;
      }

      if (screen.width > 960) {
        return 3;
      }

      return 1;
    },
        
    col_tam(){
      
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 6;
      }

      if (screen.width > 960) {
        return 4;
      }

      return 1;
    },
    show_arrows(){
      
      if (screen.width > 961 ) {
        return false;
      }
      return true;
    },
    height_bootom() {
      if (screen.width > 0 && screen.width <= 600) {
        return "small";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "small";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "large";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "large";
      }

      if (screen.width > 1904) {
        return "x-large";
      }
      return "small";
    },
  },
  data(){
    return {
      x1 : true,
      //baseUrl : "http://localhost/",
      baseUrl : "https://cca.edu.bo/",
       baseImagen : "img-ccatarija/img-noticia/",
      noticias : [],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      dialog : false,
      dialog_info : false,
      carrera : {
        id: null,
        nombre : "",
        areas : "",
        descripcion  : "",
        campo_laboral  : "",
        duracion_semestre : "",
        duracion_anio  : "",
        horario  : "",
        modalidad   : "",
        requisito  : "",
        imagen : "",
        estado : ""
      },
      informacion : {
        id: null,
        nombre : "",
        apellido  : "",
        celular  : "",
        correo   : "",
        curso : "",
      },
    };
  },
  mounted(){
      this.mostrar();
    }

      

}
</script>

<style scoped>
.padding-0{
    padding-top: 0px;
}

.text-myriadpro-regular{
  font-family: "myriadpro-regular";
}
.text-Poppins-Medium{
  font-family: "Poppins-Medium";
}
.text-Poppins-Regular{
  font-family: "Poppins-Regular";
}
.text-LiberationSans-Bold{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.text-whit{
  color: white;
}
.text-ffcf00{
  color : #ffcf00
}
.background-ffcf00{
  background : #ffcf00
}
.text-004c8c{
  color: #004c8c;
}
.text-afafaf{
  color: #afafaf;
}

.background-0d8dff{
   background: #0d8dff
}
.background-ffbe0d{
   background: #ffbe0d
}
.background-000000{
   background: #000000
}
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.border-rad{
    
    border-radius: 9999px !important;
}

.margin-carrera{
    margin: 20px 0px 10px 0px
  }

  .padding-title{
    padding-left: 15%;
  }
  .cursor {
	cursor: pointer;
    color: inherit;
}
.cursor:hover {
	background-color: #0d8dff; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
    
.div-ta1{
  height: 33px;
  margin: 0px;
}
    .padding-title{
    padding-left: 10%;
  }
  .dialog_tan{
   width : 300px
 }
 
  .text-size-title{ 
    font-size: 6vw;
  }
  
.size-text-area{ 
    font-size: 5.2vw;
  }  
.text-bn-tam{
    font-size: 5.5vw;
} 
.text-size-title1{
    font-size: 5.2vw;
}  
.padding-text-area{
    padding-top: 6%;
} 
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
    
.div-ta1{
  height: 38px;
  margin: 0px;
}
.dialog_tan{
   width : 601px
 }
 
  .text-size-title{ 
    font-size: 4vw;
  }
  
.text-bn-tam{
    font-size: 1.8vw;
} 
.text-size-title1{
    font-size: 2vw;
}  
.text-size-booton{
    font-size: 2vw;
}  
.size-text-area{ 
    font-size: 2vw;
  } 
.padding-text-area{
    padding-top: 5%;
} 
}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
   .dialog_tan{
   width : 930px  
   }
   
.div-ta1{
  height: 41px;
  margin: 0px;
}
.text-size-booton{
    font-size: 1.1vw;
}  
  .text-size-title{ 
    font-size: 3vw;
  }
  .text-bn-tam{
    font-size: 1.8vw;
} 
.text-size-title1{
    font-size: 1.2vw;
} 
.size-text-area{ 
    font-size: 2.2vw;
  } 

.padding-text-area{
    padding-top: 5%;
} 
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
  .dialog_tan{
   width : 1100px
 }
 .div-ta1{
  height: 41px;
  margin: 0px;
} 
  .text-size-title{ 
    font-size: 3vw;
  }
  
  .text-bn-tam{
    font-size: 1.8vw;
} 
.text-size-title1{
    font-size: 1.3vw;
}
.text-size-booton{
    font-size: 1.1vw;
}  
.size-text-area{ 
    font-size: 2.2vw;
  } 

.padding-text-area{
    padding-top: 4%;
} 
    
 }
@media (min-width: 1905px)  { 
  .dialog_tan{
   width : 1700px
 }
  
.text-size-booton{
    font-size: 1vw;
} 
  .text-size-title{ 
    font-size: 3vw;
  }
    .text-bn-tam{
    font-size: 1.8vw;
} 
.text-size-title1{
    font-size: 1.4vw;
}
.text-size-subtitle{
    font-size: 1vw;
}
.size-text-area{ 
    font-size: 2vw;
  } 

.padding-text-area{
    padding-top: 4%;
} 
.div-ta1{
  height: 45px;
  margin: 0px;
}
}


</style>
