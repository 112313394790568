<template> 
<div class=" div-ta1 background-004c8c">
</div> 
<div class=" div-ta0 ffcf00-background"></div>
<v-card>
  <v-toolbar
    :height="height_toolbar"
    class="background-ffffff text-black ">
      <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
      <v-img 
        class="img-padding" src="../../../assets/Logos/CCA2.webp"></v-img>
      <v-spacer></v-spacer>
      
      <v-menu v-if="ifnabar" location="start">
        <template v-slot:activator="{ props }">
          
          <v-app-bar-nav-icon variant="text" 
            class="ma-2"
            v-bind="props"></v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item>
            <v-btn variant="text"  v-on:click="changeFocus('quiensomos')" >
              ¿QUIENES  SOMOS?
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn variant="text"  v-on:click="changeFocus('carrera')" >
              CARRERAS TÉCNICAS
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn variant="text"  v-on:click="changeFocus('curso')" >
              CURSOS DE CAPACITACIÓN
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn variant="text"  v-on:click="changeFocus('contacto')" >
              CONTACTO
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
      icon="mdi-home"
      v-on:click=" enviarinicio()"
    ></v-btn>
      <v-btn v-if="!ifnabar" variant="text" class ="text-Poppins-SemiBold text-narbar-tam" v-on:click="changeFocus('quiensomos') ,enviarQuien()">¿QUIENES SOMOS?</v-btn>
      
      <v-menu v-if="!ifnabar" >
        <template v-slot:activator="{ props }">
          <v-btn v-if="!ifnabar" 
            variant="text" 
            class ="text-Poppins-SemiBold text-narbar-tam"
            v-bind="props">OFERTA ACADÉMICA</v-btn>
        </template>
        <v-list >
          <v-list-item class="background-888888">
            <v-btn variant="text"  class="text-Montserrat-Bold text-white" v-on:click="changeFocus('carrera') ,enviarCarrera(1)" >
              CARRERAS TÉCNICAS
            </v-btn>
          </v-list-item>
          <v-list-item class="background-888888 text-Montserrat-Bold">
            <v-btn block variant="text"  class="text-Montserrat-Bold text-white" v-on:click="changeFocus('curso') ,enviarCurso(2)" >
              CURSOS DE  CAPACITACIÓN
            </v-btn>
          </v-list-item>
          <v-list-item class="background-888888">
            <v-btn variant="text"  class="text-Montserrat-Bold text-white" v-on:click="changeFocus('curso') ,enviarCurso(3)">
              CURSOS DE FORMACIÓN <br>CONTINUA
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-if="!ifnabar" variant="text" class ="text-Poppins-SemiBold text-narbar-tam " >PLATAFORMA MOODLE</v-btn>
      <v-menu v-if="!ifnabar" 
            open-on-hover>
        <template v-slot:activator="{ props }">
          <v-btn v-if="!ifnabar" 
            variant="text" 
            class ="text-Poppins-SemiBold text-narbar-tam"
            v-bind="props">SEDE</v-btn>
        </template>
        <v-list>
          <v-list-item class="background-888888 ">
            <v-btn variant="text" class="text-Montserrat-Bold text-white" >
              TARIJA
            </v-btn>
          </v-list-item>
          <v-list-item class="background-888888">
            <v-btn variant="text" class="text-Montserrat-Bold text-white" >
              SUCRE
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-if="!ifnabar" variant="text" class ="text-Poppins-SemiBold text-narbar-tam margin-booton" v-on:click="changeFocus('contacto')">CONTACTO</v-btn>
  </v-toolbar>  
  </v-card>

</template>

<script>


export default {
  name: 'CcaNabar',
  props: {
  },
  components : {
  },
  computed: {
    ifnabar(){
      
      if (screen.width <= 960 ) {
        return true;
      }
      return false;
    },
    height_toolbar() {
      if (screen.width > 0 && screen.width <= 600) {
        return 85;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 100; 
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 110;
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return 110;
      }

      if (screen.width > 1904) {
        return 120;
      }

      return 1;
    },
      
  },
 methods: {
    changeFocus (hash) {
     window.location.hash = hash;
  },
  enviarinicio(){
     this.$emit("quienesSomos",false)
     this.$emit("carrera",false),
     this.$emit("curso",false),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
  },
  enviarCarrera(id_carrera){
     this.$emit("id_carrera",id_carrera),
     this.$emit("servicios_id",id_carrera),
     this.$emit("quienesSomos",false)
     this.$emit("carrera",true),
     this.$emit("curso",false),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
    },
  enviarCurso(id_curso){
     this.$emit("id_curso",id_curso),
     this.$emit("servicios_id",id_curso),
     this.$emit("quienesSomos",false),
     this.$emit("carrera",false),
     this.$emit("curso",true),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
    },
   enviarQuien(){
     this.$emit("quienesSomos",true),
     this.$emit("carrera",false),
     this.$emit("curso",false),
     this.$emit("ViewCurso",false),
     this.$emit("ViewCarrera",false)
    },
   
 },
  data(){
    return {
    };
  },

    watch: {
    },
  mounted(){
    }

      

}
</script>

<style scoped>

.text-Poppins-SemiBold{
  font-family: "Poppins-SemiBold";
}
.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-Bold{
  font-family: "Montserrat-Bold";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.padding-col{
  padding: 0px;
} 
.ffcf00-background{
    background: #ffcf00
} 
.background-888888{
    background: #888888
}  
.background-ffffff{
    background: #ffffff
}  
.background-004c8c{
    background: #004c8c
}  
.background-navbar{
    background: linear-gradient(90deg, #004c8c, #0d8dff);
}
.div-ta0{
  height: 10px;
  margin: 0px;
}

/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  .img-padding{   
    padding: 12% 0px 0px 0px;
}

.div-ta1{
  height: 28px;
  margin: 0px;
}
    
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){ 
    .img-padding{   
    padding: 10% 0px 0px 0px;
}
.div-ta1{
  height: 33px;
  margin: 0px;
}
}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
   .img-padding{   
    padding: 10% 0px 0px 0px;
}
  .text-narbar-tam{
    font-size: 1vw
  }
  .margin-img{
    margin: 0px 0px 0px 80px
  }
  .margin-booton{
    margin: 0px 120px 0px 0px;
  }
     
  .div-ta1{
  height: 36px;
  margin: 0px;
}
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
     .img-padding{   
    padding: 7% 0px 0px 0px;
}
  .text-narbar-tam{
    font-size: 1.1vw
  }
  .margin-img{
    margin: 0px 0px 0px 125px;
  }
  .margin-booton{
    margin: 0px 150px 0px 0px;
  }
  .div-ta1{
  height: 36px;
  margin: 0px;
}
 }
@media (min-width: 1905px)  { 
     .img-padding{   
    padding: 4.5% 0px 0px 0px;
}
  .text-narbar-tam{
    font-size: 0.9vw
  }
  .margin-img{
    margin: 0px 0px 0px 250px;
  }
  .margin-booton{
    margin: 0px 250px 0px 0px;
  }
  .div-ta1{
  height: 40px;
  margin: 0px;
}
}


</style>
