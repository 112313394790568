<template>
  <div >
    <h3 class="text-Arial-Bold text-black  text-center text-size-title">Nuestros Noticia</h3>
    <v-row justify="center">
      <v-col cols="11" v-if="tablas">
        <v-btn
          color="success"
           @click="formNuevo()"
        >
            CREAR <v-icon>mdi-table-plus</v-icon>
        </v-btn>        
        <v-table
            fixed-header
            height="900px" 
        >
            <thead >
            <tr class="light-blue darken-2" >
                <th class="text-left">
                #
                </th>
                <th class="text-left">
                TITULO
                </th>
                <th class="text-left">
                VIDEO
                </th>
                <th class="text-left">
                ESTADO
                </th>
                <th class="text-left">
                ACCCIONES
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="(video, index) in videos" v-bind:key="index"
            >
                <td>{{index+1}}</td>
                <td>{{video.titulo}}</td>
                <td v-if="video.facebook==0"> 
                  <iframe 
                    :src="baseVideo+''+video.video" 
                    class="embed-responsive-item video-width" 
                    frameborder="0"  allowfullscreen cover></iframe>
                </td>
                <td v-if="video.facebook==1">                   
                  <iframe 
                    :src="baseVideof+''+video.video+'%2F&show_text=false&t=0'"  
                    style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" 
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                    height="auto" width="auto" ></iframe>   
                </td>
                <td class="text-blue" v-if="video.estado == 1">
                  HABILITADO
                </td>
                <td class="text-red" v-if="video.estado == 0">
                  DESHABILITADO 
                </td>
                <td class="text-white">
                <v-btn
                  icon="mdi-table-edit"
                  color="info"
                  @click="formEditar (video)"
                ></v-btn>
                <v-btn
                    icon="mdi-table-eye-off"
                    @click="formElimina(video.id)"
                    class="background-red"
                    v-if="video.estado == 1"
                ></v-btn>
                <v-btn
                    icon="mdi-table-eye"
                    @click="formHabilitar(video.id)"
                    class="background-success"
                    v-if="video.estado == 0"
                ></v-btn>
                </td>
            </tr>
            </tbody>
        </v-table>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-10" v-if="!tablas">
      <v-col cols="11">
       <v-row justify="center">
          <v-text-field
          class="pr-2"  
          :rules="textRules"
          label="Titulo"
          required
          v-model="video.titulo"
          ></v-text-field>
          <v-text-field
          class="pl-2"
          :rules="textRules"
          label="Alineacion"
          required
          v-model="video.aliniacion"
          ></v-text-field>    
       </v-row>
       <v-row justify="center">           
          <v-textarea
          class="pl-2"
          :rules="textRules"
          label="descripcion"
          required
          v-model="video.descripcion"
          ></v-textarea>  
       </v-row>   
       <v-row justify="center">
        <v-col cols="2" class="d-flex">
          <v-switch
            v-model="video.facebook"
            color="primary"
            label="Facebook"
            :value="1"
            hide-details
            class="align-self-center"
          ></v-switch>
        </v-col>
        <v-col class="d-flex">
          <v-text-field
          class="pl-2 align-self-center"
          :rules="textRules"
          label="Video"
          required
          v-model="video.video"
          ></v-text-field>   
        </v-col> 
        <v-col class="d-flex">
          <iframe 
            :src="baseVideo+''+video.video" 
            class="embed-responsive-item video-width align-self-center" 
            frameborder="0"  allowfullscreen cover v-if="video.facebook==0 || video.facebook==false"></iframe>              
          <iframe 
            :src="baseVideof+''+video.video+'%2F&show_text=false&t=0'"  
            style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" 
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
            height="auto" width="auto"  v-if="video.facebook ==1"
            class="align-self-center"></iframe>   
        </v-col>
       </v-row>     
      <v-btn
        text
        @click="tablas=true"
      >Cancelar</v-btn>
      <v-btn
        text
        @click="guardar_s"
      >Guardar</v-btn>
      </v-col>

    </v-row>
  </div>
<v-dialog
      v-model="dialog_img"
    >
    <v-toolbar
      class="background-personal text-white"
    >video</v-toolbar>
    <v-card  width="600">
      <v-card-text>
        <span >Cambiar video</span>        
        <v-file-input 
        class="pr-2"
        v-if="if_video == if_crear"
        label="video"
        show-size
        filled
        prepend-icon="mdi-camera"
        @change="cargarvideo"
        ></v-file-input> 
      </v-card-text>
      <v-card-actions class="justify-end text-white">
        <v-btn
          class="background-red"
          @click="dialog_img=false"
        >Cancelar</v-btn>
        <v-btn
          class="background-success "
          @click="guardar_s"
        >Guardar</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>

    
    <v-dialog
      v-model="dialogEliminar"
    >
    <v-toolbar
      class="background-red text-white"
    >DESHABILITAR</v-toolbar>
    <v-card>
      <v-card-text>
        <span >Esta seguro de desHabiliatar ?</span>
      </v-card-text>
      <v-card-actions class="justify-end text-white">
        <v-btn
          class="background-success"
          @click="dialogEliminar=false"
        >Cancelar</v-btn>
        <v-btn
          class="background-red "
          @click="eliminar_slider"
        >Si</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogHabilitar"
    >
    <v-toolbar
      class="background-1C759F text-white"
    >HABILITAR</v-toolbar>
    <v-card>
      <v-card-text>
        <span >Esta seguro de Habiliatar ?</span>
      </v-card-text>
      <v-card-actions class="justify-end text-white">
        <v-btn
          class="background-success"
          @click="dialogHabilitar=false"
        >Cancelar</v-btn>
        <v-btn
          class="background-red "
          @click="habilitar_slider"
        >Si</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
</template>

<script>

//import { Carousel, Slide, Navigation } from 'vue3-carousel';
import axios from 'axios'
var url ='backend-ccasucre/crud-videos.php';

export default {
  name: 'CcaSucreVideosConfig',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
  },
 methods: {
    formNuevo (){
      this.tablas = false
      this.operacion='crear';
      this.video.titulo = "";
      this.video.descripcion = "";
      this.video.aliniacion = "";
      this.video.facebook= 0;
      this.video.video = "";
    },
    formEditar (video){
      this.tablas = false
      this.operacion='editar';
      this.video.id = video.id;
      this.video.titulo = video.titulo;
      this.video.descripcion = video.descripcion;
      this.video.aliniacion=video.aliniacion,
      this.video.video = video.video;
      this.video.facebook = video.facebook;
    }, 
    formElimina(id){
      this.video.id=id;
      this.dialogEliminar = true
    },
    formHabilitar(id){
      this.video.id=id;
      this.dialogHabilitar = true
    },       
   guardar_s(){
     
    if(this.operacion=='crear'){
      this.crear_slider();              
    }
    if(this.operacion=='editar'){ 
      this.editar_slider();                           
    }
    this.dialog_img=false;
   }, 
    guardar_slider (){
      if(this.operacion=='crear'){
        this.crear_slider();                 
      }
      if(this.operacion=='editar'){ 
        this.editar_slider();                           
      }
      this.tablas=true;  
    },
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:5})
      .then(Response =>{
        this.videos=Response.data
      })
    }, 
    editar_slider (){
      axios.post(this.baseUrl+''+url, {opcion:3,id:this.video.id,titulo: this.video.titulo, descripcion:this.video.descripcion,
                aliniacion:this.video.aliniacion,video:this.video.video,facebook:this.video.facebook})
        .then(response =>{
          response;
          this.mostrar();
        });  
      this.tablas= true;

    },
    crear_slider (){        
      axios.post(this.baseUrl+''+url, {opcion:2, id:this.video.id,titulo: this.video.titulo, descripcion:this.video.descripcion,
                aliniacion:this.video.aliniacion,video:this.video.video,facebook:this.video.facebook })
        .then(response =>{
          response;
          this.mostrar();
        });  
      this.tablas= true;
    },
    eliminar_slider (){
      axios.post(this.baseUrl+''+url, {opcion:4, id:this.video.id, estado: false })
        .then(response =>{
          response;
          this.mostrar();

        });        
      this.video.id = null;
      this.dialogEliminar= false;
    },
    habilitar_slider(){
      axios.post(this.baseUrl+''+url, {opcion:4, id:this.video.id, estado: true })
        .then(response =>{
          response;
          this.mostrar();
        });  
      
      this.video.id = null;
      this.dialogHabilitar= false;
    },
 },
  data(){
    return {
      //baseUrl : "http://localhost/",
      baseUrl : "https://cca.edu.bo/",
      baseVideo : "https://www.youtube.com/embed/",
       basevideo : "img-ccasucre/img-video/",
      baseVideof : "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F",
      videos : {},
      cargos : [],
      video : {
        id: null,
        titulo : "",
        descripcion : "",
        video : "",
        facebook : "",
        aliniacion : "",
        estado : ""
      },         
      if_video : true,
      if_crear : true,
      tablas : true,
      dialog_img : false,
      file: null,
      dialogEliminar : false,
      dialogHabilitar : false,
    }
  },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 2;
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 3;
      }
      if (screen.width > 1264) {
        return 4;
      }

      return 1;
    },
    col_tam() {
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 6;
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 4;
      }
      if (screen.width > 1264) {
        return 3;
      }

      return 1;
    }
  },
  mounted(){
      this.mostrar();
    }

      

}
</script>

<style scoped>
.v-card--variant-contained {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%);
}
.text-keep-calm-font{
  font-family: "keep-calm-font";
}
.text-MontserratAlternates-Regular{
  font-family: "MontserratAlternates-Regular";
}
.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}
.text-Arial-Bold{
  font-family: "Arial-Bold";
}

.margin-0{
  margin: 0px;
}

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.border-001C29{
  border: #001C29;
  border-style: solid;
  border-color: green;
  border-width: 10px;
}
.background-success{
  background-color : green;
}
.background-red{
  background-color : red;
}
.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}
.background-0d8dff{
  background-color: #0d8dff;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.background-014c8d {
  background-color: #014c8d;

}
.text-ffd000{
  color : #ffd000;
}
.text-014c8d{
  color : #014c8d;
}
.background-ffd000{
  background-color: #ffd000;
}
.padding-next{
  
    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
.padding-prev{

    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
.height-div{
  height: 50px;
}
.whatsapp{
 cursor: pointer;
}
.whatsapp:hover{
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  color: #25D366;
	transform: translateY(-7px);
}
.email{
 cursor: pointer;
}
.email:hover{
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  color: #8f39ce;
	transform: translateY(-7px);
}
.background-personal{
    background: linear-gradient(90deg, #004c8c, #1496d9);
}
.img{
 cursor: pointer;
  color: inherit;
}

.img-contedor:hover .img {
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
  }
.img-contedor {
  overflow:hidden;
  }
@media (min-width: 0px) and (max-width: 600px) { 
  .text-size-icon{
    font-size: 15vw;
  }
  .text-size-title{
    font-size: 8vw;
  }
 .video-width{
    width: 210px;
  }
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
   /** sive todo */
   .text-size-icon{
    font-size: 10vw;
  }
  .text-size-title{
    font-size: 5vw;
  }
  .video-width{
    width: 180px;
  }
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
   .text-size-icon{
    font-size: 8vw;
  }
  .text-size-title{
    font-size: 4vw;
  }
.video-width{
    width: 200px;
  }
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
     .text-size-icon{
    font-size: 6vw;
  }
  .text-size-title{
    font-size: 3vw;
  }
  .video-width{
    width: 240px;
  }

 }
@media (min-width: 1905px)  { 
 
     .text-size-icon{
    font-size: 4vw;
  }
  .text-size-title{
    font-size: 2.5vw;
  }
  .video-width{
    width: 300px;
    height: 200px;
  }
 }

</style>
