<template>
  <div >
    <h3 class="text-Arial-Bold text-black  text-center text-size-title">Redes Sociales</h3>
    <v-row justify="center">
      <v-col cols="11" v-if="tablas">      
        <v-table
            fixed-header
            height="900px" 
        >
            <thead >
            <tr class="light-blue darken-2" >
                <th class="text-left">
                #
                </th>
                <th class="text-left">
                nombre
                </th>
                <th class="text-left">
                enlace
                </th>
                <th class="text-left">
                ACCCIONES
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="(rsocial, index) in rsocials" v-bind:key="index"
            >
                <td>{{index+1}}</td>
                <td>
                  <div class="d-flex pl-8 mb-2">
                    <v-icon :icon="'mdi-'+rsocial.logo" class="text-ffcf00 text-size-bn "></v-icon>
                    <h4 class="text-Arial-Narrow text-size-bn text-ffcf00 pl-2">{{rsocial.nombre}}</h4>
                  </div> 
              </td>
                <td>{{rsocial.enlace}}</td>
                <td class="text-white">
                <v-btn
                  icon="mdi-table-edit"
                  color="info"
                  @click="formEditar (rsocial)"
                ></v-btn>
                </td>
            </tr>
            </tbody>
        </v-table>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-10" v-if="!tablas">
      <v-col cols="11">
       <v-row justify="center">
          <v-text-field
          class="pr-2"  
          :rules="textRules"
          label="Nombre"
          required
          v-model="rsocial.nombre"
          ></v-text-field>
          <v-text-field
          class="pl-2"
          :rules="textRules"
          label="Enlace"
          required
          v-model="rsocial.enlace"
          ></v-text-field>    
       </v-row>     
      <v-btn
        text
        @click="tablas=true"
      >Cancelar</v-btn>
      <v-btn
        text
        @click="guardar_s"
      >Guardar</v-btn>
      </v-col>

    </v-row>
  </div>
</template>

<script>

//import { Carousel, Slide, Navigation } from 'vue3-carousel';
import axios from 'axios'
var url ='backend-ccasucre/crud-rsociales.php';

export default {
  name: 'CcaSucreRedesSocialesConfig',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
  },
 methods: {
    formEditar (rsocial){
      this.tablas = false
      this.operacion='editar';
      this.rsocial.id = rsocial.id;
      this.rsocial.nombre = rsocial.nombre;
      this.rsocial.enlace = rsocial.enlace;
    },       
   guardar_s(){
    if(this.operacion=='editar'){ 
      this.editar_slider();                           
    }
    this.dialog_img=false;
   }, 
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:5})
      .then(Response =>{
        this.rsocials=Response.data
      })
    }, 
    editar_slider (){
      axios.post(this.baseUrl+''+url, {opcion:3,id:this.rsocial.id,nombre: this.rsocial.nombre, enlace:this.rsocial.enlace})
        .then(response =>{
          response;
          this.mostrar();
        });  
      this.tablas= true;

    },
 },
  data(){
    return {
      //baseUrl : "http://localhost/",
      baseUrl : "https://cca.edu.bo/",
      rsocials : {},
      cargos : [],
      rsocial : {
        id: null,
        nombre : "",
        enlace : "",
      },         
      if_rsocial : true,
      if_crear : true,
      tablas : true,
      dialog_img : false,
      file: null,
      dialogEliminar : false,
      dialogHabilitar : false,
    }
  },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 2;
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 3;
      }
      if (screen.width > 1264) {
        return 4;
      }

      return 1;
    },
    col_tam() {
      if (screen.width > 0 && screen.width <= 600) {
        return 12;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 6;
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 4;
      }
      if (screen.width > 1264) {
        return 3;
      }

      return 1;
    }
  },
  mounted(){
      this.mostrar();
    }

      

}
</script>

<style scoped>
.v-card--variant-contained {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%);
}
.text-keep-calm-font{
  font-family: "keep-calm-font";
}
.text-MontserratAlternates-Regular{
  font-family: "MontserratAlternates-Regular";
}
.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}
.text-Arial-Bold{
  font-family: "Arial-Bold";
}

.margin-0{
  margin: 0px;
}

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.border-001C29{
  border: #001C29;
  border-style: solid;
  border-color: green;
  border-width: 10px;
}
.background-success{
  background-color : green;
}
.background-red{
  background-color : red;
}
.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}
.background-0d8dff{
  background-color: #0d8dff;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.background-014c8d {
  background-color: #014c8d;

}
.text-ffd000{
  color : #ffd000;
}
.text-014c8d{
  color : #014c8d;
}
.background-ffd000{
  background-color: #ffd000;
}
.padding-next{
  
    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
.padding-prev{

    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
.height-div{
  height: 50px;
}
.whatsapp{
 cursor: pointer;
}
.whatsapp:hover{
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  color: #25D366;
	transform: translateY(-7px);
}
.email{
 cursor: pointer;
}
.email:hover{
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  color: #8f39ce;
	transform: translateY(-7px);
}
.background-personal{
    background: linear-gradient(90deg, #004c8c, #1496d9);
}
.img{
 cursor: pointer;
  color: inherit;
}

.img-contedor:hover .img {
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
  }
.img-contedor {
  overflow:hidden;
  }
@media (min-width: 0px) and (max-width: 600px) { 
  .text-size-icon{
    font-size: 15vw;
  }
  .text-size-title{
    font-size: 8vw;
  }
 .rsocial-width{
    width: 210px;
  }
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
   /** sive todo */
   .text-size-icon{
    font-size: 10vw;
  }
  .text-size-title{
    font-size: 5vw;
  }
  .rsocial-width{
    width: 180px;
  }
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
   .text-size-icon{
    font-size: 8vw;
  }
  .text-size-title{
    font-size: 4vw;
  }
.rsocial-width{
    width: 200px;
  }
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
     .text-size-icon{
    font-size: 6vw;
  }
  .text-size-title{
    font-size: 3vw;
  }
  .rsocial-width{
    width: 240px;
  }

 }
@media (min-width: 1905px)  { 
 
     .text-size-icon{
    font-size: 4vw;
  }
  .text-size-title{
    font-size: 2.5vw;
  }
  .rsocial-width{
    width: 300px;
    height: 200px;
  }
 }

</style>
