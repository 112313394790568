<template>
  <div >    
    <div class="text-center pt-4">
      <span class="mb-6 text-center text-Montserrat-Medium text-size-title text-white">CONOCE A NUESTRA</span>
      <span class="mb-6 text-center text-Montserrat-Black text-size-title text-ffcf00"> CORPORACIÓN</span>
    </div>
      <v-row justify="center">
        <v-col
          cols="8"
          sm = "10"
          md = "8"
          lg = "8"
          xl = "6"
          class="margin-col">
            <v-row justify="center"> 
              <v-col
              cols="6"
              sm = "2"
              md = "2"
              lg = "2"
              xl = "2"
              >
                <v-img
                    src="../../../assets/logosWhite/ESAM.webp"
                    width="60%"
                    aspect-ratio="1"
                    v-on:click="logohref('https://esam.edu.bo/')"
                    class="esam"
                >
                </v-img>
                  
              </v-col>
              <v-col
              cols="6"
              sm = "2"
              md = "2"
              lg = "2"
              xl = "2"
              >
                <v-img
                    src="../../../assets/logosWhite/CAS.webp"
                    width="60%"
                    aspect-ratio="1"
                    v-on:click="logohref('https://castja.edu.bo/')"
                    class="cas"
                >
                </v-img>
                  
              </v-col>
              <v-col
              cols="6"
              sm = "2"
              md = "2"
              lg = "2"
              xl = "2"
              >
                <v-img
                    src="../../../assets/logosWhite/CIBERKIDS.webp"
                    width="60%"
                    aspect-ratio="1"
                    v-on:click="logohref('https://ciberkids.com.bo/')"
                    class="ciberKids"
                >
                </v-img>
                  
              </v-col>
              <v-col
              cols="6"
              sm = "2"
              md = "2"
              lg = "2"
              xl = "2"
              >
                <v-img
                    src="../../../assets/logosWhite/CYBERCORP.webp"
                    width="60%"
                    aspect-ratio="1"
                    v-on:click="logohref('https://www.cybercorp.com.bo/')"
                    class="cybercorp"
                >
                </v-img>
                  
              </v-col>
              <v-col
              cols="6"
              sm = "2"
              md = "2"
              lg = "2"
              xl = "2"
              >
                <v-img
                    src="../../../assets/logosWhite/intradigital.webp"
                    width="60%"
                    aspect-ratio="1"
                    v-on:click="logohref('https://esamlatam.com/')"
                    class="imtraDigital"
                >
                </v-img>
                  
              </v-col>
              <v-col
              cols="6"
              sm = "2"
              md = "2"
              lg = "2"
              xl = "2"
              >
                <v-img
                    src="../../../assets/logosWhite/esamlatam.webp"
                    width="60%"
                    aspect-ratio="1"
                    v-on:click="logohref('https://esamlatam.com/')"
                    class="esamLatam"
                >
                </v-img>
                  
              </v-col>
            </v-row>
        </v-col>
      </v-row>
  </div>
</template>

<script>



export default {
  name: 'CcaLogos',
  props: {
  },
  components : {
  },
  data(){
    return {
    };
  },
 methods: {
   logohref(href){
    window.open(href);
   }
 },
  mounted(){
  }

      

}
</script>

<style scoped>
.cursor{
 cursor: pointer;
    color: inherit;
}
.text-Montserrat-Medium{
  font-family: "Montserrat-Medium";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-LiberationSans-Boldy{
  font-family: "LiberationSans-Bold";
}
.text-LiberationSans-Regular{
  font-family: "LiberationSans-Regular";
}
.text-Montserrat-Black{
  font-family: "Montserrat-Black";
}
.text-Montserrat-Regular{
  font-family: "Montserrat-Regular";
}
.text-Montserrat-BlackItalic{
  font-family: "Montserrat-BlackItalic";
}
.background-ffcf00{
    background: #ffcf00
}  
.background-0d8dff{
   background: #0d8dff
} 
.background-d2d4da{
   background: #d2d4da
}
.text-whit{
  color: white;
}
.text-ffcf00{
  color: #ffcf00;
}
.background-navbar{
    background: linear-gradient(180deg, #0084ff, #00398c);
}
.background-logos{
    background: linear-gradient(180deg, #004c8c, #035aa3, #097add);
}
.border-rad{
    
    border-radius: 9999px !important;
}
.esam{
  cursor: pointer;
    color: inherit;
}
.esam:hover {
  content:url("../../../assets/Logos/esam.webp");
	transform: translateY(-7px);
}
.cas{
  cursor: pointer;
    color: inherit;
}
.cas:hover {
  content:url("../../../assets/Logos/cads.webp");
	transform: translateY(-7px);
}
.ciberKids{
  cursor: pointer;
    color: inherit;
}
.ciberKids:hover {
  content:url("../../../assets/Logos/ciberKids.webp");
	transform: translateY(-7px);
}
.cybercorp{
  cursor: pointer;
    color: inherit;
}
.cybercorp:hover {
  content:url("../../../assets/Logos/cybercorp.webp");
	transform: translateY(-7px);
}
.imtraDigital{
  cursor: pointer;
    color: inherit;
}
.imtraDigital:hover {
  content:url("../../../assets/Logos/imtraDigital.webp");
	transform: translateY(-7px);
}
.esamLatam{
  cursor: pointer;
    color: inherit;
}
.esamLatam:hover {
  content:url("../../../assets/Logos/logosEsamLatam1.webp");
	transform: translateY(-7px);
}
.margin-est{
    margin: 20px 0px 0px 0px
  }
  .margin-col{
    margin: 50px 0px 50px 0px;
  }
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  .text-size-title{
  font-size: 6vw;
}
 .text-tam{ 
    font-size: 5vw;
  }
    .margin-col{
    margin: 20px 0px 20px 0px;
  }
/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px){   
  .text-size-title{
  font-size: 6vw;
}
.margin-col{
    margin: 30px 0px 30px 0px;
  }
}
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px)  { 
     .text-size-title{
  font-size: 5vw;
}
  
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px)  { 
     .text-size-title{
  font-size: 4vw;
}
    
 }
@media (min-width: 1905px)  { 
 .text-size-title{
  font-size: 4vw;
}
}


</style>
